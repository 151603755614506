import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concatMap, switchMap, catchError, startWith } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';
import dayjs from 'dayjs';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';
import { toastShowCall } from '@src/store/actions/ui';
import * as actions from '@src/store/actions';
import { getCartProductsDone, getCartProductsFail } from '@src/store/actions/cart';
import { payment } from '@src/services';
import { getCartProducts } from '@src/services/cart';
import {
	getRegisterFormFieldsByEventId,
	saveRegisterFormBySlot,
	getRegisterFormFieldsByWebinarId,
	saveWebinarRegisterFormBySlot,
	getAreaInfo,
	// getPurchaseInfoFields,
	getRecipientInfo,
	saveCheckoutFormPurchase,
	getCheckoutForm,
} from '@src/services/checkout';
import {
	GET_CART_PRODUCTS_AND_REGISTER_FORMS,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL,
	PAY_VIA_VACC_WITH_REGISTER_FORM_CALL,
	SAVE_REGISTER_FORM_BY_SLOT_CALL,
	SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL,
	PAYMENT_ERROR_HANDLER_ACTION_CALL,
	GET_AREA_INFO_CALL,
	// GET_PURCHASE_INFO_FIELDS_CALL,
	GET_RECIPIENT_INFO_CALL,
	SAVE_CHECKOUT_FORM_PURCHASE_CALL,
	GET_CHECKOUT_FORM_CALL,
	PAYMENT_SUCCESS_ACTIONS,
} from '@src/store/types/checkout';
import { IShoppingCartProducts } from '@src/types/cart';
import { affiliateMarketingEventTriggerCall } from '@src/store/actions/affiliateMarketing';
import { reactLocalStorage } from '@src/utils/localStorage';
import { saveCheckoutFormPurchaseCall } from '@src/store/actions/checkout';

export const getCartProductsAndRegisterFormsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_CART_PRODUCTS_AND_REGISTER_FORMS),
		switchMap(() => (
			fromPromise(getCartProducts()).pipe(
				switchMap(response => {
					const { event_slots: eventSlots, webinar_slots: webinarSlots } = response as IShoppingCartProducts;
					const eventIds = eventSlots.map(eventSlot => eventSlot.event_id);
					const webinarIds = webinarSlots.map(webinarSlot => webinarSlot.webinar_id);
					const eventFormFieldActions = eventIds
						.map(eventId => actions.getRegisterFormFieldsByEventIdCall({ eventId }));
					const webinarFormFieldActions = webinarIds
						.map(webinarId => actions.getRegisterFormFieldsByWebinarIdCall({ webinarId }));

					return of(
						getCartProductsDone({ response }),
						...eventFormFieldActions,
						...webinarFormFieldActions,
					);
				}),
				catchError(error => of(getCartProductsFail({ error }))),
			)
		)),
	)
);

// get event forms
export const getRegisterFormFieldsByEventIdEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL),
		concatMap(({ payload: { eventId } }) => (
			fromPromise(getRegisterFormFieldsByEventId({ eventId })).pipe(
				switchMap(response => of(actions.getRegisterFormFieldsByEventIdDone({ eventId, response }))),
				catchError(error => of(actions.getRegisterFormFieldsByEventIdFail({ eventId, error }))),
			)
		)),
	)
);

// get webinar forms
export const getRegisterFormFieldsByWebinarIdEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL),
		concatMap(({ payload: { webinarId } }) => (
			fromPromise(getRegisterFormFieldsByWebinarId({ webinarId })).pipe(
				switchMap(response => of(actions.getRegisterFormFieldsByWebinarIdDone({ webinarId, response }))),
				catchError(error => of(actions.getRegisterFormFieldsByWebinarIdFail({ webinarId, error }))),
			)
		)),
	)
);

const formatRegisterForms = payload => {
	const register_forms = {
		event_forms: [],
		webinar_forms: [],
	};
	const { formDataPayload: {
		event: eventFormDataPayload,
		webinar: webinarFormDataPayload,
	} } = payload;
	if (eventFormDataPayload?.length > 0) {
		register_forms.event_forms = eventFormDataPayload.map(item => ({
			slot_id: item.slotId,
			short_link: item.shortLink,
			form_fields: item.formDataArray,
			other_attenders: item.otherAttenderFormData,
		}));
	}
	if (webinarFormDataPayload?.length > 0) {
		register_forms.webinar_forms = webinarFormDataPayload.map(item => ({
			slot_id: item.slotId,
			short_link: item.shortLink,
			form_fields: item.formDataArray,
		}));
	}
	return register_forms;
};

// credit card payment
export const payViaCreditCardWithRegisterFormEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL),
	switchMap(({ payload }) => {
		const postData = {
			...payload,
			register_forms: formatRegisterForms(payload),
		};
		delete postData.formDataPayload;
		return fromPromise(payment.payViaCreditCard(postData))
			.pipe(
				switchMap((response: any) => of(
					toastShowCall({ message: 'checkout:checkout_success', type: TOAST_TYPE_SUCCESS }),
					actions.paymentSuccessActions({ paymentPayload: payload, response }),
					actions.payViaCreditCardWithRegisterFormDone({ response }),
				)),
				catchError(error => of(
					toastShowCall({ message: 'checkout:checkout_failed', type: TOAST_TYPE_ERROR }),
					actions.payViaCreditCardWithRegisterFormFail(error),
					actions.paymentErrorHandlerActionCall(error),
				)),
			);
	}),
);

// ECPay payment
export const payViaECPayWithRegisterFormEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL),
	switchMap(({ payload }) => {
		const postData = {
			...payload,
			register_forms: formatRegisterForms(payload),
		};
		delete postData.formDataPayload;
		return fromPromise(payment.payViaEcpay(postData))
			.pipe(
				switchMap((response: any) => {
					const tempContainer = document.createElement('div');
					tempContainer.innerHTML = response;
					const formElement = tempContainer.querySelector('form');
					const formContainer = document.getElementById('__next');
					formContainer.appendChild(formElement);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					document.getElementById('data_set').submit();
					return of(
						// actions.paymentSuccessActions({ paymentPayload: payload, response }),
						// actions.payViaECPayWithRegisterFormDone({ response }),
					);
				}),
				catchError(error => of(
					actions.payViaECPayWithRegisterFormFail(error),
					actions.paymentErrorHandlerActionCall(error),
				)),
			);
	}),
);

// ATM payment
export const payViaVaccWithRegisterFormEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(PAY_VIA_VACC_WITH_REGISTER_FORM_CALL),
	switchMap(({ payload }) => {
		const postData = {
			...payload,
			register_forms: formatRegisterForms(payload),
		};
		delete postData.formDataPayload;
		return fromPromise(payment.payViaVacc(postData))
			.pipe(
				switchMap((response: any) => of(
					actions.paymentSuccessActions({ paymentPayload: payload, response }),
					actions.payViaVaccWithRegisterFormDone({ response }),
				)),
				catchError(error => of(
					actions.paymentErrorHandlerActionCall(error),
					actions.payViaVaccWithRegisterFormFail(error),
				)),
			);
	}),
);

export const paymentSuccessActionsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(PAYMENT_SUCCESS_ACTIONS),
	switchMap(({ payload: {
		paymentPayload, response,
	} }) => {
		const { formDataPayload: {
			checkoutForm: {
				isCheckoutFormEnabled,
				...checkoutFormDataPayload
			},
		} } = paymentPayload;
		const trackId = reactLocalStorage.get('track_id');
		const validUntil = reactLocalStorage.get('valid_until');
		const currentTime = dayjs(new Date());
		const validUntilTime = dayjs(parseInt(validUntil));
		const isCampaignExpired = validUntilTime.isBefore(currentTime);
		const purchaseOrderList = response.map(item => item.purchase_order_id);
		let checkoutFormActions = [];
		let affiliateMarketingActions = [];

		if (isCheckoutFormEnabled) {
			checkoutFormActions = purchaseOrderList.map(purchase_id => saveCheckoutFormPurchaseCall({
				...checkoutFormDataPayload,
				purchase_id,
			}));
		}

		if (trackId && !isCampaignExpired) {
			affiliateMarketingActions = [
				affiliateMarketingEventTriggerCall({
					track_id: trackId,
					event_type: 'purchase',
					purchase_order_list: purchaseOrderList,
				}),
			];
		}

		return of(
			...checkoutFormActions,
			...affiliateMarketingActions,
			actions.historyPushCall({
				pathname: '/checkout-success',
				query: {
					purchaseOrderList: purchaseOrderList.join(','),
				},
			}),
		);
	}),
);

export const saveRegisterFormBySlotEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(SAVE_REGISTER_FORM_BY_SLOT_CALL),
	switchMap(({ payload }) => fromPromise(saveRegisterFormBySlot(payload))
		.pipe(
			startWith(actions.payViaVaccCall()),
			switchMap(() => of(actions.saveRegisterFormBySlotDone())),
			catchError(error => of(actions.saveRegisterFormBySlotFail({ error }))),
		)),
);

export const saveWebinarRegisterFormBySlotEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL),
	switchMap(({ payload }) => fromPromise(saveWebinarRegisterFormBySlot(payload))
		.pipe(
			startWith(actions.payViaVaccCall()),
			switchMap(() => of(actions.saveWebinarRegisterFormBySlotDone())),
			catchError(error => of(actions.saveWebinarRegisterFormBySlotFail({ error }))),
		)),
);

export const paymentErrorHandlerActionCallEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(PAYMENT_ERROR_HANDLER_ACTION_CALL),
	switchMap(({ payload }) => {
		const { data: failedInfoArray } = payload.response;
		const { failed_event_slots = [], failed_webinar_slots = [] } = failedInfoArray;
		const webinarTicketsStatusArr = failed_webinar_slots
			.flatMap(slot => slot.tickets)
			.flatMap(ticket => ticket.status);
		const eventTicketsStatusArr = failed_event_slots
			.flatMap(slot => slot.tickets)
			.flatMap(ticket => ticket.status);
		const foundWebinarSoldOut = webinarTicketsStatusArr.find(status => status.indexOf('sold_out') > -1);
		const foundEventSoldOut = eventTicketsStatusArr.find(status => status.indexOf('sold_out') > -1);

		if (foundWebinarSoldOut || foundEventSoldOut) {
			return of(actions.toastShowCall({
				message: 'checkout_failed:sold_out',
				type: TOAST_TYPE_ERROR,
			}));
		}
		return of(actions.toastShowCall({
			message: 'checkout_failed:item_changed',
			type: TOAST_TYPE_ERROR,
		}));
	}),
);

export const getAreaInfoEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_AREA_INFO_CALL),
		switchMap(() => (
			fromPromise(getAreaInfo()).pipe(
				switchMap(response => of(actions.getAreaInfoDone({ response }))),
				catchError(error => of(actions.getAreaInfoFail({ error }))),
			)
		)),
	)
);

// export const getPurchaseInfoFieldsEpic = (action$: Observable<Action>): Observable<Action> => (
// 	action$.pipe(
// 		ofType(GET_PURCHASE_INFO_FIELDS_CALL),
// 		switchMap(() => (
// 			fromPromise(getPurchaseInfoFields()).pipe(
// 				switchMap(response => of(actions.getPurchaseInfoFieldsDone({ response }))),
// 				catchError(error => of(actions.getPurchaseInfoFieldsFail({ error }))),
// 			)
// 		)),
// 	)
// );

export const getRecipientInfoEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_RECIPIENT_INFO_CALL),
		switchMap(() => (
			fromPromise(getRecipientInfo()).pipe(
				switchMap(response => of(actions.getRecipientInfoDone({ response }))),
				catchError(error => of(actions.getRecipientInfoFail({ error }))),
			)
		)),
	)
);

export const saveRecipientInfoEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(SAVE_CHECKOUT_FORM_PURCHASE_CALL),
		switchMap(({ payload }) => (
			fromPromise(saveCheckoutFormPurchase(payload)).pipe(
				switchMap(response => of(actions.saveCheckoutFormPurchaseDone({ response }))),
				catchError(error => of(actions.saveCheckoutFormPurchaseFail({ error }))),
			)
		)),
	)
);

export const getCheckoutFormEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_CHECKOUT_FORM_CALL),
		switchMap(() => (
			fromPromise(getCheckoutForm()).pipe(
				switchMap(response => of(actions.getCheckoutFormDone({ response }))),
				catchError(error => of(actions.getCheckoutFormFail({ error }))),
			)
		)),
	)
);
