import { Observable } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { ui } from '@src/services'
import * as types from '@src/store/types'
import * as actions from '@src/store/actions'

export const toastShowCall = (action$, store) =>
  action$.pipe(
    ofType(types.TOAST_SHOW_CALL),
    map(({ payload }) => {
      ui.toastShow(payload)

      return actions.toastShowDone()
    }),
    catchError(err =>
      of(actions.toastShowFail(err))
    )
  )