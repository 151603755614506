import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_ATTENDANCE_HISTORY_CALL,
	DELETE_PUNCH_IN_RECORD_CALL,
} from '@src/store/types/attendance';

import {
	getAttendanceHistoryDone,
	getAttendanceHistoryFail,
	deletePunchInRecordDone,
	deletePunchInRecordFail,
} from '@src/store/actions/attendance';
import {
	getAttendanceHistory,
	deletePunchInRecord,
} from '@src/services/attendance';

export const getAttendanceHistoryEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_ATTENDANCE_HISTORY_CALL),
	switchMap(
		({ payload }) => fromPromise(getAttendanceHistory(payload)).pipe(
			switchMap(res => of(getAttendanceHistoryDone({ res }))),
			catchError(error => of(getAttendanceHistoryFail({ error }))),
		),
	),
);

export const deletePunchInRecordEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_PUNCH_IN_RECORD_CALL),
	switchMap(
		({ payload }) => fromPromise(deletePunchInRecord(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(deletePunchInRecordDone({ res }));
			}),
			catchError(error => of(deletePunchInRecordFail({ error }))),
		),
	),
);
