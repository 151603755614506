/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { getThemeSelector } from '@src/store/selectors';
import TextField from '@src/components/textField';
import * as actionCreators from '@src/store/actions';
import { IconComponent } from '@src/icons';
import ModalFooterInfo from '@src/components/modalContainer/components/modalFooterInfo';
import { checkIsServer } from '@src/utils/deprecatedCommon';
import { getRegisterUrl } from '@src/utils/hardcode';

import LoginWithOther from './components/LoginWithOther';
import ModalHeader from '../modalHeader';
import {
	Wrapper,
	Content,
	LoginBtn,
	LinkButton,
	ErrorMsgBox,
	Box,
	Footer,
} from './Styled';

const LoginModal = ({ onClose, actions, errorMessage, t, domain }) => {
	const router = useRouter();
	const { query } = router;
	const { fromCompanyVat } = query || {};
	const [showPassword, setShowPassword] = useState(false);
	const [currentEmail, setCurrentEmail] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [withError, setWithError] = useState(false);
	const [targetClass, setTargetClass] = useState();
	const { themes: { disable_oauth: isDisableOauth } } = useSelector(getThemeSelector);

	useEffect(() => {
		setWithError(!!errorMessage);
	}, [errorMessage]);

	function handleChange(value) {
		setCurrentLanguage(value);
	}

	function handleChangePasswordType() {
		setShowPassword(!showPassword);
	}

	function handleSubmit(e) {
		e.preventDefault();

		actions.emailLoginCall({
			email: currentEmail,
			password: currentPassword,
			...(fromCompanyVat ? { hr_line_uuid: fromCompanyVat } : {}),
			onSuccess: () => {
				if (fromCompanyVat) {
					router.reload();
				}
			},
		});
	}

	function handleChangeModal(type) {
		actions.setModalType({ type });
	}

	function handleClickStart(e) {
		setTargetClass(e.target.className);
	}

	function handleClickEnd(e) {
		if (
			e.target.id === 'modal-wrap' &&
      window.innerWidth > 768 &&
      targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass();
	}

	const handleClickRegister = () => {
		actions.clearErrorMessage();
		const hardcodeRegisterUrl = getRegisterUrl(domain);
		if (hardcodeRegisterUrl) {
			window.open(hardcodeRegisterUrl, '_self');
			return;
		}
		handleChangeModal('register');
	};

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<div role="presentation" className="login icon-icon_close_31" onClick={onClose} />
				<ModalHeader title={t('login:title')} onClose={onClose} />
				<form onSubmit={e => handleSubmit(e)}>
					<TextField
						required
						inputType="email"
						placeholder={t('login:email')}
						InputValue={currentEmail}
						autoComplete="email"
						onInputValueChange={value => setCurrentEmail(value)}
						withError={withError}
						icon={(
							<IconComponent
								size={22}
								color="#dcdcdc"
								icon="login_form_email"
							/>
						)}
						autoFocus
					/>
					<TextField
						required
						inputType={showPassword ? 'text' : 'password'}
						placeholder={t('login:password')}
						InputValue={currentPassword}
						autoComplete="current-password"
						onInputValueChange={value => setCurrentPassword(value)}
						withError={withError}
						minLength="8"
						icon={(
							<IconComponent
								size={22}
								color="#dcdcdc"
								icon={
									showPassword ? 'login_form_eye_onsvg' : 'login_form_eye_off'
								}
								onClick={handleChangePasswordType}
							/>
						)}
						style={{ marginTop: 20 }}
					/>
					{errorMessage && <ErrorMsgBox>{t(errorMessage)}</ErrorMsgBox>}
					<LoginBtn>{t('login:title')}</LoginBtn>
				</form>
				<LinkButton
					className="forget-password"
					onClick={() => handleChangeModal('forgetpassword')}
				>
					{t('login:forget')}
				</LinkButton>
				{/* google / facebook / line 登入 */}
				{!isDisableOauth && !checkIsServer() && <LoginWithOther />}
				<Box>
					<span>{t('login:toRegister')}</span>
					<LinkButton onClick={handleClickRegister}>{t('login:register')}</LinkButton>
				</Box>
				<Footer>
					<ModalFooterInfo />
				</Footer>
			</Content>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	errorMessage: state.errorMessage.groupErrorMessage,
	domain: state.ssr.domain,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(LoginModal),
);
