import api from '@src/api';

import { IEventTicket } from '@src/types/event';
import { IWebinarTicket } from '@src/types/webinar';

export const addEventToCart = ({ slotId, tickets }: {
	slotId: string;
	tickets: IEventTicket[];
}): Promise<any> => api.post(`shopping-carts/event-slots/${slotId}`, { tickets });

export const addWebinarToCart = ({ slotId, tickets }: {
	slotId: string;
	tickets: IWebinarTicket[];
}): Promise<any> => api.post(`shopping-carts/webinar-slots/${slotId}`, { tickets });

export const getCartProducts = (): Promise<any> => api.get('shopping-carts/');

export const removeCourseFromCart = ({ courseId } : {
	courseId: string;
}): Promise<any> => api['delete'](`shopping-carts/products/${courseId}`);

export const removeEventFromCart = ({ slotId, ticketId } : {
	slotId: string;
	ticketId: string;
}): Promise<any> => api['delete'](`shopping-carts/event-slots/${slotId}/tickets/${ticketId}`);

export const removeWebinarFromCart = ({ slotId, ticketId } : {
	slotId: string;
	ticketId: string;
}): Promise<any> => api['delete'](`shopping-carts/webinar-slots/${slotId}/tickets/${ticketId}`);

export const updateEventQuantity = ({ slotId, ticketId, quantity } : {
	slotId: string;
	ticketId: string;
	quantity: number
}): Promise<any> => api.put(`shopping-carts/event-slots/${slotId}/tickets/${ticketId}`, { quantity });

export const reserveCartProducts = (): Promise<any> => api.post('shopping-carts/reserve');

export const getPublicCourseCoupons = (): Promise<any> => api.get('shopping-carts/products/coupons');

export const applyCourseCoupons = ({ coupon }: {
	coupon: string;
}): Promise<any> => api.post('shopping-carts/products/coupons', { promo_code: coupon });

export const deleteCourseCoupons = (): Promise<any> => api['delete']('shopping-carts/products/coupons');

export const getPublicEventCoupons = (): Promise<any> => api.get('shopping-carts/events/coupons');

export const applyEventSlotCoupons = ({ coupon } : {
	coupon: string;
}): Promise<any> => api.post('shopping-carts/events/coupons', { promo_code: coupon });

export const deleteEventSlotCoupons = (): Promise<any> => api['delete']('shopping-carts/events/coupons');

export const getPublicWebinarCoupons = (): Promise<any> => api.get('shopping-carts/webinars/coupons');

export const applyWebinarSlotCoupons = ({ coupon } : {
	coupon: string;
}): Promise<any> => api.post('shopping-carts/webinars/coupons', { promo_code: coupon });

export const deleteWebinarSlotCoupons = (): Promise<any> => api['delete']('shopping-carts/webinars/coupons');
