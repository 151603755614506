import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_APPROVAL_CENTER_CALL,
} from '@src/store/types/hrCenter';

import {
	getApprovalCenterDone,
	getApprovalCenterFail,
} from '@src/store/actions/hrCenter';
import {
	getApprovalCenter,
} from '@src/services/hrCenter';

export const getApprovalCenterEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_APPROVAL_CENTER_CALL),
	switchMap(
		() => fromPromise(getApprovalCenter()).pipe(
			switchMap(res => of(getApprovalCenterDone({ res }))),
			catchError(error => of(getApprovalCenterFail({ error }))),
		),
	),
);
