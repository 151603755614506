import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import * as types from '@src/store/types';

const defaultSideEffectStatus = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	questionSheet: {},
	questionSheetSideEffect: defaultSideEffectStatus,
	answerSheet: {},
	answerSheetSideEffect: defaultSideEffectStatus,
	viewAnswer: {},
	viewAnswerSideEffect: defaultSideEffectStatus,
	allAnswerSheet: {},
};

export default handleActions({
	// get questionSheet
	[types.GET_QUIZZES_QUESTION_SHEET_CALL](state) {
		return update(state, {
			questionSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoading: true,
			} },
		});
	},
	[types.GET_QUIZZES_QUESTION_SHEET_DONE](state, { payload }) {
		return update(state, {
			questionSheet: { $set: payload.response },
			questionSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoaded: true,
			} },
		});
	},
	[types.GET_QUIZZES_QUESTION_SHEET_FAIL](state, { payload }) {
		return update(state, {
			questionSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				error: payload.error,
			} },
		});
	},
	// get answerSheet
	[types.GET_QUIZZES_ANSWER_SHEET_CALL](state) {
		return update(state, {
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoading: true,
			} },
		});
	},
	[types.GET_QUIZZES_ANSWER_SHEET_DONE](state, { payload }) {
		return update(state, {
			answerSheet: { $set: payload.response },
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoaded: true,
			} },
		});
	},
	[types.GET_QUIZZES_ALL_ANSWER_SHEET_DONE](state, { payload }) {
		return update(state, {
			allAnswerSheet: {
				[payload.response.id]: { $set: payload.response },
			},
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoaded: true,
			} },
		});
	},
	[types.GET_QUIZZES_ANSWER_SHEET_FAIL](state, { payload }) {
		return update(state, {
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				error: payload.error,
			} },
		});
	},
	// view answer
	[types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_CALL](state) {
		return update(state, {
			viewAnswerSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoading: true,
			} },
		});
	},
	[types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_DONE](state, { payload }) {
		return update(state, {
			viewAnswer: { $set: payload.response },
			viewAnswerSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoaded: true,
			} },
		});
	},
	[types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_FAIL](state, { payload }) {
		return update(state, {
			viewAnswerSideEffect: { $set: {
				...defaultSideEffectStatus,
				error: payload.error,
			} },
		});
	},
}, initialState);
