import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getRegisterFieldsCall } from '@src/store/actions/school';
import { selectSchool } from '@src/store/selectors/school';

const useRegisterFields = () => {
	const dispatch = useDispatch();

	const {
		registerFields,
	} = useSelector(selectSchool);

	useEffect(() => {
		dispatch(getRegisterFieldsCall());
	}, []);

	return {
		registerFields,
	};
};

export default useRegisterFields;
