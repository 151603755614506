import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_KNOWLEDGE_BASE_SPACES_CALL,
	POST_KNOWLEDGE_BASE_SPACE_CALL,
	DELETE_KNOWLEDGE_BASE_SPACE_CALL,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL,
	PATCH_KNOWLEDGE_BASE_SPACE_CALL,
} from '@src/store/types/knowledgeBaseSpace';

import {
	getKnowledgeBaseSpacesDone,
	getKnowledgeBaseSpacesFail,
	postKnowledgeBaseSpaceDone,
	postKnowledgeBaseSpaceFail,
	deleteKnowledgeBaseSpaceDone,
	deleteKnowledgeBaseSpaceFail,
	getKnowledgeBaseSpaceDetailDone,
	getKnowledgeBaseSpaceDetailFail,
	patchKnowledgeBaseSpaceDone,
	patchKnowledgeBaseSpaceFail,
} from '@src/store/actions/knowledgeBaseSpace';
import { toastShowCall } from '@src/store/actions/ui';
import {
	getKnowledgeBaseSpaces,
	postKnowledgeBaseSpace,
	deleteKnowledgeBaseSpace,
	getKnowledgeBaseSpaceDetail,
	patchKnowledgeBaseSpace,
} from '@src/services/knowledgeBaseSpace';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const getKnowledgeBaseSpacesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_KNOWLEDGE_BASE_SPACES_CALL),
	switchMap(
		({ payload }) => fromPromise(getKnowledgeBaseSpaces(payload)).pipe(
			switchMap(res => of(getKnowledgeBaseSpacesDone({ res }))),
			catchError(error => of(getKnowledgeBaseSpacesFail({ error }))),
		),
	),
);

export const postKnowledgeBaseSpaceEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_KNOWLEDGE_BASE_SPACE_CALL),
	switchMap(
		({ payload }) => fromPromise(postKnowledgeBaseSpace(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postKnowledgeBaseSpaceDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postKnowledgeBaseSpaceFail({ error }),
			)),
		),
	),
);

export const deleteKnowledgeBaseSpaceEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_KNOWLEDGE_BASE_SPACE_CALL),
	switchMap(
		({ payload }) => fromPromise(deleteKnowledgeBaseSpace(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:deleted_success', type: TOAST_TYPE_SUCCESS }),
					deleteKnowledgeBaseSpaceDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:deleted_fail', type: TOAST_TYPE_ERROR }),
				deleteKnowledgeBaseSpaceFail({ error }),
			)),
		),
	),
);

export const getKnowledgeBaseSpaceDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getKnowledgeBaseSpaceDetail(payload)).pipe(
			switchMap(res => of(getKnowledgeBaseSpaceDetailDone({ res }))),
			catchError(error => of(getKnowledgeBaseSpaceDetailFail({ error }))),
		),
	),
);

export const patchKnowledgeBaseSpaceEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(PATCH_KNOWLEDGE_BASE_SPACE_CALL),
	switchMap(
		({ payload }) => fromPromise(patchKnowledgeBaseSpace(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:updated_success', type: TOAST_TYPE_SUCCESS }),
					patchKnowledgeBaseSpaceDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:updated_fail', type: TOAST_TYPE_ERROR }),
				patchKnowledgeBaseSpaceFail({ error }),
			)),
		),
	),
);
