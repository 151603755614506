import api from '@src/api';

export const addCourseDiscussion = (payload) =>
	api.post(`course/${payload.course_id}/discussion`, payload.data);

export const getCourseDiscussionsBefore = (payload) =>
	/**
	 * TODO:
	 * There is limited default PAGE_SIZE=40 in backend(course-api).
	 * So front-end can't get total amount of discussions when there is no param is given.
	 * We temporarily hardcode page=0 and page_size=1000 to get all amount of discussions.
	 * After pagination in front-end is implemented, these hardcode params should be removed.
	*/
	api.get(
		`course/${payload.course_id}/discussions/before?page=${payload.page || 0}&page_size=${payload.pageSize || 9999}`,
		payload.data,
		{ hasPagination: true },
	);

export const getCourseDiscussions = (payload) =>
	api.get(`course/${payload.course_id}/discussions`, payload.data);

export const addCourseDiscussionReply = (payload) =>
	api.post(`comment/${payload.comment_id}/reply`, payload.data);

export const addCourseDiscussionBefore = (payload) =>
	api.post(`course/${payload.course_id}/discussions/before`, payload.data);
