import React from 'react';
import PropTypes from 'prop-types';

import { IconComponent } from '@src/icons';
import { Header } from './Styled';

const ModalHeader = ({ title, onClose, className }) => (
	<Header className={className}>
		<div className="title">{title}</div>
		<button className="go-back" onClick={onClose}>
			<IconComponent size={20} color="#1b2833" icon="icon_mobile_arrow_45" />
		</button>
	</Header>
);

ModalHeader.propTypes = {
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ModalHeader;
