import { handleActions } from 'redux-actions';
import produce from 'immer';

import * as types from '@src/store/types';
import { getCurrentTimestamp } from '@src/utils/day';

const initialState = {
  addDiscussionStates: {},
  addDiscussionReplyStates: {},
  getDiscussionsStates: {},
  discussions: [],
  getDiscussionsBeforeStates: {},
  discussionsBefore: {
    data: [],
    pagination: {
      totalCount: 0,
    },
  },
};

export default handleActions(
  {
    [types.ADD_COURSE_DISCUSSION_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionStates[payload.course_id] = 'LOADING';
      });
    },
    [types.ADD_COURSE_DISCUSSION_RESET](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionStates[payload.course_id] = 'IDLE';
      });
    },
    [types.ADD_COURSE_DISCUSSION_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionStates[payload.course_id] = 'SUCCESS';
      });
    },
    [types.ADD_COURSE_DISCUSSION_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionStates[payload.course_id] = 'FAIL';
      });
    },
    [types.ADD_COURSE_DISCUSSION_REPLY_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionReplyStates[payload.comment_id] = 'LOADING';
      });
    },
    [types.ADD_COURSE_DISCUSSION_REPLY_RESET](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionReplyStates[payload.comment_id] = 'IDLE';
      });
    },
    [types.ADD_COURSE_DISCUSSION_REPLY_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionReplyStates[payload.comment_id] = 'SUCCESS';
      });
    },
    [types.ADD_COURSE_DISCUSSION_REPLY_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.addDiscussionReplyStates[payload.comment_id] = 'FAIL';
      });
    },
    [types.GET_COURSE_DISCUSSIONS_BEFORE_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsBeforeStates[payload.course_id] = 'LOADING';
      });
    },
    [types.GET_COURSE_DISCUSSIONS_BEFORE_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsBeforeStates[payload.course_id] = 'SUCCESS';
        draft.discussionsBefore = payload.response;
      });
    },
    [types.GET_COURSE_DISCUSSIONS_BEFORE_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsBeforeStates[payload.course_id] = 'FAIL';
      });
    },
    [types.GET_COURSE_DISCUSSIONS_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsStates[payload.course_id] = 'LOADING';
      });
    },
    [types.GET_COURSE_DISCUSSIONS_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsStates[payload.course_id] = 'SUCCESS';
        draft.discussions = payload.response;
      });
    },
    [types.GET_COURSE_DISCUSSIONS_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getDiscussionsStates[payload.course_id] = 'FAIL';
      });
    },
  },
  initialState
);
