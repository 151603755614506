import api from '@src/api';

export const affiliateMarketingEventTrigger = payload => {
	const {
		track_id,
		event_type,
		purchase_order_list,
	} = payload;
	const data = { purchase_order_list };
	return api.post(
		`affiliate-marketing/event/trigger?track_id=${track_id}&event_type=${event_type}`,
		data,
	);
};
