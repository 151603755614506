import React, { useState, useEffect } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LineShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
} from 'react-share';

import ModalHeader from '../modalHeader';
import { Wrapper, Box, IconList, UrlList } from './Styled';

export default function ShareModal({ onClose }) {
  const [showSuccessBox, setShowSuccessBox] = useState(false);
  const currentUrl = window.location.href;

  useEffect(() => {
    if (showSuccessBox) {
      setTimeout(() => {
        setShowSuccessBox(false);
      }, 3000);
    }
  }, [showSuccessBox]);

  function handleCopy() {
    if (showSuccessBox) {
      return;
    }

    window.navigator.clipboard.writeText(currentUrl);
    setShowSuccessBox(true);
  }

  return (
    <Wrapper onClick={onClose}>
      <Box onClick={(e) => e.stopPropagation()}>
        <div className="login icon-icon_close_31" onClick={onClose} />
        <ModalHeader title="分享此課程" onClose={onClose} />
        <IconList>
          <FacebookShareButton url={currentUrl} className="item">
            <FacebookIcon size={45} round />
            <div className="title">Facebook</div>
          </FacebookShareButton>
          <FacebookMessengerShareButton url={currentUrl} className="item">
            <FacebookMessengerIcon size={45} round />
            <div className="title">Messenger</div>
          </FacebookMessengerShareButton>
          <LineShareButton url={currentUrl} className="item">
            <LineIcon size={45} round />
            <div className="title">LINE</div>
          </LineShareButton>
          <EmailShareButton url={currentUrl} className="item">
            <i className="icon email" />
            <div className="title">Email</div>
          </EmailShareButton>
        </IconList>
        <UrlList>
          <div className="url">{currentUrl}</div>
          <button className="copy-btn" onClick={handleCopy}>
            {showSuccessBox ? '已複製' : '複 製'}
          </button>
        </UrlList>
      </Box>
    </Wrapper>
  );
}
