import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background: rgba(42, 42, 42, 0.61);
  justify-content: center;
  align-items: flex-start;
  display: flex;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const Content = styled.div`
  width: 400px;
  border-radius: 2px;
  box-shadow: rgba(69, 65, 78, 0.08) 0px 1px 15px 0px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 30px 25px;
  position: relative;
  margin: 100px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 160px);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 80px;
    background-color: #f3f3f1;
    max-height: initial;
  }
`;
