import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';

import {
	AFFILIATE_MARKETING_EVENT_TRIGGER_CALL,
} from '@src/store/types/affiliateMarketing';
import {
	affiliateMarketingEventTriggerDone,
	affiliateMarketingEventTriggerFail,
} from '@src/store/actions/affiliateMarketing';

import { affiliateMarketingEventTrigger } from '@src/services/affiliateMarketing';

export const affiliateMarketingEventTriggerEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(AFFILIATE_MARKETING_EVENT_TRIGGER_CALL),
	switchMap(({ payload }) => fromPromise(affiliateMarketingEventTrigger(payload))
		.pipe(
			switchMap(response => of(affiliateMarketingEventTriggerDone({ response }))),
			catchError(error => of(affiliateMarketingEventTriggerFail({ error }))),
		)),
);
