import api from '@src/api';

export const getSchools = payload => api.get('schools', payload);

export const addSchool = payload => api.post('school', payload);

export const getSchoolPlans = payload => api.get('school/plans', payload);

export const checkHostNameExist = ({ hostName }) => api.get(`school/hostname/${hostName}`);

export const getTeachers = payload => api.get('teachers', payload);

export const addTeacher = payload => api.post('teacher', payload);

export const updateTeacher = payload => api.post(`teacher/${payload.id}`, payload);

export const deleteTeacher = payload => api['delete'](`teacher/${payload.id}`, payload);

// 取得所有註冊欄位
export const getRegisterFields = () => api.get('school/register_fields');

export const getSchoolSearch = ({ hostname }) => api.get('school/search', { hostname });
