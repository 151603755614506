import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';
import * as types from '@src/store/types';

const initialState = {
  showModal: false,
  lockScroll: false,
  showCheckModal: false,
  modalData: null,
  modalType: null,
  keyboardShow: false,
};

export default handleActions(
  {
    [types.SET_MODAL](state, { payload: { showModal, modalData } }) {
      return produce(state, (draft) => {
        draft.showModal = showModal;
        draft.modalData = modalData;
      });
    },
    [types.SET_CHECK_MODAL](state, { payload: { showCheckModal, modalData } }) {
      return produce(state, (draft) => {
        draft.showCheckModal = showCheckModal;
        draft.modalData = modalData;
      });
    },
    [types.SET_LOCK_SCROLL](state, { payload: { lockScroll } }) {
      return produce(state, (draft) => {
        draft.lockScroll = lockScroll;
      });
    },
    [types.SET_MODAL_TYPE](state, { payload: { type } }) {
      return produce(state, (draft) => {
        draft.modalType = type;
      });
    },
    [types.SET_KEYBOARD_SHOW](state, { payload: { keyboardShow } }) {
      return produce(state, (draft) => {
        draft.keyboardShow = keyboardShow;
      });
    },
  },
  initialState
);
