export const PURCHASE_STATES = {
	all: 'all',
	completed: 'completed',
	canceled: 'canceled',
};

// These constants are also relative to i18n key.
export const METHOD_CREDIT_CARD = 'credit-card';
export const METHOD_ATM = 'atm';

// These constants are also relative to i18n key and payment api variable, it must be uppercase.
export const INVOICE_METHOD_B2C_ELECTRONIC_INVOICE = 'B2C'; // B2C 電子發票
export const INVOICE_METHOD_B2B_GOV_UNIFORM_INVOICE = 'B2B'; // B2B 統一編號發票(公司)
export const INVOICE_METHOD_DONATE_INVOICE = 'DONATE'; // DONATE 捐贈發票

export const B2C_INVOICE_CARRIER_MEMBER = 'carrier'; // 會員載具
export const B2C_INVOICE_CARRIER_MOBILE_BARCODE_CARRIER = 'mobile_barcode_carrier'; // 手機條碼載具
export const B2C_INVOICE_CARRIER_CITIZEN_DIGITAL_CERTIFICATE = 'citizen_digital_certificate'; // 自然人憑證載具
export const B2C_INVOICE_MEMBER = '2';
export const B2C_INVOICE_MOBILE = '0';
export const B2C_INVOICE_NATURAL_PERSON = '1';

export const DONATE_OPTIONS = [
	{
		key: '25885',
		text: '財團法人伊甸社會福利基金會 (25885)',
	},
	{
		key: '5299',
		text: '社團法人台灣導盲犬協會 (5299)',
	},
	{
		key: '52668',
		text: '社團法人臺北市支持流浪貓絕育計畫協會 (52668)',
	},
	{
		key: '599',
		text: '台灣動物不再流浪協會(599)',
	},
];

export const RECIPIENT_NAME_FIELD = 'name';
export const RECIPIENT_PHONE_FIELD = 'phone';
export const RECIPIENT_CITY_FIELD = 'county';
export const RECIPIENT_REGION_FIELD = 'area';
export const RECIPIENT_ADDRESS_FIELD = 'address';
