import React, { useEffect, createContext, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

export const ScrollToTopContext = createContext<ScrollToTopContextType | undefined>(undefined);


export interface ScrollToTopContextType {
  scrollToTop: () => void;
};


function setScrollTop(value) {
  const hrMainContent = document.querySelector('.hr-main-content');
  if (hrMainContent) {
    hrMainContent.scrollTo(0, 0);
  }
  window.document.body.scrollTop = value;
  if (window.document.documentElement) {
    window.document.documentElement.scrollTop = value;
  }
}

function ScrollToTop({ children }) {
    const router = useRouter();
    // Reference:
    // https://github.com/graysonhicks/react-scrolltop-button/blob/master/src/lib/index.jsx
    const scrollToTop = useCallback(() => {
    const { performance, requestAnimationFrame } = window;
    const speed = 100;
    const target = 0;

    if (
      speed <= 0 ||
      typeof performance === 'undefined' ||
      typeof requestAnimationFrame === 'undefined'
    ) {
      return setScrollTop(target);
    }

    const start = performance.now();
    const initScrollTop =
      document.body.scrollTop ||
      (document.documentElement && document.documentElement.scrollTop) ||
      0;
    const pxsToScrollBy = initScrollTop - target;
    requestAnimationFrame(step);

    function step(timestamp) {
      const delta = timestamp - start;
      const progress = Math.min(delta / speed, 1);
      setScrollTop(initScrollTop - Math.round(progress * pxsToScrollBy));
      if (progress < 1) {
        requestAnimationFrame(step);
      }
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', scrollToTop);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [scrollToTop, router]);

  const value = useMemo(
    () => ({
      scrollToTop,
    }),
    [scrollToTop]
  );

  return (
    <ScrollToTopContext.Provider value={value}>
      {children}
    </ScrollToTopContext.Provider>
  );
}

export default ScrollToTop;
