import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_OVERTIME_APPLICATIONS_CALL,
	POST_OVERTIME_APPLICATION_CALL,
	GET_OVERTIME_APPLICATION_INFO_CALL,
	GET_OVERTIME_APPLICATION_DETAIL_CALL,
	POST_OVERTIME_APPLICATION_APPROVE_CALL,
	GET_OVERTIME_APPLICATION_CONFIRM_CALL,
	POST_OVERTIME_APPLICATION_CONFIRM_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_CALL,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL,
} from '@src/store/types/overtimeApplication';

import {
	getOvertimeApplicationsDone,
	getOvertimeApplicationsFail,
	postOvertimeApplicationDone,
	postOvertimeApplicationFail,
	getOvertimeApplicationInfoDone,
	getOvertimeApplicationInfoFail,
	getOvertimeApplicationDetailCall,
	getOvertimeApplicationDetailDone,
	getOvertimeApplicationDetailFail,
	postOvertimeApplicationApproveDone,
	postOvertimeApplicationApproveFail,
	getOvertimeApplicationConfirmDone,
	getOvertimeApplicationConfirmFail,
	postOvertimeApplicationConfirmDone,
	postOvertimeApplicationConfirmFail,
	postOvertimeApplicationWithdrawDone,
	postOvertimeApplicationWithdrawFail,
	getOvertimeApplicationsApproverViewDone,
	getOvertimeApplicationsApproverViewFail,
	postOvertimeApplicationWithdrawApprovalDone,
	postOvertimeApplicationWithdrawApprovalFail,
} from '@src/store/actions/overtimeApplication';
import {
	getOvertimeApplications,
	postOvertimeApplication,
	getOvertimeApplicationInfo,
	getOvertimeApplicationDetail,
	postOvertimeApplicationApprove,
	getOvertimeApplicationConfirm,
	postOvertimeApplicationConfirm,
	postOvertimeApplicationWithdraw,
	getOvertimeApplicationsApproverView,
	postOvertimeApplicationWithdrawApproval,
} from '@src/services/overtimeApplication';

export const getOvertimeApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_OVERTIME_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(getOvertimeApplications(payload)).pipe(
			switchMap(res => of(getOvertimeApplicationsDone({ res }))),
			catchError(error => of(getOvertimeApplicationsFail({ error }))),
		),
	),
);

export const postOvertimeApplicationEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_OVERTIME_APPLICATION_CALL),
	switchMap(
		({ payload }) => fromPromise(postOvertimeApplication(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postOvertimeApplicationDone({ res }),
				);
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(
					postOvertimeApplicationFail({ error }),
				);
			}),
		),
	),
);

export const getOvertimeApplicationInfoEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_OVERTIME_APPLICATION_INFO_CALL),
	switchMap(
		({ payload }) => fromPromise(getOvertimeApplicationInfo(payload)).pipe(
			switchMap(res => of(getOvertimeApplicationInfoDone({ res }))),
			catchError(error => of(getOvertimeApplicationInfoFail({ error }))),
		),
	),
);

export const getOvertimeApplicationDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_OVERTIME_APPLICATION_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getOvertimeApplicationDetail(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getOvertimeApplicationDetailDone({ res }));
			}),
			catchError(error => of(getOvertimeApplicationDetailFail({ error }))),
		),
	),
);

export const postOvertimeApplicationApproveEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_OVERTIME_APPLICATION_APPROVE_CALL),
	switchMap(
		({ payload }) => fromPromise(postOvertimeApplicationApprove(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postOvertimeApplicationApproveDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postOvertimeApplicationApproveFail({ error }));
			}),
		),
	),
);

export const getOvertimeApplicationConfirmEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_OVERTIME_APPLICATION_CONFIRM_CALL),
	switchMap(
		({ payload }) => fromPromise(getOvertimeApplicationConfirm(payload)).pipe(
			switchMap(res => of(getOvertimeApplicationConfirmDone({ res }))),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(
					getOvertimeApplicationConfirmFail({ error }),
				);
			}),
		),
	),
);

export const postOvertimeApplicationConfirmEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_OVERTIME_APPLICATION_CONFIRM_CALL),
	switchMap(
		({ payload }) => fromPromise(postOvertimeApplicationConfirm(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postOvertimeApplicationConfirmDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postOvertimeApplicationConfirmFail({ error }));
			}),
		),
	),
);

export const postOvertimeApplicationWithdrawEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_OVERTIME_APPLICATION_WITHDRAW_CALL),
	switchMap(
		({ payload }) => fromPromise(postOvertimeApplicationWithdraw(payload)).pipe(
			switchMap(res => [
				getOvertimeApplicationDetailCall({ id: payload.id }),
				postOvertimeApplicationWithdrawDone({ res }),
			]),
			catchError(error => of(postOvertimeApplicationWithdrawFail({ error }))),
		),
	),
);

export const getOvertimeApplicationsApproverViewEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL),
	switchMap(
		({ payload }) => fromPromise(getOvertimeApplicationsApproverView(payload)).pipe(
			switchMap(res => of(getOvertimeApplicationsApproverViewDone({ res }))),
			catchError(error => of(getOvertimeApplicationsApproverViewFail({ error }))),
		),
	),
);

export const postOvertimeApplicationWithdrawApprovalEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL),
	switchMap(
		({ payload }) => fromPromise(postOvertimeApplicationWithdrawApproval(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postOvertimeApplicationWithdrawApprovalDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postOvertimeApplicationWithdrawApprovalFail({ error }));
			}),
		),
	),
);
