/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { MODAL_TYPE_LOGIN, MODAL_TYPE_REGISTER } from '@src/const/common';
import { getThemeSelector } from '@src/store/selectors';
import * as actionCreators from '@src/store/actions';
import SelectAuthIcon from '@src/assets/images/select_auth.svg';

import LoginWithOther from './components/LoginWithOther';
import ModalHeader from '../modalHeader';
import {
	Wrapper,
	Content,
	SelectBtn,
} from './Styled';

const ButtonContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledModalHeader = styled(ModalHeader)`
	margin-bottom: 15px;
`;

const ImgContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 26px 0;
`;

const SelectAuthModal = ({ onClose, actions, t }) => {
	const [targetClass, setTargetClass] = useState();
	const { themes: { disable_oauth: isDisableOauth } } = useSelector(getThemeSelector);

	const handleChangeModal = type => {
		actions.setModalType({ type });
	};

	const handleClickStart = e => {
		setTargetClass(e.target.className);
	};

	const handleClickEnd = e => {
		if (
			e.target.id === 'modal-wrap' &&
			window.innerWidth > 768 &&
			targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass();
	};

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<div role="presentation" className="login icon-icon_close_31" onClick={onClose} />
				{
					isDisableOauth && (
						<ImgContent>
							<img src={SelectAuthIcon} />
						</ImgContent>
					)
				}
				<StyledModalHeader title={t('auth_modal:continue_with')} onClose={onClose} />
				{
					!isDisableOauth && <LoginWithOther isBorderTop={false} isBorderBottom />
				}
				<ButtonContent>
					<SelectBtn
						className="forget-password"
						onClick={() => handleChangeModal(MODAL_TYPE_LOGIN)}
					>
						{t('auth_modal:login')}
					</SelectBtn>
					<SelectBtn
						className="forget-password"
						onClick={() => handleChangeModal(MODAL_TYPE_REGISTER)}
					>
						{t('auth_modal:register')}
					</SelectBtn>
				</ButtonContent>
			</Content>
		</Wrapper>
	);
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(null, mapDispatchToProps)(SelectAuthModal),
);
