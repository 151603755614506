import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_PUNCH_IN_CALL,
	GET_PUNCH_IN_DONE,
	GET_PUNCH_IN_FAIL,
	POST_PUNCH_IN_RECORD_CALL,
	POST_PUNCH_IN_RECORD_DONE,
	POST_PUNCH_IN_RECORD_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	punchIn: {},
	punchInSideEffect: defaultSideEffectState,
	postPunchInRecord: {},
	postPunchInRecordSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_PUNCH_IN_CALL](state) {
		return update(state, {
			punchInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_PUNCH_IN_DONE](state, { payload: { res } }) {
		return update(state, {
			punchIn: { $set: res },
			punchInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_PUNCH_IN_FAIL](state, { payload }) {
		return update(state, {
			punchInSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[POST_PUNCH_IN_RECORD_CALL](state) {
		return update(state, {
			postPunchInRecordSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_PUNCH_IN_RECORD_DONE](state, { payload: { res } }) {
		return update(state, {
			postPunchInRecord: { $set: res },
			postPunchInRecordSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_PUNCH_IN_RECORD_FAIL](state, { payload }) {
		return update(state, {
			postPunchInRecordSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
