// eslint-disable-next-line import/no-cycle
import api from '@src/api';

import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getOwnedDepartment = (payload): Observable<Action> => (
	api.get('departments/owned_departments', payload, { hasPagination: true })
);

export const getDepartmentsLearningRecords = (payload): Observable<Action> => (
	api.get('departments/learning-records', payload, { hasPagination: true })
);
