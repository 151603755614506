import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { announcement } from '@src/services';
import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const getCourseAnnouncementsCall = (action$, store) =>
  action$.pipe(
    ofType(types.GET_COURSE_ANNOUNCEMENTS_CALL),
    switchMap(({ payload }) =>
      fromPromise(announcement.getCourseAnnouncements(payload)).pipe(
        switchMap((res) => {
          return [
            actions.getCourseAnnouncementsDone({
              course_id: payload.course_id,
              response: res,
            }),
          ];
        }),
        catchError((err) => [actions.getCourseAnnouncementsFail(err)])
      )
    )
  );
