import styled, { css } from 'styled-components';

const disabledStyle = css`
  border: 1px solid #9C9C9C;
  background: #ECECEC;
  & > input {
    background: #ECECEC;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 48.5px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.54px;
  padding: 11px;
  border: solid 1px ${({ withError }) => (withError ? '#D0021B' : '#dbdbdb')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: border-color 0.2s ease-in-out;
  background: #fff;

  &:focus-within {
    border: solid 1px ${({ withError }) => (withError ? '#D0021B' : '#000000')};
    outline: none;
    color: #222;
  }

  ${props => props.$isDisabled && disabledStyle}
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  color: #676767;
`;
