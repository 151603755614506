import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL,
	RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	makeUpPunchApplications: [],
	getMakeUpPunchApplicationsSideEffect: defaultSideEffectState,
	postMakeUpPunchApplications: [],
	postMakeUpPunchApplicationsSideEffect: defaultSideEffectState,
	getMakeUpPunchApplicationsApproverView: [],
	getMakeUpPunchApplicationsApproverViewSideEffect: defaultSideEffectState,
	getMakeUpPunchApplicationsPunchOptions: [],
	getMakeUpPunchApplicationsPunchOptionsSideEffect: defaultSideEffectState,
	makeUpPunchApplicationsDetail: [],
	getMakeUpPunchApplicationsDetailSideEffect: defaultSideEffectState,
	postMakeUpPunchApplicationsApprove: [],
	postMakeUpPunchApplicationsApproveSideEffect: defaultSideEffectState,
	postMakeUpPunchApplicationsWithdraw: [],
	postMakeUpPunchApplicationsWithdrawSideEffect: defaultSideEffectState,
	postMakeUpPunchApplicationsWithdrawApproval: [],
	postMakeUpPunchApplicationsWithdrawApprovalSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_MAKE_UP_PUNCH_APPLICATIONS_CALL](state) {
		return update(state, {
			getMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			makeUpPunchApplications: { $set: res },
			getMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			getMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_MAKE_UP_PUNCH_APPLICATIONS_CALL](state) {
		return update(state, {
			postMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			postMakeUpPunchApplications: { $set: res },
			postMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			postMakeUpPunchApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL](state) {
		return update(state, {
			getMakeUpPunchApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE](state, { payload: { res } }) {
		return update(state, {
			getMakeUpPunchApplicationsApproverView: { $set: res },
			getMakeUpPunchApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL](state, { payload }) {
		return update(state, {
			getMakeUpPunchApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL](state) {
		return update(state, {
			getMakeUpPunchApplicationsPunchOptionsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			getMakeUpPunchApplicationsPunchOptions: { $set: res },
			getMakeUpPunchApplicationsPunchOptionsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL](state, { payload }) {
		return update(state, {
			getMakeUpPunchApplicationsPunchOptionsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL](state) {
		return update(state, {
			getMakeUpPunchApplicationsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			makeUpPunchApplicationsDetail: { $set: res },
			getMakeUpPunchApplicationsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getMakeUpPunchApplicationsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL](state) {
		return update(state, {
			makeUpPunchApplicationsDetail: { $set: {} },
		});
	},

	[POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL](state) {
		return update(state, {
			postMakeUpPunchApplicationsApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE](state, { payload: { res } }) {
		return update(state, {
			postMakeUpPunchApplicationsApprove: { $set: res },
			postMakeUpPunchApplicationsApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL](state, { payload }) {
		return update(state, {
			postMakeUpPunchApplicationsApproveSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL](state) {
		return update(state, {
			postMakeUpPunchApplicationsWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE](state, { payload: { res } }) {
		return update(state, {
			postMakeUpPunchApplicationsWithdraw: { $set: res },
			postMakeUpPunchApplicationsWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL](state, { payload }) {
		return update(state, {
			postMakeUpPunchApplicationsWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL](state) {
		return update(state, {
			postMakeUpPunchApplicationsWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE](state, { payload: { res } }) {
		return update(state, {
			postMakeUpPunchApplicationsWithdrawApproval: { $set: res },
			postMakeUpPunchApplicationsWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL](state, { payload }) {
		return update(state, {
			postMakeUpPunchApplicationsWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

}, initialState);
