import { switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

const STASH_KEY = 'STASH_KEY';
const STASH_TIME = 'STASH_TIME';

export const addActionsToStash = (action$, store) => action$.pipe(
	ofType(types.ADD_ACTIONS_TO_STASH),
	switchMap(({ payload }) => {
		localStorage.setItem(STASH_KEY, JSON.stringify(payload));
		localStorage.setItem(STASH_TIME, Date.now());

		return [];
	}),
);

// 超過兩分鐘就捨棄登入前，希望加入購物車的行為
const EXPIRE_TIME = 120000;

export const popActionsFromStash = (action$, store) => action$.pipe(
	ofType(types.EMAIL_LOGIN_DONE, types.CHECK_LOGIN_SESSION_DONE),
	switchMap(() => {
		const stashActions = localStorage.getItem('STASH_KEY');
		const stashTime = localStorage.getItem('STASH_TIME') || 0;
		const isExpired = Date.now() - parseInt(stashTime, 10) > EXPIRE_TIME;

		if (!stashActions || isExpired) {
			return [actions.clearStash()];
		}

		const nextActions =
			JSON.parse(stashActions)?.map(
				waitAction => actions[waitAction.actionMethod](waitAction.actionPayload),
			) || [];

		return [...nextActions, actions.clearStash()];
	}),
);

export const clearStash = (action$, store) => action$.pipe(
	ofType(types.CLEAR_STASH, types.LOGOUT_DONE),
	switchMap(() => {
		localStorage.removeItem(STASH_TIME);
		localStorage.removeItem(STASH_KEY);

		return [];
	}),
);
