import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  background: rgba(42, 42, 42, 0.61);
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const Content = styled.div`
  width: 400px;
  border-radius: 2px;
  box-shadow: rgba(69, 65, 78, 0.08) 0px 1px 15px 0px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 30px 25px;
  position: relative;
  margin: 100px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 70px;
    background-color: #FFFFFF;
    max-height: initial;
  }
`;

export const LoginBtn = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 2px;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  cursor: pointer;
  background: #3091fd;

  &:hover {
    background: #005abe;
  }
`;

export const SelectBtn = styled.button`
  width: 100%;
  height: 45px;
  text-align: center;
  color: #3091FD;
  border: 1px solid #3091FD;
  border-radius: 2px;
  cursor: pointer;
  
  :first-child {
    margin-right: 16px;
  }

  &:hover {
    color: #FFF;
    background: #005abe;
    border: 1px solid #005abe;
  }
`;

export const LinkButton = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--primaryDark);
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &.forget-password {
    margin: 0 auto 20px;
  }
`;

export const ErrorMsgBox = styled.div`
  font-family: NotoSansTC;
  font-size: 14px;
  font-weight: 300;
  color: #d0021b;
  margin: 3px 0;
`;

export const Box = styled.div`
  text-align: center;
  margin: 20px 0;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  flex: 1;

  span {
    font-family: NotoSansTC;
    font-size: 14px;
    color: rgb(34, 34, 34);
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    border-color: #dbdbdb;
    align-items: baseline;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  p {
    font-family: NotoSansTC;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.43px;
    color: #222;
    margin: 0;
  }
  .lang-box {
    position: relative;
    svg {
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }
  select {
    box-sizing: border-box;
    width: 151px;
    height: 40px;
    font-family: NotoSansTC;
    font-size: 16px;
    font-weight: 300;
    color: #222222;
    padding-left: 35px;
    border-radius: 2px;
    border: solid 1px #222222;
    appearance: none;
  }
`;
