import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getAllPurchaseRecordsByOrderList = ({ include, purchase_order_list }: {
	include: string;
	purchase_order_list: string;
}): Observable<Action> => api.get('purchases', {
	include,
	purchase_order_list,
});
