// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getMakeUpPunchApplications = (payload: {
	year: number;
	within: string;
	status: string;
}): Observable<Action> => (
	api.get('make-up-punch-applications/', payload)
);

export const postMakeUpPunchApplication = (payload): Observable<Action> => (
	api.post('make-up-punch-applications/', payload)
);

export const getMakeUpPunchApplicationsApproverView = (payload: {
	year: number;
	within: string;
	status: string;
	waiting_only: boolean
}): Observable<Action> => (
	api.get('make-up-punch-applications/approver_view', payload)
);

export const getMakeUpPunchApplicationsPunchOptions = (): Observable<Action> => (
	api.get('make-up-punch-applications/punch_options')
);

export const getMakeUpPunchApplicationDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`make-up-punch-applications/${payload.id}`)
);

export const postMakeUpPunchApplicationApprove = (payload: {
	id: string;
	comments: string;
	is_approved: boolean;
}): Observable<Action> => (
	api.post(`make-up-punch-applications/${payload.id}/approve`, payload)
);

export const postMakeUpPunchApplicationWithdraw = (payload: { id: string }): Observable<Action> => (
	api.post(`make-up-punch-applications/${payload.id}/withdraw`)
);

export const postMakeUpPunchApplicationWithdrawApproval = (payload: { id: string }): Observable<Action> => (
	api.post(`make-up-punch-applications/${payload.id}/withdraw_approval`)
);
