import { handleActions } from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';

import { formatTimeFloor } from '@src/utils/time';
import { STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE } from '@src/const/common';
import {
	GET_WEBINAR_CALL,
	GET_WEBINAR_DONE,
	GET_WEBINAR_FAIL,
	GET_WEBINARS_CALL,
	GET_WEBINARS_DONE,
	GET_WEBINARS_FAIL,
	SET_CURRENT_WEBINAR_PRICE,
	SET_WEBINAR_COUNTDOWN_FOR_SALE,
} from '@src/store/types/webinar';

const initialState = {
	webinar: {},
	getWebinarState: {},
	webinarsList: [],
	webinarsListState: STATE_IDLE,
	currentPrice: 0,
	countDownForSale: formatTimeFloor(0),
};

export default handleActions({
	[GET_WEBINAR_CALL](state, { payload }) {
		return produce(state, draft => {
			draft.getWebinarState = {
				...draft.getWebinarState,
				[payload.webinarShortLink]: STATE_LOADING,
			};
		});
	},
	[GET_WEBINAR_DONE](state, { payload }) {
		return produce(state, draft => {
			draft.getWebinarState = {
				...draft.getWebinarState,
				[payload.webinarShortLink]: STATE_SUCCESS,
			};
			draft.webinar = {
				...draft.webinar,
				[payload.webinarShortLink]: payload.response,
			};
		});
	},
	[GET_WEBINAR_FAIL](state, { payload }) {
		return produce(state, draft => {
			draft.getWebinarState = {
				...draft.getWebinarState,
				[payload.webinarShortLink]: STATE_FAIL,
			};
		});
	},
	// get webinars list
	[GET_WEBINARS_CALL](state) {
		return update(state, {
			webinarsListState: { $set: STATE_LOADING },
		});
	},
	[GET_WEBINARS_DONE](state, { payload }) {
		return update(state, {
			webinarsList: { $set: payload.response.map(webinar => ({ ...webinar, type: 'webinar' })) },
			webinarsListState: { $set: STATE_SUCCESS },
		});
	},
	[GET_WEBINARS_FAIL](state) {
		return update(state, {
			webinarsListState: { $set: STATE_FAIL },
		});
	},
	[SET_CURRENT_WEBINAR_PRICE](state, { payload }) {
		return update(state, {
			currentPrice: { $set: payload.currentPrice },
		});
	},
	[SET_WEBINAR_COUNTDOWN_FOR_SALE](state, { payload }) {
		return update(state, {
			countDownForSale: { $set: payload.countdown },
		});
	},
}, initialState);
