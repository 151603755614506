/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-cycle
import api from '@src/api';

export const getLearningPaths = ({ ssrHeaders, path_ids }: {
	ssrHeaders: {
		[x:string]: string;
	};
	path_ids?: string;
}): any => api.get('learning-paths/', { enable: true, ...path_ids ? { path_ids } : {} }, { ssrHeaders });

export const getMineLearningPaths = ({ ssrHeaders, status }: {
	ssrHeaders: {
		[x:string]: string;
	};
	status: string;
}): any => api.get('learning-paths/mine', { status }, { ssrHeaders });

export const getLearningPathsByShortLink = ({ ssrHeaders, shortLink }: {
	ssrHeaders: { [x:string]: string };
	shortLink: string
}): any => api.get(`learning-paths/${shortLink}`, {}, { ssrHeaders });

export const joinLearningPaths = ({ ssrHeaders, id }: {
	ssrHeaders: { [x:string]: string };
	id: string
}): any => api.post(`learning-paths/${id}/join`, {}, { ssrHeaders });

export const leaveLearningPaths = ({ ssrHeaders, id }: {
	ssrHeaders: { [x:string]: string };
	id: string
}): any => api.post(`learning-paths/${id}/leave`, {}, { ssrHeaders });

export const learningPathsProgress = ({ ssrHeaders, id }: {
	ssrHeaders: { [x:string]: string };
	id: string
}): any => api.get(`learning-paths/${id}/progress`, {}, { ssrHeaders });

export const getLearningPathsCommodities = ({ ssrHeaders, shortLink }: {
	ssrHeaders: { [x:string]: string; };
	shortLink: string
}): any => api.get(`learning-paths/${shortLink}/commodities`, {}, { ssrHeaders });

export const postLearningPathsCertificate = ({ ssrHeaders, id, method }: {
	ssrHeaders: { [x:string]: string };
	id: string
	method: string
}): any => api[method](`learning-paths/${id}/certificate`, {}, { ssrHeaders });
