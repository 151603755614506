import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_SCHOOL_ANNOUNCEMENTS_CALL,
	GET_SCHOOL_ANNOUNCEMENTS_DONE,
	GET_SCHOOL_ANNOUNCEMENTS_FAIL,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL,
	POST_SCHOOL_ANNOUNCEMENT_READ_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_DONE,
	POST_SCHOOL_ANNOUNCEMENT_READ_FAIL,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	schoolAnnouncements: {
		all: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		unread: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		modalList: {
			all: {
				data: [],
				pagination: {
					totalCount: 0,
				},
			},
			unread: {
				data: [],
				pagination: {
					totalCount: 0,
				},
			},
		},
	},
	getSchoolAnnouncementsSideEffect: defaultSideEffectState,
	schoolAnnouncementDetail: {},
	getSchoolAnnouncementDetailSideEffect: defaultSideEffectState,

	schoolAnnouncementByIndex: {},
	getSchoolAnnouncementByIndexSideEffect: defaultSideEffectState,

	postSchoolAnnouncementRead: {},
	postSchoolAnnouncementReadSideEffect: defaultSideEffectState,

	postSchoolAnnouncementReadAll: {},
	postSchoolAnnouncementReadAllSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_SCHOOL_ANNOUNCEMENTS_CALL](state) {
		return update(state, {
			getSchoolAnnouncementsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENTS_DONE](state, { payload: { res, isUnread, isModalList } }) {
		return update(state, {
			...isModalList ? {
				...isUnread ? {
					schoolAnnouncements: { modalList: { unread: { $set: res } } },
				} : {
					schoolAnnouncements: { modalList: { all: { $set: res } } },
				},
			} : {
				...isUnread ? {
					schoolAnnouncements: { unread: { $set: res } },
				} : {
					schoolAnnouncements: { all: { $set: res } },
				},
			},
			getSchoolAnnouncementsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENTS_FAIL](state, { payload }) {
		return update(state, {
			getSchoolAnnouncementsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL](state) {
		return update(state, {
			schoolAnnouncementDetail: { $set: {} },
			getSchoolAnnouncementDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			schoolAnnouncementDetail: { $set: res },
			getSchoolAnnouncementDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getSchoolAnnouncementDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL](state) {
		return update(state, {
			getSchoolAnnouncementByIndexSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE](state, { payload: { res } }) {
		return update(state, {
			schoolAnnouncementByIndex: { $set: res },
			getSchoolAnnouncementByIndexSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL](state, { payload }) {
		return update(state, {
			getSchoolAnnouncementByIndexSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_CALL](state) {
		return update(state, {
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_DONE](state, { payload: { res } }) {
		return update(state, {
			postSchoolAnnouncementRead: { $set: res },
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_FAIL](state, { payload }) {
		return update(state, {
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL](state) {
		return update(state, {
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE](state, { payload: { res } }) {
		return update(state, {
			postSchoolAnnouncementRead: { $set: res },
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL](state, { payload }) {
		return update(state, {
			postSchoolAnnouncementReadSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
