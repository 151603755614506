import api from '@src/api';

export const getProfile = ({ ssrHeaders }) =>
  api.get('user/profile', undefined, { ssrHeaders });

export const getNotifications = () => api.get('notifications');

export const getNotificationsSeen = (id) => api.get(`notification/${id}`);

export const getNotificationsHaveReadCall = (id) =>
  api.post(`/notifications/read`);

export const getBindCreditCardInfoCall = () => api.get(`payment/credit-cards`);

export const deleteBindCreditCardInfoCall = ({ CARD6NO, CARD4NO }) =>
  api.delete(`payment/credit-card/${CARD6NO}/${CARD4NO}`);

export const rebindCreditCardInfoCall = ({ data = {} }) =>
  api.post(`payment/credit-card/bind`, data);

export const getInvoiceListCall = ({ owned_school_id = '' }) =>
  api.get(`purchases?owned_school_id=${owned_school_id}`);

export const modifyInvoiceIncNumberCall = ({ CARD6NO, CARD4NO, data }) =>
  api.post(`payment/credit-card/${CARD6NO}/${CARD4NO}/meta`, data);

export const downloadInvoice = ({ invoiceNumber, direct }) =>
  api.get(`invoice/${invoiceNumber}/download`, { direct });

// 取得當前使用方案
export const getUserCurrentPlanCall = ({}) => api.get(`school`);

// 轉正式方案
export const changeUserFormalPlanCall = () => api.post(`school/activate`);

// 刪除試用
export const deleteUserTrialPlanCall = ({}) => api.delete(`school`);

// 更換其他方案
export const putUserPlanCall = ({ data = {} }) => api.post(`school`, data);

// 反饋意見
export const opinionUserPlanCall = ({ data = {} }) =>
  api.post(`school/suggestion`, data);

// 取消方案續約
export const cancelUserPlanCall = ({ reason = '' }) =>
  api.delete(`school?reason=${reason}`);

// 更換大頭照
export const putUserProfilePictureCall = ({ formData }) =>
  api.post(`user/profile/picture`, formData);

// 更新資料
export const putUserProfileCall = ({ form }) => api.post(`user/profile`, form);

// 更新手機
export const putProfileMobileCall = ({ form = {} }) =>
  api.post(`user/mobile`, form);

// 手機驗證碼
export const verificationProfileMobileCall = ({ form = {} }) =>
  api.post(`user/mobile/confirm`, form);

// 更新密碼
export const putProfilePasswordCall = ({ form = {} }) =>
  api.post(`user/password`, form);

export const bindUserEmailCall = ({ form = {} }) =>
  api.post(`user/bind/email`, form);

export const postVerificationUserEmailCall = ({ form = {} }) =>
  api.post(`user/email/confirm`, form);

export const bindOtherUsernameCall = ({ type, category }) => api.get(`user/bind/${type}?category=${category}`);

export const unbindOtherUsernameCall = ({ type }) =>
  api.delete(`user/bind/${type}`);

export const getOtherUserProfile = ({ param, ssrHeaders }) =>
  api.get(`user/${param.user_id}/profile`, undefined, { ssrHeaders });

export const getLearningCenter = () => api.get('user/my-learning-center/');

export const getLearningCenterAssignCourse =
  payload => api.get('user/my-learning-center/assigned-courses', payload, { hasPagination: true });

export const getLearningCenterLearningPath =
  payload => api.get('learning-paths/mine', payload, { hasPagination: true });

export const getUserJoinedCoursesStatistics =
  payload => api.get(`user/${payload.userId || 'me'}/joined_courses_statistics`, payload, { hasPagination: true });

export const getUserJoinedLearningPathStatistics =
  payload => api.get(`user/${payload.userId || 'me'}/joined_learning_path_statistics`, payload, { hasPagination: true });

export const getUserLearningStatistics =
  (payload) => api.get(`user/${payload?.userId || 'me'}/learning_statistics`);

export const getLearningRecordsMine =
  (payload) => api.get(`learning-records/?student_id=${payload?.userId || 'me'}`, payload, { hasPagination: true });

export const getLearningLevel =
  () => api.get('learning-level/');

export const postUserLanguage = (payload) => api.post('user/language', payload);
