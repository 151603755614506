import styled, { css } from 'styled-components';

const disabledStyleBtn = css`
  color: #9C9C9C;
  background: #ECECEC;
  cursor: not-allowed;
  &:hover {
    background: #ECECEC;
  }
`;

export const BlueBtnText = styled.span`
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.47px;
  color: #676767;
  margin: 0 0 0 5px;
`;

export const Wrapper = styled.div`
  width: 100vw;
  background: rgba(42, 42, 42, 0.61);
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const Content = styled.div`
  width: 400px;
  border-radius: 2px;
  box-shadow: rgba(69, 65, 78, 0.08) 0px 1px 15px 0px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 30px 25px 20px;
  position: relative;
  margin: 100px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 100vh;

  .des {
    font-size: 14px;
    text-align: center;
    margin-bottom: 52px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 80px;
    background-color: #f3f3f1;
    max-height: initial;
  }
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;

export const EmailInputWrapper = styled.div`
  margin-top: 15px;
`;

export const BlueBtn = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 2px;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  cursor: pointer;
  background: #3091fd;
  border-radius: 4px;
  &:hover {
    background: #005abe;
  }
  ${props => props.$isDisabled && disabledStyleBtn}
`;

export const ResendInfo = styled.div`
  background: #E7F4F9;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  color: #222;
`;
