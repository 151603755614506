/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import api from '@src/api';

export const getAllCategories = ({ ssrHeaders }: {
	ssrHeaders: {
		[x:string]: string;
	};
}): any => api.get('categories/', {}, { ssrHeaders });

export const getCommoditySearch = payload => {
	// eslint-disable-next-line no-param-reassign
	if (!payload.commodity_type) delete payload.commodity_type;
	return api.get(
		'commodity/search',
		{
			...payload,
			category_short_link: payload.category_short_link === 'all' ? '' : payload.category_short_link,
		},
		{ ssrHeaders: payload.ssrHeaders, hasPagination: true },
	);
};

export const getCommodityPublishedType = payload => api.get(
	'commodity/published-types', {}, { ssrHeaders: payload.ssrHeaders },
);
