import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_JOBS_CALL,
	POST_JOB_APPLY_CALL,
	GET_JOB_DETAIL_CALL,
	POST_JOB_BINDING_CALL,
	POST_JOB_VIEWED_CALL,
} from '@src/store/types/job';

import {
	getJobsDone,
	getJobsFail,
	postJobApplyDone,
	postJobApplyFail,
	getJobDetailDone,
	getJobDetailFail,
	postJobBingingDone,
	postJobBingingFail,
	postJobViewedDone,
	postJobViewedFail,
} from '@src/store/actions/job';
import { toastShowCall } from '@src/store/actions/ui';
import {
	getJobs,
	postJobApply,
	getJobDetail,
	postJobBinding,
	postJobViewed,
} from '@src/services/job';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const getJobsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_JOBS_CALL),
	switchMap(
		({ payload }) => fromPromise(getJobs(payload)).pipe(
			switchMap(res => of(getJobsDone({ res }))),
			catchError(error => of(getJobsFail({ error }))),
		),
	),
);

export const postJobApplyEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_JOB_APPLY_CALL),
	switchMap(
		({ payload }) => fromPromise(postJobApply(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postJobApplyDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postJobApplyFail({ error }),
			)),
		),
	),
);

export const getJobDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_JOB_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getJobDetail(payload)).pipe(
			switchMap(res => of(getJobDetailDone({ res }))),
			catchError(error => of(getJobDetailFail({ error }))),
		),
	),
);

export const patchJobEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_JOB_BINDING_CALL),
	switchMap(
		({ payload }) => fromPromise(postJobBinding(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postJobBingingDone({ res }),
				);
			}),
			catchError(error => of(
				postJobBingingFail({ error }),
			)),
		),
	),
);

export const postJobViewedEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_JOB_VIEWED_CALL),
	switchMap(
		({ payload }) => fromPromise(postJobViewed(payload)).pipe(
			switchMap(res => of(postJobViewedDone({ res }))),
			catchError(error => of(postJobViewedFail({ error }))),
		),
	),
);
