// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getAttendanceHistory = (payload: {
	year: number;
	month: number;
}): Observable<Action> => (
	api.get('attendance-history/', payload)
);

export const deletePunchInRecord = (payload: {
	id: number;
}): Observable<Action> => (
	api['delete'](`punch-in-records/${payload.id}`)
);
