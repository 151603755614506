import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { payment } from '@src/services';
import * as actions from '@src/store/actions';
import { getCartProductsCall } from '@src/store/actions/cart';
import * as types from '@src/store/types';

export const getCreditCardsCall = (action$, store) => action$.pipe(
	ofType(types.GET_CREDIT_CARDS_CALL),
	switchMap(({ payload }) => fromPromise(payment.getCreditCards(payload)).pipe(
		map(res => actions.getCreditCardsDone(res)),
		catchError(err => of(actions.getCreditCardsFail(err))),
	)),
);

export const addCreditCardCall = (action$, store) => action$.pipe(
	ofType(types.ADD_CREDIT_CARD_CALL),
	switchMap(({ payload: { cardParam, invoiceParam } }) => fromPromise(payment.addCreditCard(cardParam)).pipe(
		switchMap(res => {
			const { id, card6_no, card4_no } = res;
			const newActions = [actions.addCreditCardDone(res)];

			newActions.push(
				actions.editCreditCardMetaCall({
					invoiceParam,
					card6_no,
					card4_no,
					id,
				}),
			);

			return newActions;
		}),
		catchError(error => of(actions.addCreditCardFail({ error }))),
	)),
);

export const editCreditCardMetaCall = (action$, store) => action$.pipe(
	ofType(types.EDIT_CREDIT_CARD_META_CALL),
	switchMap(({ payload, payload: { id } }) => fromPromise(payment.editCreditCardMeta(payload)).pipe(
		switchMap(res => [
			actions.editCreditCardMetaDone(res),
			actions.historyPushCall({
				pathname: '/add-school-page',
				search: `?cardId=${id}`,
			}),
		]),
		catchError(err => of(actions.editCreditCardMetaFail(err))),
	)),
);

export const payViaCreditCardCall = (action$, store) => action$.pipe(
	ofType(types.PAY_VIA_CREDIT_CARD_CALL),
	switchMap(({ payload }) => fromPromise(payment.payViaCreditCard(payload)).pipe(
		switchMap(res => [
			actions.payViaCreditCardDone(res),
			actions.historyPushCall({
				pathname: `/checkout-success/${payload.purchase_id}`,
			}),
		]),
		catchError(err => of(actions.payViaCreditCardFail(err))),
	)),
);

export const payViaVaccCall = (action$, store) => action$.pipe(
	ofType(types.PAY_VIA_VACC_CALL),
	switchMap(({ payload }) => fromPromise(payment.payViaVacc(payload)).pipe(
		switchMap(res => [
			getCartProductsCall(),
			actions.payViaVaccDone(res),
			actions.historyPushCall({
				pathname: `/checkout-success/${payload.purchase_id}`,
			}),
		]),
		catchError(err => of(actions.payViaVaccFail(err))),
	)),
);
