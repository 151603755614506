// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

interface IPunchInRecord {
	duty: true,
  ip_setting_id: string;
  is_overtime: string;
  latitude: string;
  location_id: string;
  longitude: string;
  non_overtime_reason: string;
  punch_in_type: string;
  reason: string;
}

export const getPunchIn = (): Observable<Action> => (
	api.get('punch-in-info/')
);

export const postPunchInRecord = (payload: IPunchInRecord): Observable<Action> => (
	api.post('punch-in-records/', payload)
);
