// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

interface IApplyJob {
	id: string;
	data: {
		field_id?: string;
		value?: string;
	}[]
}

export const getJobs = (payload: {
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('jobs/', payload, { hasPagination: true })
);

export const postJobApply = (payload: IApplyJob): Observable<Action> => (
	api.post(`jobs/${payload.id}/apply`, payload.data)
);

export const getJobDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`jobs/${payload.id}`)
);

export const postJobBinding = (payload: { id: string }): Observable<Action> => (
	api.post(`/resumes/${payload.id}/binding`)
);

export const postJobViewed = (payload: { id: string }): Observable<Action> => (
	api.post(`/jobs/${payload.id}/viewed`)
);
