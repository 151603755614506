import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap, exhaustMap } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';

import {
	GET_ALL_CATEGORIES_CALL,
	GET_COMMODITY_SEARCH_CALL,
	GET_COMMODITY_SEARCH_SWITCH_CALL,
	GET_COMMODITY_PUBLISHED_TYPES_CALL,
} from '@src/store/types/category';
import {
	getAllCategoriesDone,
	getAllCategoriesFail,
	getCommoditySearchDone,
	getCommoditySearchFail,
	getCommodityPublishedTypeDone,
	getCommodityPublishedTypeFail,
} from '@src/store/actions/category';
import { getAllCategories, getCommoditySearch, getCommodityPublishedType } from '@src/services/category';

export const getAllCategoriesEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_ALL_CATEGORIES_CALL),
		switchMap(({ payload }) => (
			fromPromise(getAllCategories(payload)).pipe(
				switchMap(response => of(getAllCategoriesDone({ response }))),
				catchError(error => of(getAllCategoriesFail({ error }))),
			)
		)),
	)
);

export const getCommodityPublishedTypeEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_COMMODITY_PUBLISHED_TYPES_CALL),
		switchMap(({ payload }) => (
			fromPromise(getCommodityPublishedType(payload)).pipe(
				switchMap(response => of(getCommodityPublishedTypeDone({ response }))),
				catchError(error => of(getCommodityPublishedTypeFail({ error }))),
			)
		)),
	)
);

export const getCommoditySearchEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_COMMODITY_SEARCH_CALL),
		mergeMap(({ payload }) => (
			fromPromise(getCommoditySearch(payload)).pipe(
				switchMap((response: { data: [], pagination: { total: number } }) => of(
					getCommoditySearchDone({
						list: response.data,
						categoryId: payload.category_short_link || payload.category_id || 'all',
						pagination: response.pagination,
					}),
				)),
				catchError(error => of(getCommoditySearchFail({ error }))),
			)
		)),
	)
);

export const getCommoditySearchSwitchEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_COMMODITY_SEARCH_SWITCH_CALL),
		exhaustMap(({ payload }) => (
			fromPromise(getCommoditySearch(payload)).pipe(
				switchMap((response: { data: [], pagination: { total: number } }) => of(
					getCommoditySearchDone({
						list: response.data,
						categoryId: payload.category_short_link || payload.category_id || 'all',
						pagination: response.pagination,
					}),
				)),
				catchError(error => of(getCommoditySearchFail({ error }))),
			)
		)),
	)
);
