// TODO: Use translation to prevent hard code
export const STRENGTHS = [{
  message: '✓ 密碼強度：強',
  color: '#4fbf40'
}, {
  message: '✓ 密碼強度：中',
  color: '#ffa600'
}, {
  message: '密碼強度：弱',
  color: '#d0021b'
}];
