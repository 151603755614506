/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '@src/api';

export const getCreditCards = payload => api.get('payment/credit-cards', payload);

export const addCreditCard = payload => api.post('payment/credit-card/bind', payload);

export const editCreditCardMeta = ({ card6_no, card4_no, invoiceParam }) => api.post(`payment/credit-card/${card6_no}/${card4_no}/meta`, invoiceParam);

export const payViaCreditCard = payload => api.post('payment/credit-card', payload, { timeout: 120 * 1000 });

export const payViaEcpay = payload => api.post('payment/ecpay', payload);

export const payViaVacc = payload => api.post('payment/vacc', payload, { timeout: 120 * 1000 });
