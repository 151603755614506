import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_LEARNING_AREAS_CALL,
	GET_LEARNING_AREAS_BY_SHORT_LINK_CALL,
	GET_LEARNING_AREAS_PATHS_CALL,
} from '@src/store/types/learningArea';

import {
	getLearningAreasDone,
	getLearningAreasFail,
	getLearningAreasByShortLinkDone,
	getLearningAreasByShortLinkFail,
	getLearningAreasPathsDone,
	getLearningAreasPathsFail,
} from '@src/store/actions/learningArea';
import {
	getLearningAreas,
	getLearningAreasByShortLink,
	getLearningAreasPaths,
} from '@src/services/learningArea';

export const getLearningAreasEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_AREAS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getLearningAreas(payload)).pipe(
			mergeMap(res => of(getLearningAreasDone({ res, key: payload.key }))),
			catchError(error => of(getLearningAreasFail({ error }))),
		),
	),
);

export const getLearningAreasByShortLinkEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_AREAS_BY_SHORT_LINK_CALL),
	switchMap(
		({ payload }) => fromPromise(getLearningAreasByShortLink(payload)).pipe(
			switchMap(res => of(getLearningAreasByShortLinkDone({ res }))),
			catchError(error => of(getLearningAreasByShortLinkFail({ error }))),
		),
	),
);

export const getLearningAreasPathsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_AREAS_PATHS_CALL),
	switchMap(
		({ payload }) => fromPromise(getLearningAreasPaths(payload)).pipe(
			switchMap(res => of(getLearningAreasPathsDone({ res }))),
			catchError(error => of(getLearningAreasPathsFail({ error }))),
		),
	),
);
