import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL,
} from '@src/store/types/makeUpPunchApplication';

import {
	getMakeUpPunchApplicationsDone,
	getMakeUpPunchApplicationsFail,
	postMakeUpPunchApplicationsDone,
	postMakeUpPunchApplicationsFail,
	getMakeUpPunchApplicationsApproverViewDone,
	getMakeUpPunchApplicationsApproverViewFail,
	getMakeUpPunchApplicationsPunchOptionsDone,
	getMakeUpPunchApplicationsPunchOptionsFail,
	getMakeUpPunchApplicationsDetailCall,
	getMakeUpPunchApplicationsDetailDone,
	getMakeUpPunchApplicationsDetailFail,
	postMakeUpPunchApplicationsApproveDone,
	postMakeUpPunchApplicationsApproveFail,
	postMakeUpPunchApplicationsWithdrawDone,
	postMakeUpPunchApplicationsWithdrawFail,
	postMakeUpPunchApplicationsWithdrawApprovalDone,
	postMakeUpPunchApplicationsWithdrawApprovalFail,
} from '@src/store/actions/makeUpPunchApplication';
import {
	getMakeUpPunchApplications,
	postMakeUpPunchApplication,
	getMakeUpPunchApplicationsApproverView,
	getMakeUpPunchApplicationsPunchOptions,
	getMakeUpPunchApplicationDetail,
	postMakeUpPunchApplicationApprove,
	postMakeUpPunchApplicationWithdraw,
	postMakeUpPunchApplicationWithdrawApproval,
} from '@src/services/makeUpPunchApplication';

export const getMakeUpPunchApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MAKE_UP_PUNCH_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(getMakeUpPunchApplications(payload)).pipe(
			switchMap(res => of(getMakeUpPunchApplicationsDone({ res }))),
			catchError(error => of(getMakeUpPunchApplicationsFail({ error }))),
		),
	),
);

export const postMakeUpPunchApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_MAKE_UP_PUNCH_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(postMakeUpPunchApplication(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postMakeUpPunchApplicationsDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postMakeUpPunchApplicationsFail({ error }));
			}),
		),
	),
);

export const getMakeUpPunchApplicationsApproverViewEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL),
	switchMap(
		({ payload }) => fromPromise(getMakeUpPunchApplicationsApproverView(payload)).pipe(
			switchMap(res => of(getMakeUpPunchApplicationsApproverViewDone({ res }))),
			catchError(error => of(getMakeUpPunchApplicationsApproverViewFail({ error }))),
		),
	),
);

export const getMakeUpPunchApplicationsPunchOptionsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL),
	switchMap(
		() => fromPromise(getMakeUpPunchApplicationsPunchOptions()).pipe(
			switchMap(res => of(getMakeUpPunchApplicationsPunchOptionsDone({ res }))),
			catchError(error => of(getMakeUpPunchApplicationsPunchOptionsFail({ error }))),
		),
	),
);

export const getMakeUpPunchApplicationsDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getMakeUpPunchApplicationDetail(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getMakeUpPunchApplicationsDetailDone({ res }));
			}),
			catchError(error => of(getMakeUpPunchApplicationsDetailFail({ error }))),
		),
	),
);

export const postMakeUpPunchApplicationsApproveEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL),
	switchMap(
		({ payload }) => fromPromise(postMakeUpPunchApplicationApprove(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postMakeUpPunchApplicationsApproveDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postMakeUpPunchApplicationsApproveFail({ error }));
			}),
		),
	),
);

export const postMakeUpPunchApplicationsWithdrawEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL),
	switchMap(
		({ payload }) => fromPromise(postMakeUpPunchApplicationWithdraw(payload)).pipe(
			switchMap(res => [
				getMakeUpPunchApplicationsDetailCall({ id: payload.id }),
				postMakeUpPunchApplicationsWithdrawDone({ res }),
			]),
			catchError(error => of(postMakeUpPunchApplicationsWithdrawFail({ error }))),
		),
	),
);

export const postMakeUpPunchApplicationsWithdrawApprovalEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL),
	switchMap(
		({ payload }) => fromPromise(postMakeUpPunchApplicationWithdrawApproval(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postMakeUpPunchApplicationsWithdrawApprovalDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postMakeUpPunchApplicationsWithdrawApprovalFail({ error }));
			}),
		),
	),
);
