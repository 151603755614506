import session from './session';
import errorMessage from './errorMessage';
import user from './user';
import school from './school';
import payment from './payment';
import ui from './ui';
import course from './course';
import upload from './upload';
import theme from './theme';
import purchase from './purchase';
import comment from './comment';
import announcement from './announcement';
import homework from './homework';
import otherUser from './otherUser';
import ssr from './ssr';
import event from './event';
import webinar from './webinar';
import cart from './cart';
import checkout from './checkout';
import checkoutSuccess from './checkoutSuccess';
import quiz from './quiz';
import category from './category';
import survey from './survey';
import learningArea from './learningArea';
import learningPath from './learningPath';
import schoolAnnouncement from './schoolAnnouncement';
import knowledgeBasePage from './knowledgeBasePage';
import knowledgeBaseSpace from './knowledgeBaseSpace';
import job from './job';
import departments from './departments';
import punchIn from './punchIn';
import overtimeApplication from './overtimeApplication';
import hrCenter from './hrCenter';
import leaveApplication from './leaveApplication';
import makeUpPunchApplication from './makeUpPunchApplication';
import attendance from './attendance';

export default {
	session,
	errorMessage,
	user,
	school,
	payment,
	ui,
	course,
	upload,
	theme,
	purchase,
	comment,
	announcement,
	homework,
	otherUser,
	ssr,
	event,
	webinar,
	cart,
	checkout,
	checkoutSuccess,
	quiz,
	category,
	survey,
	learningArea,
	learningPath,
	schoolAnnouncement,
	knowledgeBasePage,
	knowledgeBaseSpace,
	job,
	departments,
	punchIn,
	overtimeApplication,
	hrCenter,
	leaveApplication,
	makeUpPunchApplication,
	attendance,
};
