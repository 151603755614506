import api from '@src/api';

export const getWebinar = ({ webinarShortLink, ssrHeaders }: {
	webinarShortLink: string;
	ssrHeaders: {
		[x:string]: string;
	};
}) => api.get(`webinars/${webinarShortLink}`, {}, { ssrHeaders });

export const getWebinars = ({ ssrHeaders }: {
	ssrHeaders: {
		[x:string]: string;
	};
}) => api.get('webinars/', {}, { ssrHeaders });
