// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { reactLocalStorage } from '@src/utils/localStorage';

// 所有課程
export const getCourseView = payload => api.get('courses/view', payload, {
	hasPagination: true,
	ssrHeaders: payload.ssrHeaders,
});

export const getCurrentCourse = ({ courseId, ssrHeaders, created_at, preview, previewToken }) => {
	if (!previewToken) {
		previewToken = reactLocalStorage.get('previewToken');
		preview = !!previewToken;
	}
	return api.get(`course/${courseId}/view`, { created_at, preview, 'preview-token': previewToken }, { ssrHeaders });
};

export const getCurrentCourseGeneral = ({ courseId, ssrHeaders }) => (
	api.get(`course/${courseId}/view/general`, {}, { ssrHeaders })
);

export const getCurrentCourseProgress = ({ courseId }) => api.get(`course/${courseId}/progress`);
export const updateCourseUnitProgress = ({
	unitId,
	duration,
	progress,
}) => api.post(`unit/${unitId}/progress`, { duration, progress });

export const getCourseRating = payload => api.get(`course/${payload.course_id}/ratings`, payload.data);
export const addCourseRating = payload => api.post(`course/${payload.course_id}/rating`, payload.data);
export const editCourseRating = payload => api.patch(`course/${payload.course_id}/rating`, payload.data);

export const getPropagandaReview = payload => api.get(`propaganda-ratings?course_id=${payload.courseId}`);

export const getCourseCertificate = payload => api.get(`course/${payload.courseId}/certificate`);
export const createCourseCertificate = payload => api.post(`course/${payload.courseId}/certificate`);

export const unitViewed = payload => api.post(`unit/${payload.unitId}/viewed`);

export const unitViewedRecord = payload => api.post(`unit/${payload.unitId}/view-record`, payload);

export const courseViewedRecord = payload => api.post(`course/${payload.courseId}/viewed`);
