import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_LEARNING_PATHS_CALL,
	GET_LEARNING_PATHS_DONE,
	GET_LEARNING_PATHS_FAIL,
	GET_MINE_LEARNING_PATHS_CALL,
	GET_MINE_LEARNING_PATHS_DONE,
	GET_MINE_LEARNING_PATHS_FAIL,
	GET_LEARNING_PATHS_BY_SHORT_LINK_CALL,
	GET_LEARNING_PATHS_BY_SHORT_LINK_DONE,
	GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL,
	GET_LEARNING_PATHS_COMMODITIES_CALL,
	GET_LEARNING_PATHS_COMMODITIES_DONE,
	GET_LEARNING_PATHS_COMMODITIES_FAIL,
	JOIN_LEARNING_PATHS_CALL,
	JOIN_LEARNING_PATHS_DONE,
	JOIN_LEARNING_PATHS_FAIL,
	LEAVE_LEARNING_PATHS_CALL,
	LEAVE_LEARNING_PATHS_DONE,
	LEAVE_LEARNING_PATHS_FAIL,
	LEARNING_PATHS_PROGRESS_CALL,
	LEARNING_PATHS_PROGRESS_DONE,
	LEARNING_PATHS_PROGRESS_FAIL,
	POST_LEARNING_PATHS_CERTIFICATE_CALL,
	POST_LEARNING_PATHS_CERTIFICATE_DONE,
	POST_LEARNING_PATHS_CERTIFICATE_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	learningPaths: [],
	learningPathsByKey: {},
	getLearningPathsSideEffect: defaultSideEffectState,
	learningPathDetail: {},
	getLearningPathsDetailSideEffect: defaultSideEffectState,
	mineLearningPaths: [],
	getMineLearningPathsSideEffect: defaultSideEffectState,
	learningPathsCommodities: [],
	getLearningPathsCommoditiesSideEffect: defaultSideEffectState,
	learningPathsProgress: {
		joined: false,
		progress: [],
	},
	getLearningPathsProgressSideEffect: defaultSideEffectState,
	joinSideEffect: defaultSideEffectState,
	leaveLearningSideEffect: defaultSideEffectState,
	postLearningPathCertificate: {},
	postLearningPathCertificateSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_LEARNING_PATHS_CALL](state) {
		return update(state, {
			getLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_DONE](state, { payload: { res, key } }) {
		return update(state, {
			...key ? {
				learningPathsByKey: {
					$set: {
						...state.learningPathsByKey,
						[key]: res,
					},
				},
			} : {
				learningPaths: { $set: res },
			},
			getLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_FAIL](state, { payload }) {
		return update(state, {
			getLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_MINE_LEARNING_PATHS_CALL](state) {
		return update(state, {
			getMineLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_MINE_LEARNING_PATHS_DONE](state, { payload: { res } }) {
		return update(state, {
			mineLearningPaths: { $set: res },
			getMineLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_MINE_LEARNING_PATHS_FAIL](state, { payload }) {
		return update(state, {
			getMineLearningPathsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEARNING_PATHS_BY_SHORT_LINK_CALL](state) {
		return update(state, {
			getLearningPathsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_BY_SHORT_LINK_DONE](state, { payload: { res } }) {
		return update(state, {
			learningPathDetail: { $set: res },
			getLearningPathsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL](state, { payload }) {
		return update(state, {
			getLearningPathsDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[JOIN_LEARNING_PATHS_CALL](state) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[JOIN_LEARNING_PATHS_DONE](state) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[JOIN_LEARNING_PATHS_FAIL](state, { payload }) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[LEAVE_LEARNING_PATHS_CALL](state) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[LEAVE_LEARNING_PATHS_DONE](state) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[LEAVE_LEARNING_PATHS_FAIL](state, { payload }) {
		return update(state, {
			joinSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEARNING_PATHS_COMMODITIES_CALL](state) {
		return update(state, {
			getLearningPathsCommoditiesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_COMMODITIES_DONE](state, { payload: { res } }) {
		return update(state, {
			learningPathsCommodities: { $set: res },
			getLearningPathsCommoditiesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_PATHS_COMMODITIES_FAIL](state, { payload }) {
		return update(state, {
			getLearningPathsCommoditiesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[LEARNING_PATHS_PROGRESS_CALL](state) {
		return update(state, {
			getLearningPathsProgressSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[LEARNING_PATHS_PROGRESS_DONE](state, { payload: { res } }) {
		return update(state, {
			learningPathsProgress: { $set: res },
			getLearningPathsProgressSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[LEARNING_PATHS_PROGRESS_FAIL](state, { payload }) {
		return update(state, {
			getLearningPathsProgressSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEARNING_PATHS_CERTIFICATE_CALL](state) {
		return update(state, {
			postLearningPathCertificateSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEARNING_PATHS_CERTIFICATE_DONE](state, { payload: { res } }) {
		return update(state, {
			postLearningPathCertificate: { $set: res },
			postLearningPathCertificateSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEARNING_PATHS_CERTIFICATE_FAIL](state, { payload }) {
		return update(state, {
			postLearningPathCertificateSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
