// eslint-disable-next-line import/no-cycle
import api from '@src/api';

import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getSchoolAnnouncements = (payload: {
	unread_only?: boolean;
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('school-announcements/', payload, { hasPagination: true })
);

export const getSchoolAnnouncementDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`school-announcements/${payload.id}`)
);

export const getSchoolAnnouncementByIndex = (payload: { index: number }): Observable<Action> => (
	api.get(`school-announcements/index/${payload.index}`)
);

export const postSchoolAnnouncementRead = (payload: { id: string }): Observable<Action> => (
	api.post(`school-announcements/${payload.id}/read`)
);

export const postSchoolAnnouncementReadAll = (): Observable<Action> => (
	api.post('school-announcements/all-read')
);
