import React from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

import * as selectors from '@src/store/selectors';
import i18n from '@src/i18n';
import MyToastContainer from '@src/components/MyToastContainer';
import AppBackground from '@src/components/AppBackground';
import { I18nextProvider } from 'react-i18next';
import ScrollToTop from '@src/components/scrollToTop';
import ModalContainer from '@src/components/modalContainer';
import { colorThemes } from '@src/const/theme';

const App = ({ children }) => {
  const theme = useSelector(selectors.getThemeSelector);
  const selectedColorTheme = theme?.themes?.color_theme;
  const colorTheme =
    find(colorThemes, {
      id: selectedColorTheme,
    }) || colorThemes[0];
  const { colors } = colorTheme;
  const cssVariablesStyle = Object.keys(colors)
    .map((colorKey) => `--${colorKey}: ${colors[colorKey]};`)
    .join('');

  return (
    <I18nextProvider i18n={i18n}>
      <style global jsx>
        {`
          :root {
            ${cssVariablesStyle}
          }
        `}
      </style>
      <ScrollToTop>
        <AppBackground>
          {children}
          <MyToastContainer />
          <ModalContainer />
        </AppBackground>
      </ScrollToTop>
    </I18nextProvider>
  );
};

export default App;
