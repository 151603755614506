/* eslint-disable max-len */
import TagManager from 'react-gtm-module';

export function gtag(...args: any[]) {
	TagManager.dataLayer({ dataLayer: args });
}

export const sentGTMViewItem = (data) => {
	gtag('event', 'view_item', {
		raw: data,
		items: [{
			item_id: data.short_link,
			item_name: data.title || data.name || '',
		}],
	});
};

export const sentGTMAddCartItem = ({ id, name, raw }) => {
	gtag('event', 'add_to_cart', {
		raw,
		items: [{
			item_id: id,
			item_name: name,
		}],
	});
};

function cartToGTMValue(products) {
	const price =
		products?.product_subtotal_price || 0 +
		products?.event_subtotal_price || 0 +
		products?.webinar_subtotal_price || 0;
	return {
		price,
		items: [
			...(products?.products || []).map(product => ({ item_id: product.short_link, item_name: product.description })),
			...(products?.event_slots || []).map(product => ({ item_id: product.short_link, item_name: product.event_name })),
			...(products?.webinar_slots || []).map(product => ({ item_id: product.short_link, item_name: product.webinar_name })),
		],
	};
}

export const sentGTMBeginCheckout = ({ products }) => {
	const { price, items } = cartToGTMValue(products);

	gtag('event', 'begin_checkout', {
		currency: 'TWD',
		value: price,
		raw: products,
		coupon: (products?.coupons || []).map(coupon => coupon.code).join(','),
		items,
	});
};

export const sentGTMAddPaymentInfo = ({ products }) => {
	const { price, items } = cartToGTMValue(products);

	gtag('event', 'add_payment_info', {
		currency: 'TWD',
		value: price,
		raw: products,
		coupon: (products?.coupons || []).map(coupon => coupon.code).join(','),
		items,
	});
};

export const sentGTMPurchase = ({ purchases }) => {
	const items = [
		...(purchases?.products || []).map(product => ({ item_id: product.short_link, item_name: product.description })),
		...(purchases?.event_products || []).map(product => ({ item_id: product.event_short_link, item_name: product.event_name })),
		...(purchases?.webinar_products || []).map(product => ({ item_id: product.webinar_short_link, item_name: product.webinar_name })),
	];

	gtag('event', 'purchase', {
		currency: 'TWD',
		value: purchases?.total_price || 0,
		transaction_id: purchases?.id,
		raw: purchases,
		coupon: (purchases?.coupons || []).map(coupon => coupon.code).join(','),
		items,
	});
};
