import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';
import Cookies from 'universal-cookie';

import * as types from '@src/store/types';
import { reactLocalStorage } from '@src/utils/localStorage';
import * as Sentry from '@sentry/node';
import config from '@src/config';

const { useSentry } = config;
const initialState = {
	isSignedIn: false,
	token: null,
	isPreview: false,
	previewToken: null,
	buttonLoading: false,
	successMessage: '',
	updateMobileState: 'IDLE',
	confirmMobileState: 'IDLE',
	emailResendState: 'IDLE',
};
const cookies = new Cookies();

export default handleActions(
	{
		[types.CHECK_IS_SIGNED_IN](state) {
			return produce(state, draft => {
				draft.isSignedIn = reactLocalStorage.get('isSignedIn');
				draft.token = reactLocalStorage.get('token');
			});
		},
		[types.SET_TOKEN](state, { payload: { token } }) {
			reactLocalStorage.set('isSignedIn', true);
			reactLocalStorage.set('token', token);
			reactLocalStorage.remove('previewToken');
			cookies.set('token', token, { path: '/' });

			if (useSentry) {
				Sentry.setContext('header', {
					token: `${token}`,
				});
			}

			return produce(state, draft => {
				draft.isSignedIn = true;
				draft.token = token;
			});
		},
		[types.SET_PREVIEW_TOKEN](state, { payload: { token } }) {
			reactLocalStorage.set('previewToken', token);
			reactLocalStorage.set('isSignedIn', false);
			reactLocalStorage.remove('token');
			cookies.remove('token', { path: '/' });

			return produce(state, draft => {
				draft.isPreview = true;
				draft.previewToken = token;
				draft.isSignedIn = false;
				draft.token = '';
			});
		},
		[combineActions(
			types.EMAIL_LOGIN_CALL,
			types.EMAIL_REGISTER_CALL,
			types.EMAIL_RE_SEND_CALL,
			types.CONFIRM_MOBILE_CALL,
			types.UPDATE_MOBILE_CALL,
		)](state, { payload }) {
			return produce(state, draft => {
				draft.buttonLoading = true;
				draft.successMessage = '';
			});
		},
		[combineActions(
			types.EMAIL_LOGIN_DONE,
			types.EMAIL_LOGIN_FAIL,
			types.EMAIL_REGISTER_DONE,
			types.EMAIL_REGISTER_FAIL,
			types.EMAIL_RE_SEND_DONE,
			types.EMAIL_RE_SEND_FAIL,
			types.CONFIRM_MOBILE_DONE,
			types.CONFIRM_MOBILE_FAIL,
			types.UPDATE_MOBILE_DONE,
		)](state, { payload }) {
			return produce(state, draft => {
				draft.buttonLoading = false;
			});
		},
		[types.EMAIL_RE_SEND_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.successMessage = 'checkEmail:success';
			});
		},
		[types.UPDATE_MOBILE_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.updateMobileState = 'LOADING';
			});
		},
		[types.UPDATE_MOBILE_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.updateMobileState = 'SUCCESS';
			});
		},
		[types.UPDATE_MOBILE_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.updateMobileState = 'FAIL';
			});
		},
		[types.CONFIRM_MOBILE_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.confirmMobileState = 'LOADING';
			});
		},
		[types.CONFIRM_MOBILE_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.confirmMobileState = 'SUCCESS';
			});
		},
		[types.CONFIRM_MOBILE_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.confirmMobileState = 'FAIL';
			});
		},
		[types.RESET_UPDATE_MOBILE](state) {
			return produce(state, draft => {
				draft.updateMobileState = 'IDLE';
				draft.confirmMobileState = 'IDLE';
			});
		},
		[types.LOGOUT_CALL](state, { payload }) {
			reactLocalStorage.remove('token');
			reactLocalStorage.remove('isSignedIn');
			reactLocalStorage.remove('schoolId');
			cookies.remove('token', { path: '/' });

			return {
				isSignedIn: false,
				token: null,
				buttonLoading: false,
				successMessage: '',
			};
		},
		[types.EMAIL_RE_SEND_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.emailResendState = 'LOADING';
			});
		},
		[types.EMAIL_RE_SEND_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.emailResendState = 'SUCCESS';
			});
		},
		[types.EMAIL_RE_SEND_FAIL](state, { payload }) {
			return produce(state, draft => {
				const { message } = payload.response.data;

				if (message === 'Conflict') {
					draft.emailResendState = 'FAIL_EMAIL_EXISTED';
				} else {
					draft.emailResendState = 'FAIL';
				}
			});
		},
		[types.EMAIL_RESEND_STATE_RESET](state) {
			return produce(state, draft => {
				draft.emailResendState = 'IDLE';
			});
		},
		[combineActions(
			types.CHECK_LOGIN_FAIL,
			types.CHECK_LOGIN_SESSION_FAIL,
		)](state) {
			reactLocalStorage.set('isSignedIn', false);
			return update(state, {
				isSignedIn: { $set: false },
			});
		},
	},
	initialState,
);
