import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';

import * as types from '@src/store/types';
import { ui } from '@src/services';
import { TOAST_TYPE_ERROR } from '@src/const/common';

const initialState = {
  creditCards: [],
  showCardSelect: false,
  showCardInput: true,
  buttonLoading: false,
  showLoading: true,
  paymentState: 'IDLE',
  paymentErrorFields: [],
};

export default handleActions(
  {
    [types.GET_CREDIT_CARDS_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.showLoading = true;
      });
    },
    [types.GET_CREDIT_CARDS_DONE](state, { payload = [] }) {
      return produce(state, (draft) => {
        const creditCards = payload;

        draft.creditCards = creditCards;
        draft.showLoading = false;

        if (creditCards.length !== 0) {
          draft.showCardSelect = true;
          draft.showCardInput = false;
        }
      });
    },
    [types.GET_CREDIT_CARDS_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.showLoading = false;
      });
    },
    [types.ADD_CREDIT_CARD_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.buttonLoading = true;
      });
    },
    [combineActions(
      types.EDIT_CREDIT_CARD_META_DONE,
      types.EDIT_CREDIT_CARD_META_FAIL,
      types.ADD_CREDIT_CARD_FAIL
    )](state, { payload }) {
      return produce(state, (draft) => {
        draft.buttonLoading = false;
      });
    },
    [types.SET_SHOW_CARD](state, { payload: { showCardInput } }) {
      return produce(state, (draft) => {
        draft.showCardInput = showCardInput;
      });
    },
    [combineActions(types.PAY_VIA_CREDIT_CARD_CALL, types.PAY_VIA_VACC_CALL)](
      state,
      { payload }
    ) {
      return produce(state, (draft) => {
        draft.paymentState = 'LOADING';
        draft.paymentErrorFields = [];
      });
    },
    [combineActions(types.PAY_VIA_CREDIT_CARD_DONE, types.PAY_VIA_VACC_DONE)](
      state,
      { payload }
    ) {
      return produce(state, (draft) => {
        draft.paymentState = 'SUCCESS';
        draft.paymentErrorFields = [];
      });
    },
    [combineActions(types.PAY_VIA_CREDIT_CARD_FAIL, types.PAY_VIA_VACC_FAIL)](
      state,
      { payload }
    ) {
      const status = payload.response?.status;

      if (status === 403) {
        return produce(state, (draft) => {
          draft.paymentState = 'COURSE_UNPUBLISHED';
          draft.paymentErrorFields = [];
        });
      }

      const errorData = payload.response?.data;

      if (errorData?.i18n_msg) {
        ui.toastShow({ message: errorData?.i18n_msg, type: TOAST_TYPE_ERROR });
      }

      return produce(state, (draft) => {
        draft.paymentState = errorData?.errno ? 'FAIL_CREDIT_CARD' : 'FAIL';
        draft.paymentErrorFields = errorData?.fields || [];
      });
    },
  },
  initialState
);
