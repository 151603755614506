import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';

import { ArrowDownIcon } from '@src/components/common/icon';

export const Input = styled.input`
  border: none;
  width: 100%;
  color: #676767;
  cursor: pointer;
`;

const SelectedBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DBDBDB;
  height: 44px;
  padding: 0px 12px;
  color: #676767;
	font-weight: ${({ hasSelectedOption }) => (hasSelectedOption ? 400 : 300)};
  cursor: pointer;
`;

const MenuItem = styled(Menu.Item)`
  font-size: 16px;
  color: ${(props: { $isActive: boolean }) => (props.$isActive ? '#3091fd' : '#707070')};
  height: 44px;
  display: flex;
  align-items: center;
`;

interface IProps {
	selectedOption: string;
	options: string[];
	hint: string;
	onSelect: (value: string) => void;
}

const Selector = ({
	selectedOption, options, hint, onSelect,
}: IProps): JSX.Element => (
	<Dropdown
		trigger={['click']}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		getPopupContainer={trigger => trigger.parentNode}
		overlay={(
			<Menu onClick={({ key }) => onSelect(key.toString())}>
				{
					options.map(option => (
						<MenuItem key={option} $isActive={option === selectedOption}>
							{option}
						</MenuItem>
					))
				}
			</Menu>
		)}
	>
		<SelectedBox hasSelectedOption={!!selectedOption}>
			<span>{selectedOption || hint}</span>
			<ArrowDownIcon />
		</SelectedBox>
	</Dropdown>
);

export default Selector;
