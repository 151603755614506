import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation as i18nUseTranslation } from 'react-i18next';

import useTranslation from '@src/hooks/useTranslation';
import { getThemeSelector } from '@src/store/selectors';
import { IconComponent } from '@src/icons';

const FlexRow = styled.div`
	display: flex;
	flex-direction: column;
`;

const ClickableText = styled.div`
	cursor: pointer;
	text-decoration: underline;
`;

const ModalFooterInfo = (): JSX.Element => {
	const { t } = useTranslation();
	const { i18n } = i18nUseTranslation();
	const {
		themes: {
			multi_language_enabled,
			supported_languages,
		},
	} = useSelector(getThemeSelector) as {
		themes: {
			multi_language_enabled: boolean,
			supported_languages: string[],
		}
	};
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	return (
		<>
			<p>
				{t('auth_modal:agreement')}
				<br />
				<FlexRow>
					<ClickableText onClick={() => window.open('/p/terms')}>
						{t('auth_modal:service')}
					</ClickableText>
					<ClickableText onClick={() => window.open('/p/policy')}>
						{t('auth_modal:policy')}
					</ClickableText>
				</FlexRow>
			</p>
			<div className="lang-box">
				<IconComponent size={17} color="#222" icon="login_form_Language" />
				<select
					value={i18n.language.toLocaleLowerCase()}
					onChange={event => changeLanguage(event.target.value)}
				>
					<option value="zh-tw">{t('language:zh-CHT')}</option>
					{
						multi_language_enabled && supported_languages.indexOf('zh-CHS') > -1 && (
							<option value="zh-CHS">{t('language:zh-CHS')}</option>
						)
					}
					{
						multi_language_enabled && supported_languages.indexOf('en') > -1 && (
							<option value="en">{t('language:en')}</option>
						)
					}
				</select>
			</div>
		</>
	);
};

export default ModalFooterInfo;
