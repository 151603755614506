/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';

import * as actionCreators from '@src/store/actions';
import { MODAL_TYPE_LOGIN, I18N_TO_LANG } from '@src/const/common';
import TextField from '@src/components/textField';
import { InfoOutlinedIcon } from '@src/components/common/icon';

import { IconComponent } from '@src/icons';
import ModalHeader from '../modalHeader';
import { BlueBtn, Wrapper, Content, LinkButton } from './Styled';

const COUNT_DOWN_TIME = 60;

const Notification = styled.div`
	font-size: 14px;
	color: #222222;
	border-radius: 4px;
	padding: 10px 24px;
	margin-bottom: 20px;
`;

const InfoNotification = styled(Notification)`
	background: #E7F4F9;
`;

const ErrorNotification = styled(Notification)`
	background: #FFE9EC;
	color: #D0021B;
	display: flex;
	align-items: center;
`;

const ErrorIcon = styled(InfoOutlinedIcon)`
	fill: #D0021B;
	margin-right: 10px;
	width: 16px;
	height: 16px;
`;

const makeErrorMessage = ({ t, message, provider }) => {
	if (message === 'Conflict Email') {
		return t('forgetPassword:oauth_email_conflict', {
			provider: provider.slice(0, 1).toUpperCase() + provider.slice(1).toLowerCase(),
		});
	}
	return t('forgetPassword:email_not_found');
};

export function ForgetPasswordModal({
	t,
	actions,
	modalData,
	onClose,
}) {
	const [currentEmail, setCurrentEmail] = useState('');
	const [targetClass, setTargetClass] = useState();
	const [isResendSuccess, setIsResendSuccess] = useState(false);
	const [errorData, setErrorData] = useState(null);
	const [countDown, setCountDown] = useState(-1);
	const [isDisabled, setIsDisabled] = useState(false);
	const { i18n } = useTranslation();
	const errorMessage = makeErrorMessage({
		t,
		message: errorData?.message,
		provider: (errorData?.accounts || [])[0]?.provider,
	});

	function handleChangeModal() {
		actions.setModalType({ type: MODAL_TYPE_LOGIN });
	}

	function handleSubmit(e) {
		e.preventDefault();

		if (!isDisabled) {
			actions.forgetPasswordCall({
				email: currentEmail,
				language: I18N_TO_LANG[i18n.language.toLocaleLowerCase()],
				onSuccess: () => {
					setIsResendSuccess(true);
					setIsDisabled(true);
				},
				onError: data => {
					setErrorData(data);
					if (data?.message === 'Conflict Email') {
						setIsDisabled(true);
					}
				},
			});
			setIsResendSuccess(false);
			setErrorData(null);
		}
	}

	function handleClickStart(e) {
		setTargetClass(e.target.className);
	}

	function handleClickEnd(e) {
		if (
			e.target.id === 'modal-wrap' &&
      window.innerWidth > 768 &&
      targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass();
	}

	useEffect(() => {
		setIsResendSuccess(modalData?.message?.type === 'success');
	}, [modalData?.message?.type]);

	useEffect(() => {
		if (isResendSuccess) {
			setCountDown(COUNT_DOWN_TIME);
			const countDownId = setInterval(() => {
				setCountDown(prev => {
					if (prev > 0) {
						return prev - 1;
					}
					clearInterval(countDownId);
					setIsDisabled(false);
				});
			}, 1000);
		}
	}, [isResendSuccess]);

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<div role="presentation" className="login icon-icon_close_31" onClick={onClose} />
				<ModalHeader title={t('forgetPassword:title')} onClose={onClose} />
				{isResendSuccess && (
					<InfoNotification>
						<span>{t('forgetPassword:success', { email: currentEmail })}</span>
					</InfoNotification>
				)}
				{errorData?.message && (
					<ErrorNotification>
						<ErrorIcon />
						<span>{errorMessage}</span>
					</ErrorNotification>
				)}
				<p className="des">{t('forgetPassword:des:send_email_info')}</p>
				<p className="des">{t('forgetPassword:des:spam_email_info')}</p>

				<form onSubmit={e => handleSubmit(e)}>
					<TextField
						required
						inputType="email"
						placeholder={t('forgetPassword:placeholder')}
						InputValue={currentEmail}
						onInputValueChange={value => {
							setErrorData(null);
							setCurrentEmail(value);
						}}
						icon={(
							<IconComponent
								size={22}
								color={isDisabled ? '#676767' : '#DCDCDC'}
								icon="login_form_email"
							/>
						)}
						autoFocus
						isDisabled={isDisabled}
						withError={!!errorData}
					/>
					<BlueBtn $isDisabled={isDisabled}>
						{
							countDown >= 0 ? (
								`${t('forgetPassword:submit')} (${countDown})`
							) : (
								t('forgetPassword:submit')
							)
						}
					</BlueBtn>
				</form>
				<LinkButton onClick={handleChangeModal}>{t('forgetPassword:back')}</LinkButton>
			</Content>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	errorMessage: state.errorMessage.inputErrorMessages,
	modalData: state.ui.modalData,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordModal),
);
