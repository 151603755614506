import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_APPROVAL_CENTER_CALL,
	GET_APPROVAL_CENTER_DONE,
	GET_APPROVAL_CENTER_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	approvalCenter: {},
	getApprovalCenterSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_APPROVAL_CENTER_CALL](state) {
		return update(state, {
			getApprovalCenterSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_APPROVAL_CENTER_DONE](state, { payload: { res } }) {
		return update(state, {
			approvalCenter: { $set: res },
			getApprovalCenterSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_APPROVAL_CENTER_FAIL](state, { payload }) {
		return update(state, {
			getApprovalCenterSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
