import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_ATTENDANCE_HISTORY_CALL,
	GET_ATTENDANCE_HISTORY_DONE,
	GET_ATTENDANCE_HISTORY_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	attendanceHistory: [],
	getAttendanceHistorySideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_ATTENDANCE_HISTORY_CALL](state) {
		return update(state, {
			getAttendanceHistorySideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_ATTENDANCE_HISTORY_DONE](state, { payload: { res } }) {
		return update(state, {
			attendanceHistory: { $set: res },
			getAttendanceHistorySideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_ATTENDANCE_HISTORY_FAIL](state, { payload }) {
		return update(state, {
			getAttendanceHistorySideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
