import { combineActions, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { ui } from '@src/services';
import { ICheckout } from '@src/types/checkout';
import {
	UPDATE_PAYMENT_METHOD,
	UPDATE_CREDIT_CARD_NO,
	UPDATE_EXPIRY_DATE,
	UPDATE_CVC,
	UPDATE_INVOICE_METHOD,
	UPDATE_CARRIER_TYPE,
	UPDATE_DONATE,
	UPDATE_UNIFORM_NUMBER,
	UPDATE_COMPANY_NAME,
	UPDATE_MOBILE_BARCODE_CARRIER,
	UPDATE_CITIZEN_DIGITAL_CERTIFICATE,
	UPDATE_IS_CREDIT_CARD_ERROR,
	UPDATE_IS_EXP_ERROR,
	UPDATE_IS_CVC_ERROR,
	UPDATE_IS_INVOICE_METHOD_ERROR,
	UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR,
	UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR,
	UPDATE_REGISTER_FORM_BY_SLOT_ID,
	UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL,
	UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP,
	UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL,
	PAY_VIA_VACC_WITH_REGISTER_FORM_CALL,
	PAY_VIA_VACC_WITH_REGISTER_FORM_DONE,
	PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL,
	SET_RESERVED_UNTIL_COUNT_DOWN,
	INIT_CHECKOUT_STATE,
	SET_PAYMENT_STATE,
	GET_AREA_INFO_CALL,
	GET_AREA_INFO_DONE,
	GET_AREA_INFO_FAIL,
	// GET_PURCHASE_INFO_FIELDS_CALL,
	// GET_PURCHASE_INFO_FIELDS_DONE,
	// GET_PURCHASE_INFO_FIELDS_FAIL,
	GET_RECIPIENT_INFO_CALL,
	GET_RECIPIENT_INFO_DONE,
	GET_RECIPIENT_INFO_FAIL,
	UPDATE_PURCHASE_INFO,
	UPDATE_DEFAULT_SETTING,
	UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS,
	GET_CHECKOUT_FORM_CALL,
	GET_CHECKOUT_FORM_DONE,
	GET_CHECKOUT_FORM_FAIL,
	SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO,
	SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS,
} from '@src/store/types/checkout';
import {
	METHOD_CREDIT_CARD,
	B2C_INVOICE_MEMBER,
	DONATE_OPTIONS,
} from '@src/const/checkout';
import {
	TOAST_TYPE_ERROR,
	STATE_SUCCESS,
	STATE_LOADING,
	STATE_FAIL, STATE_IDLE,
} from '@src/const/common';

const initialState = {
	paymentMethod: METHOD_CREDIT_CARD,
	creditCardNo: '',
	expiryDate: '',
	cvc: '',
	invoiceMethod: '',
	carrierType: B2C_INVOICE_MEMBER,
	donate: DONATE_OPTIONS[0].key,
	uniformNumber: '',
	companyName: '',
	mobileBarcodeCarrier: '',
	citizenDigitalCertificate: '',
	isCreditCardError: false,
	isExpError: false,
	isCvcError: false,
	isInvoiceMethodError: false,
	isMobileBarcodeCarrierError: false,
	isCitizenDigitalCertificateError: false,
	// event register form
	registerFormDataBySlotIdMap: {},
	registerOtherAttenderFormDataBySlotIdMap: {},
	registerFormFieldsByEventIdMap: {},
	registerFormFieldsSideEffectsMap: {},
	registerFormErrorFieldBySlotIdMap: {},
	// webinar register form
	webinarRegisterFormDataBySlotIdMap: {},
	webinarRegisterFormFieldsByWebinarIdMap: {},
	webinarRegisterFormFieldsSideEffectsMap: {},
	webinarRegisterFormErrorFieldBySlotIdMap: {},
	paymentState: STATE_IDLE,
	reservedUntilCountDown: {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	},
	areaInfo: [],
	areaInfoState: STATE_IDLE,

	// checkout-form for purchase
	// purchaseInfoFields: [],
	// purchaseInfoFieldsState: STATE_IDLE,
	recipientInfo: {},
	recipientInfoState: STATE_IDLE,
	recipientInfoFormErrorFields: [],
	isDefaultAddress: true,
	checkoutFormSetting: {},
	checkoutFormSettingState: STATE_IDLE,
	customCheckoutFormFieldsInfo: [],
	customCheckoutFormErrorFields: [],
} as ICheckout;

export default handleActions(
	{
		[INIT_CHECKOUT_STATE]() {
			return initialState;
		},
		[UPDATE_PAYMENT_METHOD](state, { payload }) {
			return update(state, {
				paymentMethod: { $set: payload.paymentMethod },
			});
		},
		[UPDATE_CREDIT_CARD_NO](state, { payload }) {
			return update(state, {
				creditCardNo: { $set: payload.creditCardNo },
			});
		},
		[UPDATE_EXPIRY_DATE](state, { payload }) {
			return update(state, {
				expiryDate: { $set: payload.expiryDate },
			});
		},
		[UPDATE_CVC](state, { payload }) {
			return update(state, {
				cvc: { $set: payload.cvc },
			});
		},
		[UPDATE_INVOICE_METHOD](state, { payload }) {
			return update(state, {
				invoiceMethod: { $set: payload.invoiceMethod },
			});
		},
		[UPDATE_CARRIER_TYPE](state, { payload }) {
			return update(state, {
				carrierType: { $set: payload.carrierType },
			});
		},
		[UPDATE_DONATE](state, { payload }) {
			return update(state, {
				donate: { $set: payload.donate },
			});
		},
		[UPDATE_UNIFORM_NUMBER](state, { payload }) {
			return update(state, {
				uniformNumber: { $set: payload.uniformNumber },
			});
		},
		[UPDATE_COMPANY_NAME](state, { payload }) {
			return update(state, {
				companyName: { $set: payload.companyName },
			});
		},
		[UPDATE_MOBILE_BARCODE_CARRIER](state, { payload }) {
			return update(state, {
				mobileBarcodeCarrier: { $set: payload.mobileBarcodeCarrier },
			});
		},
		[UPDATE_CITIZEN_DIGITAL_CERTIFICATE](state, { payload }) {
			return update(state, {
				citizenDigitalCertificate: { $set: payload.citizenDigitalCertificate },
			});
		},
		[UPDATE_IS_CREDIT_CARD_ERROR](state, { payload }) {
			return update(state, {
				isCreditCardError: { $set: payload.isCreditCardError },
			});
		},
		[UPDATE_IS_EXP_ERROR](state, { payload }) {
			return update(state, {
				isExpError: { $set: payload.isExpError },
			});
		},
		[UPDATE_IS_CVC_ERROR](state, { payload }) {
			return update(state, {
				isCvcError: { $set: payload.isCvcError },
			});
		},
		[UPDATE_IS_INVOICE_METHOD_ERROR](state, { payload }) {
			return update(state, {
				isInvoiceMethodError: { $set: payload.isInvoiceMethodError },
			});
		},
		[UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR](state, { payload }) {
			return update(state, {
				isMobileBarcodeCarrierError: { $set: payload.isMobileBarcodeCarrierError },
			});
		},
		[UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR](state, { payload }) {
			return update(state, {
				isCitizenDigitalCertificateError: { $set: payload.isCitizenDigitalCertificateError },
			});
		},
		[UPDATE_REGISTER_FORM_BY_SLOT_ID](state, { payload }) {
			const { slotId, fieldId, fieldName, value, isOtherAttender, otherAttenderIndex } = payload;
			let data = state.registerFormDataBySlotIdMap[slotId];
			if (isOtherAttender) {
				data = state.registerOtherAttenderFormDataBySlotIdMap?.[slotId]?.[otherAttenderIndex];
			}
			const isFormDataExist = !!data;
			if (!isFormDataExist) {
				if (isOtherAttender) {
					return update(state, {
						registerOtherAttenderFormDataBySlotIdMap: {
							$set: {
								[slotId]: {
									[otherAttenderIndex]: [
										{
											fieldId,
											fieldName,
											value,
										},
									],
								},
							},
						},
					});
				}
				return update(state, {
					registerFormDataBySlotIdMap: {
						[slotId]: { $set: [
							{
								fieldId,
								fieldName,
								value,
							},
						] },
					},
				});
			}

			const isFieldExist = data.filter(field => field.fieldId === fieldId);
			const filteredFields = data.filter(field => field.fieldId !== fieldId);

			if (isOtherAttender) {
				return update(state, {
					registerOtherAttenderFormDataBySlotIdMap: {
						[slotId]: {
							[otherAttenderIndex]: {
								$set: [
									...(isFieldExist ? filteredFields : filteredFields),
									{
										fieldId,
										fieldName,
										value,
									},
								],
							},
						},
					},
				});
			}
			return update(state, {
				registerFormDataBySlotIdMap: {
					[slotId]: { $set: [
						...(isFieldExist ? filteredFields : filteredFields),
						{
							fieldId,
							fieldName,
							value,
						},
					] },
				},
			});
		},
		[UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID](state, { payload }) {
			const { slotId, fieldId, fieldName, value } = payload;

			const isFormDataExist = !!state.webinarRegisterFormDataBySlotIdMap[slotId];
			if (!isFormDataExist) {
				return update(state, {
					webinarRegisterFormDataBySlotIdMap: {
						[slotId]: { $set: [
							{
								fieldId,
								fieldName,
								value,
							},
						] },
					},
				});
			}

			const isFieldExist = state.webinarRegisterFormDataBySlotIdMap[slotId]
				.filter(field => field.fieldId === fieldId);
			const filteredFields = state.webinarRegisterFormDataBySlotIdMap[slotId]
				.filter(field => field.fieldId !== fieldId);

			return update(state, {
				webinarRegisterFormDataBySlotIdMap: {
					[slotId]: { $set: [
						...(isFieldExist ? filteredFields : filteredFields),
						{
							fieldId,
							fieldName,
							value,
						},
					] },
				},
			});
		},
		// get offline event register form fields by event id
		[GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL](state, { payload: { eventId } }) {
			return update(state, {
				registerFormFieldsSideEffectsMap: { $set: {
					[eventId]: STATE_LOADING,
				} },
			});
		},
		[GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE](state, { payload: { eventId, response } }) {
			return update(state, {
				registerFormFieldsByEventIdMap: { $merge: {
					[eventId]: response,
				} },
				registerFormFieldsSideEffectsMap: { $merge: {
					[eventId]: STATE_SUCCESS,
				} },
			});
		},
		[GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL](state, { payload: { eventId } }) {
			return update(state, {
				registerFormFieldsSideEffectsMap: { $set: {
					[eventId]: STATE_FAIL,
				} },
			});
		},
		[GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE](state, { payload: { webinarId, response } }) {
			return update(state, {
				webinarRegisterFormFieldsByWebinarIdMap: { $merge: {
					[webinarId]: response,
				} },
				webinarRegisterFormFieldsSideEffectsMap: { $merge: {
					[webinarId]: STATE_SUCCESS,
				} },
			});
		},
		[GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL](state, { payload: { webinarId } }) {
			return update(state, {
				webinarRegisterFormFieldsSideEffectsMap: { $set: {
					[webinarId]: STATE_FAIL,
				} },
			});
		},
		// update register form error fields
		[UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP](state, { payload: { slotId, errorFieldsList } }) {
			return update(state, {
				registerFormErrorFieldBySlotIdMap: { $merge: {
					[slotId]: errorFieldsList,
				} },
			});
		},
		[UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP](state, { payload: { slotId, errorFieldsList } }) {
			return update(state, {
				webinarRegisterFormErrorFieldBySlotIdMap: { $merge: {
					[slotId]: errorFieldsList,
				} },
			});
		},
		[UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS](state, { payload: { errorFieldsList } }) {
			return update(state, {
				recipientInfoFormErrorFields: { $set: errorFieldsList },
			});
		},
		[combineActions(
			PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL,
			PAY_VIA_VACC_WITH_REGISTER_FORM_CALL,
			PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL,
		)](state) {
			return update(state, {
				paymentState: { $set: STATE_LOADING },
			});
		},
		[combineActions(
			PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE,
			PAY_VIA_VACC_WITH_REGISTER_FORM_DONE,
			PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE,
		)](state) {
			return update(state, {
				paymentState: { $set: STATE_SUCCESS },
				creditCardNo: { $set: initialState.creditCardNo },
				expiryDate: { $set: initialState.expiryDate },
				cvc: { $set: initialState.cvc },
			});
		},
		[combineActions(
			PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL,
			PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL,
			PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL,
		)](state, { payload }) {
			const status = payload.response?.status;
			const errorData = payload.response?.data;

			if (status === 403) {
				return update(state, {
					paymentState: { $set: 'COURSE_UNPUBLISHED' },
				});
			}

			if (errorData?.i18n_msg?.indexOf('拒絕卡號') > -1) {
				return update(state, {
					isCreditCardError: { $set: true },
					paymentState: { $set: 'FAIL_CREDIT_CARD' },
				});
			}

			if (errorData?.i18n_msg?.indexOf('卡片到期日格式錯誤') > -1) {
				return update(state, {
					isExpError: { $set: true },
					paymentState: { $set: 'FAIL_EXPIRY' },
				});
			}

			if (errorData?.i18n_msg?.indexOf('末三碼格式錯誤') > -1) {
				return update(state, {
					isCvcError: { $set: true },
					paymentState: { $set: 'FAIL_CVC' },
				});
			}

			if (errorData?.i18n_msg) {
				ui.toastShow({ message: errorData?.i18n_msg, type: TOAST_TYPE_ERROR });
			}

			return update(state, {
				paymentState: { $set: errorData?.errno ? 'FAIL_CREDIT_CARD' : STATE_FAIL },
			});
		},
		[SET_RESERVED_UNTIL_COUNT_DOWN](state, { payload }) {
			return update(state, {
				reservedUntilCountDown: { $set: payload.countdown },
			});
		},
		[SET_PAYMENT_STATE](state, { payload }) {
			return update(state, {
				paymentState: { $set: payload.paymentState },
			});
		},
		[GET_AREA_INFO_CALL](state) {
			return update(state, {
				areaInfoState: { $set: STATE_LOADING },
			});
		},
		[GET_AREA_INFO_DONE](state, { payload }) {
			return update(state, {
				areaInfo: { $set: payload.response },
				areaInfoState: { $set: STATE_SUCCESS },
			});
		},
		[GET_AREA_INFO_FAIL](state) {
			return update(state, {
				areaInfoState: { $set: STATE_FAIL },
			});
		},
		// [GET_PURCHASE_INFO_FIELDS_CALL](state) {
		// 	return update(state, {
		// 		purchaseInfoFieldsState: { $set: STATE_LOADING },
		// 	});
		// },
		// [GET_PURCHASE_INFO_FIELDS_DONE](state, { payload }) {
		// 	return update(state, {
		// 		purchaseInfoFieldsState: { $set: STATE_SUCCESS },
		// 		purchaseInfoFields: { $set: payload.response },
		// 	});
		// },
		// [GET_PURCHASE_INFO_FIELDS_FAIL](state) {
		// 	return update(state, {
		// 		purchaseInfoFieldsState: { $set: STATE_FAIL },
		// 	});
		// },
		[GET_RECIPIENT_INFO_CALL](state) {
			return update(state, {
				recipientInfoState: { $set: STATE_LOADING },
			});
		},
		[GET_RECIPIENT_INFO_DONE](state, { payload }) {
			return update(state, {
				recipientInfoState: { $set: STATE_SUCCESS },
				recipientInfo: { $set: {
					...payload.response,
					recipient_area: '',
					recipient_county: '',
				} },
			});
		},
		[GET_RECIPIENT_INFO_FAIL](state) {
			return update(state, {
				recipientInfoState: { $set: STATE_FAIL },
			});
		},
		[UPDATE_PURCHASE_INFO](state, { payload }) {
			return update(state, {
				recipientInfo: { $merge: {
					[`recipient_${payload.fieldName}`]: payload.fieldValue,
				} },
			});
		},
		[UPDATE_DEFAULT_SETTING](state) {
			return update(state, {
				isDefaultAddress: { $set: !state.isDefaultAddress },
			});
		},
		// get checkout-form
		[GET_CHECKOUT_FORM_CALL](state) {
			return update(state, {
				checkoutFormSettingState: { $set: STATE_LOADING },
			});
		},
		[GET_CHECKOUT_FORM_DONE](state, { payload }) {
			const updatedState = update(state, {
				checkoutFormSetting: { $set: payload.response },
				checkoutFormSettingState: { $set: STATE_SUCCESS },
			});
			return updatedState;
		},
		[GET_CHECKOUT_FORM_FAIL](state) {
			return update(state, {
				checkoutFormSettingState: { $set: STATE_FAIL },
			});
		},
		// set custom checkout from fields info
		[SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO](state, { payload }) {
			return update(state, {
				customCheckoutFormFieldsInfo: { $set: payload.updatedFormFields },
			});
		},
		[SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS](state, { payload: { errorFieldsList } }) {
			return update(state, {
				customCheckoutFormErrorFields: { $set: errorFieldsList },
			});
		},
	},
	initialState,
);
