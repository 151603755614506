import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { school } from '@src/services';
import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const getSchoolsCall = (action$, store) => action$.pipe(
	ofType(types.GET_SCHOOLS_CALL),
	switchMap(({ payload: { fromLogin } }) => fromPromise(school.getSchools()).pipe(
		switchMap(res => {
			const newActions = [actions.getSchoolsDone(res)];

			if (res.length === 0) {
				newActions.push(actions.historyPushCall('/select-plan-page'));
			} else {
				const { value: storeValue } = store;
				const {
					school: { schoolId },
				} = storeValue;

				if (!schoolId) {
					if (res.length === 1) {
						const { school_id } = res[0];
						newActions.pushactions.setSchoolId({ schoolId: school_id });
					} else {
						newActions.push(actions.historyPushCall('/school'));
					}
				} else if (fromLogin) {
					newActions.push(actions.historyPushCall('/home-page'));
				}
			}

			return newActions;
		}),
		catchError(err => of(actions.getSchoolsFail(err))),
	)),
);

export const getSchoolPlansCall = (action$, store) => action$.pipe(
	ofType(types.GET_SCHOOL_PLANS_CALL),
	switchMap(({ payload: { renew_type } }) => fromPromise(school.getSchoolPlans({ renew_type })).pipe(
		map(res => actions.getSchoolPlansDone({ plans: res, renewType: renew_type })),
		catchError(err => of(actions.getSchoolPlansFail(err))),
	)),
);

export const addSchoolCall = (action$, store) => action$.pipe(
	ofType(types.ADD_SCHOOL_CALL),
	switchMap(({ payload }) => fromPromise(school.addSchool(payload)).pipe(
		switchMap(res => [
			actions.setSchoolId({ schoolId: res.school_id }),
			actions.addSchoolDone(res),
			actions.historyPushCall('/school'),
		]),
		catchError(err => of(actions.addSchoolFail(err))),
	)),
);

export const checkHostNameExistCall = (action$, store) => action$.pipe(
	ofType(types.CHECK_HOST_NAME_EXIST_CALL),
	switchMap(({ payload }) => fromPromise(school.checkHostNameExist(payload)).pipe(
		map(res => actions.checkHostNameExistDone(res)),
		catchError(err => of(actions.checkHostNameExistFail(err))),
	)),
);

export const getTeachersCall = (action$, store) => action$.pipe(
	ofType(types.GET_TEACHERS_CALL),
	switchMap(({ payload }) => fromPromise(school.getTeachers(payload)).pipe(
		map(res => actions.getTeachersDone(res)),
		catchError(err => of(actions.getTeachersFail(err))),
	)),
);

export const addTeacherCall = (action$, store) => action$.pipe(
	ofType(types.ADD_TEACHER_CALL),
	switchMap(({ payload }) => fromPromise(school.addTeacher(payload)).pipe(
		switchMap(res => [
			actions.setModal({ showModal: false }),
			actions.getTeachersCall(),
			actions.addTeacherDone(res),
		]),
		catchError(err => of(actions.addTeacherFail(err))),
	)),
);

export const updateTeacherCall = (action$, store) => action$.pipe(
	ofType(types.UPDATE_TEACHER_CALL),
	switchMap(({ payload }) => fromPromise(school.updateTeacher(payload)).pipe(
		switchMap(res => [
			actions.setModal({ showModal: false }),
			actions.getTeachersCall(),
			actions.updateTeacherDone(res),
		]),
		catchError(err => of(actions.updateTeacherFail(err))),
	)),
);

export const deleteTeacherCall = (action$, store) => action$.pipe(
	ofType(types.DELETE_TEACHER_CALL),
	switchMap(({ payload }) => fromPromise(school.deleteTeacher(payload)).pipe(
		switchMap(res => [
			actions.setCheckModal({ showCheckModal: false }),
			actions.setModal({ showModal: false }),
			actions.getTeachersCall(),
			actions.deleteTeacherDone(res),
		]),
		catchError(err => of(actions.deleteTeacherFail(err))),
	)),
);

// 取得所有註冊欄位
export const getRegisterFieldsEpic = action$ => action$.pipe(
	ofType(types.GET_REGISTER_FIELDS_CALL),
	switchMap(() => fromPromise(school.getRegisterFields()).pipe(
		switchMap(response => of(
			actions.getRegisterFieldsDone({ response }),
		)),
		catchError(error => of(
			actions.getRegisterFieldsFail({ error }),
		)),
	)),
);

export const getSchoolSearchEpic = action$ => action$.pipe(
	ofType(types.GET_SCHOOL_SEARCH_CALL),
	switchMap(({ payload }) => fromPromise(school.getSchoolSearch(payload)).pipe(
		switchMap(response => {
			if (payload.onSuccess) payload.onSuccess(response);
			return of(
				actions.getSchoolSearchDone({ response }),
			);
		}),
		catchError(error => of(
			actions.getSchoolSearchFail({ error }),
		)),
	)),
);
