/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@src/components/textField';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import * as actionCreators from '@src/store/actions';
import { MODAL_TYPE_LOGIN, I18N_TO_LANG } from '@src/const/common';
import ModalFooterInfo from '@src/components/modalContainer/components/modalFooterInfo';
import { IconComponent } from '@src/icons';
import { ErrorMessage } from '@src/components';
import { getThemeSelector } from '@src/store/selectors';

import LoginWithOther from '../loginModal/components/LoginWithOther';
import ModalHeader from '../modalHeader';
import { Wrapper, Content, BlueBtn, LinkButton, Box, Footer } from './Styled';
import useRegisterFields from './useRegisterFields';
import Selector from './Selector';

const RegisterFieldsGroup = styled(Col)`
	& > * {
		background: #FFF;
	}
	& > *:not(:first-child) {
		margin-top: 20px;
	}
`;

const RegisterModal = ({ onClose, actions, t, errorMessage }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [currentEmail, setCurrentEmail] = useState('');
	const [currentSurName, setCurrentSurName] = useState('');
	const [currentFirstName, setCurrentFirstName] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [targetClass, setTargetClass] = useState();
	const [registerFieldState, setRegisterFieldState] = useState([]);
	const [requiredErrorList, setRequiredErrorList] = useState([]);
	const { i18n } = useTranslation();
	const { registerFields } = useRegisterFields();
	const hasRegisterFields = registerFields?.length > 0;
	const { themes: { disable_oauth: isDisableOauth } } = useSelector(getThemeSelector);

	const handleValidateSubmittedData = () => {
		const requiredFields = registerFields.filter(field => field.enabled && field.is_required);
		const errorIdList = requiredFields.filter(field => {
			const foundField = registerFieldState.find(item => item.id === field.id);
			const isEmpty = !foundField || foundField?.value?.trim()?.length === 0;
			return isEmpty;
		}).map(item => item.id);

		setRequiredErrorList(errorIdList);

		return errorIdList.length === 0;
	};

	const handleUpdateRegisterFieldsState = ({ fieldId, value }) => {
		setRegisterFieldState(prev => [
			...prev.filter(field => field.id !== fieldId),
			{
				id: fieldId,
				value,
			},
		]);
	};

	function handleChange(value) {
		setCurrentLanguage(value);
	}

	function handleChangePasswordType() {
		setShowPassword(!showPassword);
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const isValid = handleValidateSubmittedData();

		if (isValid) {
			actions.emailRegisterCall({
				...({
					email: currentEmail,
					password: currentPassword,
					first_name: currentFirstName,
					last_name: currentSurName,
					isRegister: true,
					language: I18N_TO_LANG[i18n.language.toLocaleLowerCase()],
				}),
				...(registerFields.length > 0 && {
					register_fields: registerFieldState,
				}),
			});
		}
	}

	function handleChangeModal() {
		actions.clearErrorMessage();
		actions.setModalType({ type: MODAL_TYPE_LOGIN });
	}

	function handleClickStart(e) {
		setTargetClass(e.target.className);
	}

	function handleClickEnd(e) {
		if (
			e.target.id === 'modal-wrap' &&
      window.innerWidth > 768 &&
      targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass();
	}

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<div className="login icon-icon_close_31" onClick={onClose} />
				<ModalHeader title={t('login:toRegister')} onClose={onClose} />
				<form onSubmit={e => handleSubmit(e)}>
					<Row gutter={[10, 20]} align="center">
						<Col xs={24}>
							<TextField
								required
								withError={errorMessage.inputErrorMessages.email}
								inputType="email"
								placeholder={t('login:email')}
								InputValue={currentEmail}
								autoComplete="email"
								onInputValueChange={value => setCurrentEmail(value)}
								icon={(
									<IconComponent
										size={22}
										color="#dcdcdc"
										icon="login_form_email"
									/>
								)}
								autoFocus
							/>
							{errorMessage.inputErrorMessages.email && (
								<ErrorMessage
									message={t(errorMessage.inputErrorMessages.email)}
								/>
							)}
						</Col>
						{/* TODO: style異常.. width應該是有變過所以會跑版 */}

						<Col xs={12}>
							<TextField
								required
								inputType="text"
								placeholder={t('login:firstName')}
								InputValue={currentSurName}
								autoComplete="family-name"
								onInputValueChange={value => setCurrentSurName(value)}
								icon={(
									<IconComponent
										size={22}
										color="#dcdcdc"
										icon="login_form_man"
									/>
								)}
							/>
						</Col>
						<Col xs={12}>
							<TextField
								required
								inputType="text"
								placeholder={t('login:lastName')}
								InputValue={currentFirstName}
								autoComplete="given-name"
								onInputValueChange={value => setCurrentFirstName(value)}
								icon={(
									<IconComponent
										size={22}
										color="#dcdcdc"
										icon="login_form_man"
									/>
								)}
							/>
						</Col>
						<Col xs={24}>
							<TextField
								required
								inputType={showPassword ? 'text' : 'password'}
								placeholder={t('all:login_new_password')}
								autoComplete="new-password"
								InputValue={currentPassword}
								onInputValueChange={value => setCurrentPassword(value)}
								minLength="8"
								icon={(
									<div onClick={handleChangePasswordType}>
										<IconComponent
											size={22}
											color="#dcdcdc"
											icon={
												showPassword ?
													'login_form_eye_onsvg' : 'login_form_eye_off'
											}
										/>
									</div>
								)}
							/>
						</Col>
						<RegisterFieldsGroup xs={24}>
							{
								registerFields.map(field => {
									const {
										id: fieldId,
										input_type: inputType,
										hint,
										options,
										enabled,
									} = field;
									const isTypeInputBox = inputType === 'input_box';
									const isTypeMenu = inputType === 'menu';
									const foundField = registerFieldState
										.find(fieldState => fieldState?.id === fieldId) || {};
									const isEmpty = requiredErrorList.indexOf(fieldId) > -1;

									if (isTypeInputBox && enabled) {
										return (
											<div key={fieldId}>
												<TextField
													inputType="text"
													placeholder={hint}
													InputValue={foundField.value}
													autoComplete="given-name"
													onInputValueChange={value => {
														setRequiredErrorList(
															prev => prev.filter(item => item !== fieldId),
														);
														handleUpdateRegisterFieldsState({
															fieldId, value,
														});
													}}
												/>
												{isEmpty && (
													<ErrorMessage
														message={t('all:required_field')}
													/>
												)}
											</div>
										);
									}
									if (isTypeMenu && enabled) {
										return (
											<div key={fieldId}>
												<Selector
													selectedOption={foundField.value}
													hint={hint}
													options={options}
													onSelect={value => {
														setRequiredErrorList(
															prev => prev.filter(item => item !== fieldId),
														);
														handleUpdateRegisterFieldsState({
															fieldId, value,
														});
													}}
												/>
												{isEmpty && (
													<ErrorMessage
														message={t('all:required_field')}
													/>
												)}
											</div>
										);
									}
									return null;
								})
							}
						</RegisterFieldsGroup>
						<Col xs={24}>
							<BlueBtn>{t('all:register_btn')}</BlueBtn>
						</Col>
					</Row>
				</form>
				{/* google / facebook / line 登入 */}
				{!isDisableOauth && !hasRegisterFields && <LoginWithOther />}

				<Box>
					<span>{t('login:toLogin')}</span>
					<LinkButton onClick={handleChangeModal}>{t('login:title')}</LinkButton>
				</Box>
				<Footer>
					<ModalFooterInfo />
				</Footer>
			</Content>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	errorMessage: state.errorMessage,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(RegisterModal),
);
