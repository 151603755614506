import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as actionCreators from '@src/store/actions';
import { getPasswordStrength } from '@src/utils/password';

import { IconComponent } from '@src/icons';
import TextField from '@src/components/textField';
import ModalHeader from '../modalHeader';
import { ErrorMessage } from '@src/components';
import { BlueBtn, Wrapper, Content } from './Styled';

export function ResetPasswordModal({
  t,
  actions,
  errorMessage,
  modalData,
  onClose,
  theme,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [stateStrength, setStrength] = useState({
    name: '',
  });
  const [stateComparison, setComparison] = useState(false);
  const [targetClass, setTargetClass] = useState();

  const { themes: { default_landing_system } } = theme;

  useEffect(() => {
    if (password.trim() !== '') {
      const passwordStrength = getPasswordStrength(password);

      setStrength(passwordStrength);
    }
  }, [password]);

  useEffect(() => {
    // 密碼是否相符
    if (confirmPassword.trim() !== '') {
      setComparison(confirmPassword !== password);
    }
  }, [confirmPassword]);

  function handleSubmit(e) {
    e.preventDefault();

    actions.resetPasswordCall({
      email: modalData.email,
      password: confirmPassword,
      verification_code: modalData.verificationCode,
      onSuccess: () => {
        if (default_landing_system === 'hr_system') {
          window.open('/hr');
        } else {
          window.open('/member');
        }
      },
    });
  }

  function handleChangePasswordType() {
    setShowPassword(!showPassword);
  }

  function handleChangeConfirmPasswordType() {
    setConfirmShowPassword(!showConfirmPassword);
  }

  function handleClickStart(e) {
    setTargetClass(e.target.className);
  }

  function handleClickEnd(e) {
    if (
      e.target.id === 'modal-wrap' &&
      window.innerWidth > 768 &&
      targetClass === e.target.className
    ) {
      onClose();
    }
    setTargetClass();
  }

  return (
    <Wrapper
      onMouseDown={(e) => handleClickStart(e)}
      onMouseUp={(e) => handleClickEnd(e)}
      id="modal-wrap"
    >
      <Content onClick={(e) => e.stopPropagation()}>
        <div className="login icon-icon_close_31" onClick={onClose} />
        <ModalHeader title="重設密碼" onClose={onClose} />
        <p className="des">
          請為此帳號設定一組新密碼：
          <br />
          {modalData?.email}
        </p>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="label">新密碼</div>
          <TextField
            required
            inputType={showPassword ? 'text' : 'password'}
            placeholder="請輸入密碼"
            InputValue={password}
            onInputValueChange={(value) => setPassword(value)}
            withError={password.trim() !== '' && password.length < 8}
            icon={
              <div onClick={handleChangePasswordType}>
                <IconComponent
                  size={22}
                  color="#dcdcdc"
                  icon={
                    showPassword ? 'login_form_eye_onsvg' : 'login_form_eye_off'
                  }
                  autoFocus
                />
              </div>
            }
          />
          {password.trim() !== '' && (
            <div className="errorMessageBox">
              {stateStrength.name !== '' && (
                <div style={{ color: stateStrength.color }}>
                  密碼強度：{stateStrength.name}
                </div>
              )}
              {password.length < 8 && <div>請輸入至少 8 字</div>}
            </div>
          )}
          {errorMessage?.email && (
            <ErrorMessage message={t(errorMessage.email)} />
          )}
          <div className="label">請再次輸入新密碼</div>

          <TextField
            required
            inputType={showConfirmPassword ? 'text' : 'password'}
            placeholder="請輸入密碼"
            InputValue={confirmPassword}
            onInputValueChange={(value) => setConfirmPassword(value)}
            minLength="8"
            withError={stateComparison}
            icon={
              <div onClick={handleChangeConfirmPasswordType}>
                <IconComponent
                  size={22}
                  color="#dcdcdc"
                  icon={
                    showConfirmPassword
                      ? 'login_form_eye_onsvg'
                      : 'login_form_eye_off'
                  }
                />
              </div>
            }
          />
          {stateComparison && <ErrorMessage message="密碼不相符，請重新輸入" />}
          <BlueBtn>變更密碼</BlueBtn>
        </form>
      </Content>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    modalData: state.ui.modalData,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...actionCreators }, dispatch),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal)
);
