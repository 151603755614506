import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE } from '@src/const/common';
import {
	GET_ALL_CATEGORIES_CALL,
	GET_ALL_CATEGORIES_DONE,
	GET_ALL_CATEGORIES_FAIL,
	GET_COMMODITY_SEARCH_CALL,
	GET_COMMODITY_SEARCH_SWITCH_CALL,
	GET_COMMODITY_SEARCH_DONE,
	GET_COMMODITY_SEARCH_FAIL,
	GET_COMMODITY_PUBLISHED_TYPES_CALL,
	GET_COMMODITY_PUBLISHED_TYPES_DONE,
	GET_COMMODITY_PUBLISHED_TYPES_FAIL,
} from '@src/store/types/category';

const initialState = {
	categories: [],
	courseByCategory: {},
	hierarchy: [],
	getCategoriesState: STATE_IDLE,
	search: [],
	searchPagination: {
		totalCount: 0,
	},
	getSearchState: STATE_IDLE,
	commodityPublishedType: [],
	getCommodityPublishedTypeState: STATE_IDLE,
};

export default handleActions({
	[GET_ALL_CATEGORIES_CALL](state) {
		return update(state, {
			getCategoriesState: { $set: STATE_LOADING },
		});
	},
	[GET_ALL_CATEGORIES_DONE](state, { payload: { response } }) {
		return update(state, {
			categories: { $set: response.categories },
			hierarchy: { $set: response.hierarchy },
			getCategoriesState: { $set: STATE_SUCCESS },
		});
	},
	[GET_ALL_CATEGORIES_FAIL](state) {
		return update(state, {
			getCategoriesState: { $set: STATE_FAIL },
		});
	},

	[GET_COMMODITY_SEARCH_CALL](state) {
		return update(state, {
			getSearchState: { $set: STATE_LOADING },
		});
	},
	[GET_COMMODITY_SEARCH_SWITCH_CALL](state) {
		return update(state, {
			getSearchState: { $set: STATE_LOADING },
		});
	},
	[GET_COMMODITY_SEARCH_DONE](state, { payload: { list, categoryId, pagination } }) {
		return update(state, {
			search: { $set: list },
			searchPagination: { $set: pagination },
			courseByCategory: {
				[categoryId]: { $set: list },
			},
			getSearchState: { $set: STATE_SUCCESS },
		});
	},
	[GET_COMMODITY_SEARCH_FAIL](state) {
		return update(state, {
			getSearchState: { $set: STATE_FAIL },
		});
	},

	[GET_COMMODITY_PUBLISHED_TYPES_CALL](state) {
		return update(state, {
			getCommodityPublishedTypeState: { $set: STATE_LOADING },
		});
	},
	[GET_COMMODITY_PUBLISHED_TYPES_DONE](state, { payload: { response } }) {
		return update(state, {
			commodityPublishedType: { $set: response },
			getCommodityPublishedTypeState: { $set: STATE_SUCCESS },
		});
	},
	[GET_COMMODITY_PUBLISHED_TYPES_FAIL](state) {
		return update(state, {
			getCommodityPublishedTypeState: { $set: STATE_FAIL },
		});
	},

}, initialState);
