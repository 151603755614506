import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
	getSurveysQuestionSheet,
	postSurveysAnswerSheet,
	getSurveysAnswerSheet,
} from '@src/services/survey';
import { TOAST_TYPE_SUCCESS } from '@src/const/common';
import { toastShowCall } from '@src/store/actions/ui';

import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const getSurveysQuestionSheetEpic = action$ => action$.pipe(
	ofType(types.GET_SURVEYS_QUESTION_SHEET_CALL),
	switchMap(({ payload }) => fromPromise(getSurveysQuestionSheet(payload)).pipe(
		switchMap(response => of(
			actions.getSurveysQuestionSheetDone({ response }),
		)),
		catchError(error => of(actions.getSurveysQuestionSheetFail({ error }))),
	)),
);

export const postSurveysAnswerSheetEpic = action$ => action$.pipe(
	ofType(types.POST_SURVEYS_ANSWER_SHEET_CALL),
	switchMap(({ payload }) => {
		const { onSuccess, ...answerSheetPayload } = payload;
		return fromPromise(postSurveysAnswerSheet(answerSheetPayload)).pipe(
			switchMap(response => {
				if (onSuccess) {
					onSuccess();
				}
				return of(
					toastShowCall({ message: 'common:submit_successfully', type: TOAST_TYPE_SUCCESS }),
					actions.postSurveysAnswerSheetDone({ response }),
				);
			}),
			catchError(error => of(actions.postSurveysAnswerSheetFail({ error }))),
		);
	}),
);

export const getSurveysAnswerSheetEpic = action$ => action$.pipe(
	ofType(types.GET_SURVEYS_ANSWER_SHEET_CALL),
	switchMap(({ payload }) => fromPromise(getSurveysAnswerSheet(payload)).pipe(
		switchMap(response => of(
			actions.getSurveysAnswerSheetDone({ response }),
		)),
		catchError(error => of(actions.getSurveysAnswerSheetFail({ error }))),
	)),
);
