import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
	getQuizzesQuestionSheet, setQuizzesAnswerSheet, getQuizzesAnswerSheet,
	getQuizzesAnswerSheetViewAnswer,
} from '@src/services/quiz';

import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const getQuizzesQuestionSheetEpic = action$ => action$.pipe(
	ofType(types.GET_QUIZZES_QUESTION_SHEET_CALL),
	switchMap(({ payload }) => fromPromise(getQuizzesQuestionSheet(payload)).pipe(
		switchMap(response => of(
			actions.getQuizzesQuestionSheetDone({ response }),
		)),
		catchError(error => of(actions.getQuizzesQuestionSheetFail({ error }))),
	)),
);

export const getQuizzesAnswerSheetViewAnswerEpic = action$ => action$.pipe(
	ofType(types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_CALL),
	switchMap(({ payload }) => fromPromise(getQuizzesAnswerSheetViewAnswer(payload)).pipe(
		switchMap(response => of(
			actions.getQuizzesAnswerSheetViewAnswerDone({ response }),
		)),
		catchError(error => of(actions.getQuizzesAnswerSheetViewAnswerFail({ error }))),
	)),
);

export const setQuizzesAnswerSheetEpic = action$ => action$.pipe(
	ofType(types.SET_QUIZZES_ANSWER_SHEET_CALL),
	switchMap(({ payload }) => {
		const { onSuccess, ...answerSheetPayload } = payload;
		return fromPromise(setQuizzesAnswerSheet(answerSheetPayload)).pipe(
			switchMap(response => {
				if (onSuccess) {
					onSuccess();
				}
				return of(
					actions.setQuizzesAnswerSheetDone({ response }),
				);
			}),
			catchError(error => of(actions.setQuizzesAnswerSheetFail({ error }))),
		);
	}),
);

export const getQuizzesAnswerSheetEpic = action$ => action$.pipe(
	ofType(types.GET_QUIZZES_ANSWER_SHEET_CALL),
	mergeMap(({ payload }) => fromPromise(getQuizzesAnswerSheet(payload)).pipe(
		switchMap(response => {
			if (payload.notSaveCurrentSheet) {
				return of(
					actions.getQuizzesAllAnswerSheetDone({ response }),
				);
			}
			return of(
				actions.getQuizzesAnswerSheetDone({ response }),
			);
		}),
		catchError(error => of(actions.getQuizzesAnswerSheetFail({ error }))),
	)),
);
