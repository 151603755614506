// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

export const getOvertimeApplications = (payload: {
	year: number;
	within: string;
	status: string;
}): Observable<Action> => (
	api.get('overtime-applications/', payload)
);

export const postOvertimeApplication = (payload: {
	start_at: string;
	end_at: string;
	payment_method: string;
	reason: string;
}): Observable<Action> => (
	api.post('overtime-applications/', payload)
);

export const getOvertimeApplicationInfo = (payload: { date: string }): Observable<Action> => (
	api.get('overtime-applications/info', payload)
);

export const getOvertimeApplicationDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`overtime-applications/${payload.id}`)
);

export const postOvertimeApplicationApprove = (payload: {
	id: string;
	comments: string;
  is_approved: boolean;
}): Observable<Action> => (
	api.post(`overtime-applications/${payload.id}/approve`, payload)
);

export const getOvertimeApplicationConfirm = (payload: { id: string }): Observable<Action> => (
	api.get(`overtime-applications/${payload.id}/confirm`)
);

export const postOvertimeApplicationConfirm = (payload: {
	id: string;
	confirmed_start_at: string;
	confirmed_end_at: string
}): Observable<Action> => (
	api.post(`overtime-applications/${payload.id}/confirm`, payload)
);

export const postOvertimeApplicationWithdraw = (payload: { id: string }): Observable<Action> => (
	api.post(`overtime-applications/${payload.id}/withdraw`)
);

export const getOvertimeApplicationsApproverView = (payload: {
	year: number;
	within: string;
	status: string;
	waiting_only: boolean
}): Observable<Action> => (
	api.get('overtime-applications/approver_view', payload)
);

export const postOvertimeApplicationWithdrawApproval = (payload: { id: string }): Observable<Action> => (
	api.post(`overtime-applications/${payload.id}/withdraw_approval`)
);
