import _ from 'lodash'
import api from '@src/api'

export const uploadFile = async ({ fileData }) => {
  const { file, ext, folder = '', uiProgress, cancelToken, link_id } = fileData
  const formData = new FormData()

  formData.append('file', file)
  formData.append('ext', ext)

  if (link_id) {
    formData.append('link_id', link_id)
  }

  const otherConfigs = {
    onUploadProgress: uiProgress,
    timeout: 60 * 60 * 1000,
    cancelToken
  }

  return api.upload(`upload${folder}`, formData, otherConfigs)
}

export const uploadFileLink = ({ id }) =>
  api.get(`link/${id}`)

export const getFileVideoUrl = ({ id }) => api.get(`link/${id}/video_url`);
