import styled from 'styled-components';

const Line = `
content: '';
display: block;
width: 152px;
height: 1px;
background: #dbdbdb;
position: absolute;
top: 10px;
`;

export const Title = styled.div`
  font-family: NotoSansTC;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #676767;
  position: relative;
  margin: 0 auto 20px;
  &:before {
    ${Line};
    left: -159px;
  }
  &:after {
    ${Line};
    right: -159px;
  }
`;

export const OtherLogin = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  display: flex;
  text-decoration: none;
  color: #676767;
`;
