import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL,
	GET_DEPARTMENTS_LEARNING_RECORDS_CALL,
} from '@src/store/types/departments';

import {
	getOwnedDepartmentDone,
	getOwnedDepartmentFail,
	getDepartmentsLearningRecordsDone,
	getDepartmentsLearningRecordsFail,
} from '@src/store/actions/departments';
import {
	getOwnedDepartment,
	getDepartmentsLearningRecords,
} from '@src/services/departments';

export const getOwnedDepartmentEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL),
	mergeMap(
		({ payload }) => fromPromise(getOwnedDepartment(payload)).pipe(
			switchMap(res => of(getOwnedDepartmentDone({ res }))),
			catchError(error => of(getOwnedDepartmentFail({ error }))),
		),
	),
);

export const getDepartmentsLearningRecordsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_DEPARTMENTS_LEARNING_RECORDS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getDepartmentsLearningRecords(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getDepartmentsLearningRecordsDone({ res, isDownload: payload.isDownload }));
			}),
			catchError(error => of(getDepartmentsLearningRecordsFail({ error }))),
		),
	),
);
