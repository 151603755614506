import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs/observable/of';

import * as types from '@src/store/types';
import * as actions from '@src/store/actions';
import { TOAST_TYPE_ERROR } from '@src/const/common';

export const handleErrorMessages = (action$, store) => action$.pipe(
	ofType(types.CHECK_LOGIN_FAIL),
	switchMap(({ payload }) => {
		const { response = {} } = payload;
		const newActions = [
			actions.handleErrorMessagesDone(),
		];

		const { status } = response;

		if (status === 403) {
			newActions.push(...[
				actions.toastShowCall({ message: 'errors:repeatLogin', type: TOAST_TYPE_ERROR }),
				actions.logoutCall(),
			]);
		}
		if (status === 401) {
			newActions.push(...[
				actions.toastShowCall({ message: 'errors:loginError', type: TOAST_TYPE_ERROR }),
				actions.logoutCall(),
			]);
		}

		return newActions;
	}),
	catchError(err => of(actions.handleErrorMessagesFail(err))),
);
