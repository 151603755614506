import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';
import { uniq } from 'lodash';

import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

import { getAllPurchaseRecordsByOrderList } from '@src/services/checkoutSuccess';

export const getAllPurchaseRecordsByOrderListEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(types.GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL),
	switchMap(({ payload: { include, purchase_order_list } }) => fromPromise(getAllPurchaseRecordsByOrderList({
		include, purchase_order_list,
	}))
		.pipe(
			switchMap(response => of(actions.getAllPurchaseRecordsByOrderListDone({ response }))),
			catchError(error => of(actions.getAllPurchaseRecordsByOrderListFail({ error }))),
		)),
);

export const getEachPurchaseCourseDetailByIdEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(types.GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_CALL),
	switchMap(({ payload: { courseIdList } }) => {
		const newActions = courseIdList.map(courseId => actions.getCurrentCourseCall({ courseId }));
		return of(
			...newActions,
		);
	}),
);

export const getEachPurchaseEventDetailByLinkEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(types.GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_CALL),
	switchMap(({ payload: { eventShortLinkList } }) => {
		const newActions = uniq(eventShortLinkList)
			.map(eventShortLink => actions.getEventCall({ eventShortLink }));
		return of(
			...newActions,
		);
	}),
);

export const getEachPurchaseWebinarDetailByLinkEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
	ofType(types.GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_CALL),
	switchMap(({ payload: { webinarShortLinkList } }) => {
		const newActions = uniq(webinarShortLinkList)
			.map(webinarShortLink => actions.getWebinarCall({ webinarShortLink }));
		return of(
			...newActions,
		);
	}),
);
