import { map, catchError } from 'rxjs/operators';
import router from 'next/router';
import { ofType } from 'redux-observable';
import * as types from '@src/store/types';
import * as actions from '@src/store/actions';

export const historyPushCall = (action$, store) =>
  action$.pipe(
    ofType(types.HISTORY_PUSH_CALL),
    map(({ payload }) => {
      router.push(payload);

      return actions.historyPushDone();
    }),
    catchError((err) => [actions.historyPushFail(err)])
  );

export const historyReplaceCall = (action$, store) =>
  action$.pipe(
    ofType(types.HISTORY_REPLACE_CALL),
    map(({ payload }) => {
      router.replace(payload);

      return actions.historyReplaceDone();
    }),
    catchError((err) => [actions.historyReplaceFail(err)])
  );
