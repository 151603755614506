import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { zip } from 'rxjs';
import { isEmpty } from 'lodash';

import { ui, purchase, course } from '@src/services';
import * as actions from '@src/store/actions';
import { getCartProductsCall } from '@src/store/actions/cart';
import * as types from '@src/store/types';
import * as selectors from '@src/store/selectors';
import { MODAL_TYPE_LOGIN, MODAL_TYPE_SELECT_AUTH, TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';
import { sentGTMAddCartItem } from '@src/utils/gtag';

export const addProductToCartCall = (action$, store) => action$.pipe(
	ofType(types.ADD_PRODUCT_TO_CART_CALL),
	switchMap(({ payload }) => {
		const isSignIn = selectors.getIsSignIn(store.value);

		if (!isSignIn) {
			return [
				actions.addActionsToStash([
					{ actionMethod: 'addProductToCartCall', actionPayload: payload },
				]),
				actions.addProductToCartCancel(payload),
				actions.setModalType({ type: MODAL_TYPE_SELECT_AUTH }),
			];
		}

		return fromPromise(purchase.addProductToCart(payload.product_id)).pipe(
			switchMap(res => {
				sentGTMAddCartItem({
					id: payload?.course?.short_link,
					name: payload?.course?.title,
					raw: payload?.course,
				});
				const nextActions = [
					getCartProductsCall(),
					actions.addProductToCartDone(payload),
				];

				if (payload.isBuyNow) {
					nextActions.push(actions.historyPushCall('/checkout'));
				}

				return nextActions;
			}),
			catchError(err => of(actions.addProductToCartFail(payload))),
		);
	}),
);

export const getPurchaseCall = (action$, store) => action$.pipe(
	ofType(types.GET_PURCHASE_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchase(payload.purchase_id)).pipe(
		switchMap(res => {
			if (isEmpty(res.products)) {
				return of(actions.getPurchaseDone(res));
			} else {
				const courseIdList = (res?.products || []).map(
					course => course.course_id,
				);
				return zip(
					...courseIdList.map(courseId => fromPromise(course.getCurrentCourse({ courseId }))),
				).pipe(
					map(courseResponses => {
						const nextProducts = res.products.map((product, index) => {
							const course = courseResponses[index];

							return {
								...course,
								...product,
							};
						});

						const newResponse = {
							...res,
							products: nextProducts,
						};

						return actions.getPurchaseDone(newResponse);
					}),
					catchError(err => [actions.getPurchaseFail(err)]),
				);
			}
		}),
		catchError(err => of(actions.getPurchaseFail(err))),
	)),
);

export const getAllPurchaseListCall = (action$, store) => action$.pipe(
	ofType(types.GET_ALL_PURCHASE_LIST_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseList(payload)).pipe(
		switchMap(res => [
			actions.getPurchaseListDone({
				...res,
				include: payload.include,
			}),
		]),
		catchError(err => of(actions.getPurchaseListFail({ include: payload.include }))),
	)),
);

export const getCompletedPurchaseListCall = (action$, store) => action$.pipe(
	ofType(types.GET_COMPLETED_PURCHASE_LIST_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseList(payload)).pipe(
		switchMap(res => [
			actions.getPurchaseListDone({
				...res,
				include: payload.include,
			}),
		]),
		catchError(err => of(actions.getPurchaseListFail({ include: payload.include }))),
	)),
);

export const getCanceledPurchaseListCall = (action$, store) => action$.pipe(
	ofType(types.GET_CANCELED_PURCHASE_LIST_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseList(payload)).pipe(
		switchMap(res => [
			actions.getPurchaseListDone({
				...res,
				include: payload.include,
			}),
		]),
		catchError(err => of(
			actions.getPurchaseListFail({
				include: payload.include,
			}),
		)),
	)),
);

export const getPurchaseCourseTeacherListCall = (action$, store) => action$.pipe(
	ofType(types.GET_PURCHASE_COURSE_TEACHER_LIST_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseCourseTeacherList()).pipe(
		switchMap(res => [actions.getPurchaseCourseTeacherListDone(res)]),
		catchError(err => of(actions.getPurchaseCourseTeacherListFail(err))),
	)),
);

export const getPurchaseCourseListCall = (action$, store) => action$.pipe(
	ofType(types.GET_PURCHASE_COURSE_LIST_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseCourseList(payload)).pipe(
		switchMap(res => [actions.getPurchaseCourseListDone(res)]),
		catchError(err => of(actions.getPurchaseCourseListFail(err))),
	)),
);

export const cancelPurchaseOrderCall = (action$, store) => action$.pipe(
	ofType(types.CANCEL_PURCHASE_ORDER_CALL),
	switchMap(({ payload: { purchase_id, ...otherPayload } }) => fromPromise(purchase.cancelPurchaseOrder(purchase_id, otherPayload)).pipe(
		switchMap(res => [
			actions.getPurchaseCall({ purchase_id }),
			actions.cancelPurchaseOrderDone({ purchase_id }),
		]),
		catchError(err => {
			otherPayload.errorCallback && otherPayload.errorCallback();

			ui.toastShow({
				message: '不好意思，已過退款期限，將無法退款',
				type: TOAST_TYPE_ERROR,
			});

			return [
				actions.getPurchaseCall({ purchase_id }),
				actions.cancelPurchaseOrderFail({ purchase_id }),
			];
		}),
	)),
);

export const buyTheFreeCourseCall = (action$, store) => action$.pipe(
	ofType(types.BUY_THE_FREE_COURSE_CALL),
	switchMap(({ payload }) => {
		const isSignIn = selectors.getIsSignIn(store.value);

		if (!isSignIn) {
			return [
				actions.addActionsToStash([
					{
						actionMethod: 'buyTheFreeCourseCall',
						actionPayload: payload,
					},
				]),
				actions.buyTheFreeCourseCancel(payload),
				actions.setModalType({ type: MODAL_TYPE_LOGIN }),
			];
		}

		return fromPromise(purchase.buyTheFreeCourse(payload)).pipe(
			switchMap(res => {
				ui.toastShow({ message: '您現在可以開始上課囉！', type: TOAST_TYPE_SUCCESS });

				return [
					actions.buyTheFreeCourseDone(payload),
					actions.getCurrentCourseCall({ courseId: payload.courseId }),
				];
			}),
			catchError(err => [
				actions.buyTheFreeCourseFail(payload),
				actions.getCurrentCourseCall({ courseId: payload.courseId }),
			]),
		);
	}),
);

export const getPurchaseEventsEpic = action$ => action$.pipe(
	ofType(types.GET_PURCHASE_EVENTS_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseEvents(payload)).pipe(
		switchMap(response => of(
			actions.getPurchaseEventsDone({ response }),
		)),
		catchError(error => of(actions.getPurchaseEventsFail({ error }))),
	)),
);

export const getPurchaseWebinarsEpic = action$ => action$.pipe(
	ofType(types.GET_PURCHASE_WEBINARS_CALL),
	switchMap(({ payload }) => fromPromise(purchase.getPurchaseWebinars(payload)).pipe(
		switchMap(response => of(
			actions.getPurchaseWebinarsDone({ response }),
		)),
		catchError(error => of(actions.getPurchaseWebinarsFail({ error }))),
	)),
);
