/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-cycle
import api from '@src/api';

export const getLearningAreas = ({ ssrHeaders, area_ids }: {
	ssrHeaders: {
		[x:string]: string;
	};
	area_ids?: string;
}): any => api.get('learning-areas/', { enable: true, ...area_ids ? { area_ids } : {} }, { ssrHeaders });

export const getLearningAreasByShortLink = ({ ssrHeaders, shortLink }: {
	ssrHeaders: { [x:string]: string };
	shortLink: string
}): any => api.get(`learning-areas/${shortLink}`, {}, { ssrHeaders });

export const getLearningAreasPaths = ({ ssrHeaders, shortLink }: {
	ssrHeaders: { [x:string]: string };
	shortLink: string
}): any => api.get(`learning-areas/${shortLink}/learning-paths`, {}, { ssrHeaders });
