import api from '@src/api';

export const logout = (payload) => api.delete('/user/logout', payload);

export const emailLogin = (payload) => api.post('user/login', payload);

export const emailRegister = (payload) => api.post('user', payload);

export const emailConfirm = (payload) =>
  api.post('user/email/confirm', payload);

export const emailReSend = (payload) => api.post('user', payload);

export const updateMobile = (payload) => api.post('user/mobile', payload);

export const confirmMobile = (payload) =>
  api.post('user/mobile/confirm', payload);

export const forgetPassword = (payload) =>
  api.post('user/password/forgot', payload);

export const getForgetPasswordEmail = (payload) =>
  api.get('user/password/forgot', payload);

export const resetPassword = (payload) =>
  api.post('user/password/reset', payload);

export const socialLoginPrepare = ({ name }) =>
  api.get(`ext/oauth/${name}/login`);

export const socialLogin = ({ name, params }) =>
  api.get(`ext/oauth/${name}`, params);

export const sendBugMsg = (payload) => api.post('ext/debug', payload);

export const rfaLogin = (payload) => 
  api.post('user/rfa-login', undefined, { customHeader: { Authorization: payload.token } });