import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import * as types from '@src/store/types';

const defaultSideEffectStatus = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	questionSheet: {},
	questionSheetSideEffect: defaultSideEffectStatus,
	answerSheet: {},
	answerSheetSideEffect: defaultSideEffectStatus,
};

export default handleActions({
	// get questionSheet
	[types.GET_SURVEYS_QUESTION_SHEET_CALL](state) {
		return update(state, {
			questionSheetSideEffect: {
				$set: {
					...defaultSideEffectStatus,
					isLoading: true,
				},
			},
		});
	},
	[types.GET_SURVEYS_QUESTION_SHEET_DONE](state, { payload }) {
		return update(state, {
			questionSheet: { $set: payload.response },
			questionSheetSideEffect: {
				$set: {
					...defaultSideEffectStatus,
					isLoaded: true,
				},
			},
		});
	},
	[types.GET_SURVEYS_QUESTION_SHEET_FAIL](state, { payload }) {
		return update(state, {
			questionSheetSideEffect: {
				$set: {
					...defaultSideEffectStatus,
					error: payload.error,
				},
			},
		});
	},
	// get answerSheet
	[types.GET_SURVEYS_ANSWER_SHEET_CALL](state) {
		return update(state, {
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoading: true,
			} },
		});
	},
	[types.GET_SURVEYS_ANSWER_SHEET_DONE](state, { payload }) {
		return update(state, {
			answerSheet: { $set: payload.response },
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				isLoaded: true,
			} },
		});
	},
	[types.GET_SURVEYS_ANSWER_SHEET_FAIL](state, { payload }) {
		return update(state, {
			answerSheetSideEffect: { $set: {
				...defaultSideEffectStatus,
				error: payload.error,
			} },
		});
	},
}, initialState);
