import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE } from '@src/const/common';
import {
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL,
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE,
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL,
} from '@src/store/types/checkoutSuccess';

const initialState = {
	purchases: [],
	purchasesSideEffectStatus: STATE_IDLE,
};

export default handleActions({
	[GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL](state) {
		return update(state, {
			purchasesSideEffectStatus: { $set: STATE_LOADING },
		});
	},
	[GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE](state, { payload }) {
		return update(state, {
			purchases: { $set: payload.response },
			purchasesSideEffectStatus: { $set: STATE_SUCCESS },
		});
	},
	[GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL](state) {
		return update(state, {
			purchasesSideEffectStatus: { $set: STATE_FAIL },
		});
	},
}, initialState);
