import { handleActions } from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';

import * as types from '@src/store/types';
import { ui } from '@src/services';
import { PURCHASE_STATES } from '@src/const/checkout';
import {
	TOAST_TYPE_SUCCESS,
	TOAST_TYPE_ERROR,
	STATE_SUCCESS,
	STATE_LOADING,
	STATE_FAIL,
	STATE_IDLE,
} from '@src/const/common';

const initialState = {
	cartState: STATE_IDLE,
	cartUpdateState: STATE_IDLE,
	purchasePending: {},
	adding_products: {},
	purchaseState: STATE_IDLE,
	purchase: {},
	purchaseListStates: {},
	purchaseLists: {},
	purchaseListPaginations: {},
	purchaseCourseListState: STATE_IDLE,
	purchaseCourseList: [],
	purchaseCourseListPagination: {},
	purchaseCourseTeacherListState: STATE_IDLE,
	purchaseCourseTeacherList: [],
	cancelPurchaseOrderStates: {},
	buyTheFreeCourseStates: {},
	purchaseEvents: [],
	purchaseEventsState: STATE_IDLE,
	purchaseWebinars: [],
	purchaseWebinarsState: STATE_IDLE,
};

export default handleActions(
	{
		[types.ADD_PRODUCT_TO_CART_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.adding_products = {
					...state.adding_products,
					[payload.product_id]: {
						state: STATE_LOADING,
					},
				};
			});
		},
		[types.ADD_PRODUCT_TO_CART_CANCEL](state, { payload }) {
			return produce(state, draft => {
				draft.adding_products = {
					...state.adding_products,
					[payload.product_id]: {
						...state.adding_products[payload.product_id],
						state: STATE_IDLE,
					},
				};
			});
		},
		[types.ADD_PRODUCT_TO_CART_DONE](state, { payload }) {
			ui.toastShow({ message: '成功加入購物車', type: TOAST_TYPE_SUCCESS });

			return produce(state, draft => {
				draft.adding_products = {
					...state.adding_products,
					[payload.product_id]: {
						...state.adding_products[payload.product_id],
						state: STATE_SUCCESS,
					},
				};
			});
		},
		[types.ADD_PRODUCT_TO_CART_FAIL](state, { payload }) {
			ui.toastShow({ message: '加入購物車失敗，請重新操作', type: TOAST_TYPE_ERROR });

			return produce(state, draft => {
				draft.adding_products = {
					...state.adding_products,
					[payload.product_id]: {
						...state.adding_products[payload.product_id],
						state: 'ERROR',
					},
				};
			});
		},
		[types.LOGOUT_CALL](state) {
			return initialState;
		},
		[types.GET_PURCHASE_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseState = STATE_LOADING;
			});
		},
		[types.GET_PURCHASE_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseState = STATE_SUCCESS;
				draft.purchase = payload;
			});
		},
		[types.GET_PURCHASE_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseState = STATE_FAIL;
			});
		},
		[types.GET_ALL_PURCHASE_LIST_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseListStates[PURCHASE_STATES.all] = STATE_LOADING;
			});
		},
		[types.GET_COMPLETED_PURCHASE_LIST_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseListStates[PURCHASE_STATES.completed] = STATE_LOADING;
			});
		},
		[types.GET_CANCELED_PURCHASE_LIST_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseListStates[PURCHASE_STATES.canceled] = STATE_LOADING;
			});
		},
		[types.GET_PURCHASE_LIST_DONE](state, { payload }) {
			const { include = PURCHASE_STATES.all, data, pagination } = payload;

			return produce(state, draft => {
				draft.purchaseListStates[include] = STATE_SUCCESS;
				draft.purchaseLists[include] = data;
				draft.purchaseListPaginations[include] = pagination;
			});
		},
		[types.GET_PURCHASE_LIST_FAIL](state, { payload }) {
			const { include = PURCHASE_STATES.all } = payload;

			return produce(state, draft => {
				draft.purchaseListStates[include] = STATE_FAIL;
			});
		},
		[types.GET_PURCHASE_COURSE_LIST_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseListState = STATE_LOADING;
			});
		},
		[types.GET_PURCHASE_COURSE_LIST_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseListState = STATE_SUCCESS;
				draft.purchaseCourseList = payload.data;
				draft.purchaseCourseListPagination = payload.pagination;
			});
		},
		[types.ADD_COURSE_RATING_DONE](state, { payload }) {
			const targetCourseIndex = state.purchaseCourseList?.findIndex(
				course => course.id === payload.course_id,
			);

			if (targetCourseIndex === -1) {
				return state;
			}

			return produce(state, draft => {
				draft.purchaseCourseList[targetCourseIndex].has_been_rated = true;
			});
		},
		[types.GET_PURCHASE_COURSE_LIST_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseListState = STATE_FAIL;
			});
		},
		[types.GET_PURCHASE_COURSE_TEACHER_LIST_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseTeacherListState = STATE_LOADING;
			});
		},
		[types.GET_PURCHASE_COURSE_TEACHER_LIST_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseTeacherListState = STATE_SUCCESS;
				draft.purchaseCourseTeacherList = payload;
			});
		},
		[types.GET_PURCHASE_COURSE_TEACHER_LIST_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.purchaseCourseTeacherListState = STATE_FAIL;
			});
		},
		[types.CANCEL_PURCHASE_ORDER_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.cancelPurchaseOrderStates[payload.purchase_id] = STATE_LOADING;
			});
		},
		[types.CANCEL_PURCHASE_ORDER_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.cancelPurchaseOrderStates[payload.purchase_id] = STATE_SUCCESS;
			});
		},
		[types.CANCEL_PURCHASE_ORDER_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.cancelPurchaseOrderStates[payload.purchase_id] = STATE_FAIL;
			});
		},
		[types.BUY_THE_FREE_COURSE_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.buyTheFreeCourseStates[payload.product_id] = STATE_LOADING;
			});
		},
		[types.BUY_THE_FREE_COURSE_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.buyTheFreeCourseStates[payload.product_id] = STATE_SUCCESS;
			});
		},
		[types.BUY_THE_FREE_COURSE_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.buyTheFreeCourseStates[payload.product_id] = STATE_FAIL;
			});
		},
		[types.BUY_THE_FREE_COURSE_CANCEL](state, { payload }) {
			return produce(state, draft => {
				draft.buyTheFreeCourseStates[payload.product_id] = STATE_IDLE;
			});
		},
		// purchased event tickets
		[types.GET_PURCHASE_EVENTS_CALL](state) {
			return update(state, {
				purchaseEventsState: { $set: STATE_LOADING },
			});
		},
		[types.GET_PURCHASE_EVENTS_DONE](state, { payload: { response } }) {
			return update(state, {
				purchaseEvents: { $set: response },
				purchaseEventsState: { $set: STATE_SUCCESS },
			});
		},
		[types.GET_PURCHASE_EVENTS_FAIL](state) {
			return update(state, {
				purchaseEventsState: { $set: STATE_FAIL },
			});
		},
		[types.GET_PURCHASE_WEBINARS_CALL](state) {
			return update(state, {
				purchaseWebinarsState: { $set: STATE_LOADING },
			});
		},
		[types.GET_PURCHASE_WEBINARS_DONE](state, { payload: { response } }) {
			return update(state, {
				purchaseWebinars: { $set: response },
				purchaseWebinarsState: { $set: STATE_SUCCESS },
			});
		},
		[types.GET_PURCHASE_WEBINARS_FAIL](state) {
			return update(state, {
				purchaseWebinarsState: { $set: STATE_FAIL },
			});
		},
	},
	initialState,
);
