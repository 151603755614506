import api from '@src/api';

export const getEvents = payload => api.get('offline-events', payload, {
	ssrHeaders: payload.ssrHeaders,
});

export const getEvent = ({ eventShortLink, ssrHeaders }: {
	eventShortLink: string;
	ssrHeaders: {
		[x:string]: string;
	};
}): any => api.get(`offline-events/${eventShortLink}`, {}, { ssrHeaders });

export const getTags = payload => api.get('offline-event-tags/', payload, {
	ssrHeaders: payload.ssrHeaders,
});

export const eventInspectorVerify = payload => api.get(`offline-events/${payload.eventId}/inspector/verify`, null, {
	customHeader: { Authorization: `Basic ${payload.code}` },
});

export const eventRegisterFormCheckIn = payload => api.post(`register-forms/${payload.formId}/check-in`,
	payload.inspectorFields, { customHeader: { Authorization: `Basic ${payload.code}` } });

export const deleteEventRegisterFormCheckIn = payload => (
	// eslint-disable-next-line max-len
	api['delete'](`register-forms/${payload.formId}/check-in/${payload.checkInId}`, null, { customHeader: { Authorization: `Basic ${payload.code}` } })
);

export const revertDeleteEventRegisterFormCheckIn = payload => (
	// eslint-disable-next-line max-len
	api.put(`register-forms/${payload.formId}/check-in/${payload.checkInId}/revert-cancel`, null, { customHeader: { Authorization: `Basic ${payload.code}` } })
);

export const getEventRegisterForm = payload => api.get(`register-forms/${payload.formId}/front-desk`,
	payload, { customHeader: { Authorization: `Basic ${payload.code || ''}` } });

export const postEventRegisterFormNotifySetting = payload => (
	api.post(`register-forms/${payload.formId}/notify-setting`, payload)
);

export const getRegisterFormStatistics = (payload) => (
	// eslint-disable-next-line max-len
	api.get(`offline-events/${payload.shortLink}/slots/${payload.slotId}/statistics`, {}, { ssrHeaders: payload.ssrHeaders })
);

export const getRegisterFormCheckInList = (payload) => (
	// eslint-disable-next-line max-len
	api.get(`offline-events/${payload.shortLink}/slots/${payload.slotId}/front-desk/register-forms/`, payload, { ssrHeaders: payload.ssrHeaders, hasPagination: true })
);
