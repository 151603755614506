import {
	handleActions,
} from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';

import * as types from '@src/store/types';
import { STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE, STATE_NOT_FOUND } from '@src/const/common';

const initialState = {
	coursesView: null,
	coursesViewState: STATE_IDLE,
	currentCourse: null,
	currentCourseState: STATE_IDLE,
	currentCourseGeneralState: STATE_IDLE,
	currentCourseRefetchState: STATE_LOADING,
	currentCourseProgressState: STATE_IDLE,
	currentCourseProgress: null,

	courseRatings: [],
	courseRatingsById: {},
	getCourseRatingStates: {},
	addCourseRatingStates: {},
	editCourseRatingStates: {},
	propagandaReviews: [],
	propagandaReviewsState: [],
	certificate: {},
	getCertificateState: {},
	createCertificateState: {},
	coursesViewByCourseIdMap: {},
	coursesViewByCourseIdMapState: {},
};

export default handleActions(
	{
		[types.GET_COURSE_VIEW_CALL](state) {
			return update(state, {
				coursesViewState: { $set: STATE_LOADING },
			});
		},
		[types.GET_COURSE_VIEW_DONE](state, { payload }) {
			const { response: { data } } = payload;
			return update(state, {
				coursesView: { $set: data.map(course => ({ ...course, type: 'course' })) },
				coursesViewState: { $set: STATE_SUCCESS },
			});
		},
		[types.GET_CURRENT_COURSE_CALL](state, { payload }) {
			const { courseId } = payload;
			return update(state, {
				currentCourseState: { $set: STATE_LOADING },
				coursesViewByCourseIdMapState: { $merge: {
					[courseId]: STATE_LOADING,
				} },
			});
		},
		[types.GET_CURRENT_COURSE_GENERAL_CALL](state) {
			return update(state, {
				currentCourseGeneralState: { $set: STATE_LOADING },
			});
		},
		[types.GET_CURRENT_COURSE_CALL_UPDATE](state, { payload }) {
			return produce(state, draft => {
				draft.currentCourseState = STATE_LOADING;
				draft.currentCourseRefetchState = STATE_LOADING;
			});
		},
		[types.GET_CURRENT_COURSE_DONE](state, { payload }) {
			const { response, courseId } = payload;
			return update(state, {
				currentCourseState: { $set: STATE_SUCCESS },
				currentCourseRefetchState: { $set: STATE_IDLE },
				currentCourse: { $set: response },
				coursesViewByCourseIdMap: { $merge: {
					[courseId]: response,
				} },
				coursesViewByCourseIdMapState: { $merge: {
					[courseId]: STATE_SUCCESS,
				} },
			});
		},
		[types.GET_CURRENT_COURSE_GENERAL_DONE](state, { payload }) {
			const { response } = payload;
			return update(state, {
				currentCourseGeneralState: { $set: STATE_SUCCESS },
				currentCourse: { $set: response },
			});
		},
		[types.CLEAN_CURRENT_COURSE_CALL](state) {
			return produce(state, draft => {
				draft.currentCourseState = STATE_IDLE;
				draft.currentCourseGeneralState = STATE_IDLE;
				draft.currentCourse = null;
			});
		},
		[types.ADD_COURSE_HOMEWORK_DONE](state, { payload }) {
			const targetUnitId = payload.data.unit_id;
			const currentChapterIndex = state.currentCourse?.chapters?.findIndex(
				chapter => {
					const currentUnitIndex = chapter?.units?.findIndex(
						unit => unit.id === targetUnitId,
					);

					return currentUnitIndex >= 0;
				},
			);

			const currentUnitIndex = state.currentCourse?.chapters?.[
				currentChapterIndex
			]?.units?.findIndex(unit => unit.id === targetUnitId);

			if (currentChapterIndex >= 0 && currentUnitIndex >= 0) {
				return produce(state, draft => {
					draft.currentCourse.chapters[currentChapterIndex].units[
						currentUnitIndex
					].submitted = true;
				});
			}

			return state;
		},
		// 這邊應該要跳個訊息
		[types.GET_CURRENT_COURSE_FAIL](state, { payload }) {
			const { courseId } = payload;
			return update(state, {
				currentCourseState: { $set: STATE_FAIL },
				currentCourse: { $set: null },
				coursesViewByCourseIdMap: { $merge: {
					[courseId]: null,
				} },
				coursesViewByCourseIdMapState: { $merge: {
					[courseId]: STATE_FAIL,
				} },
			});
		},
		[types.GET_CURRENT_COURSE_GENERAL_FAIL](state) {
			return update(state, {
				currentCourseGeneralState: { $set: STATE_FAIL },
				currentCourse: { $set: null },
			});
		},
		[types.GET_COURSE_VIEW_FAIL](state) {
			return update(state, {
				coursesView: { $set: null },
				coursesViewState: { $set: STATE_FAIL },
			});
		},

		[types.GET_CURRENT_COURSE_PROGRESS_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.currentCourseProgressState = STATE_LOADING;
			});
		},
		[types.GET_CURRENT_COURSE_PROGRESS_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.currentCourseProgressState = STATE_SUCCESS;
				draft.currentCourseProgress = payload;
			});
		},
		[types.GET_CURRENT_COURSE_PROGRESS_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.currentCourseProgressState = STATE_FAIL;
			});
		},
		[types.UPDATE_COURSE_UNIT_PROGRESS_DONE](state, { payload }) {
			const chapterIndex = state.currentCourseProgress?.findIndex(
				chapterProgress => {
					const targetIndex = chapterProgress.progress.findIndex(
						progress => progress.id === payload.payload.unitId,
					);
					return targetIndex !== -1;
				},
			);

			const unitIndex = state.currentCourseProgress?.[chapterIndex]?.progress?.findIndex(
				progress => progress.id === payload.payload.unitId,
			);

			if (chapterIndex !== -1 && unitIndex !== -1) {
				return produce(state, draft => {
					draft.currentCourseProgress[chapterIndex].progress[
						unitIndex
					].progress = payload.res;
				});
			}
		},
		[types.GET_COURSE_RATING_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseRatingStates[payload.course_id] = STATE_LOADING;
			});
		},
		[types.GET_COURSE_RATING_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseRatingStates[payload.course_id] = STATE_SUCCESS;
				draft.courseRatings = payload.response;
				draft.courseRatingsById[payload.course_id] = payload.response;
				draft.addCourseRatingStates[payload.course_id] = STATE_IDLE;
			});
		},
		[types.GET_COURSE_RATING_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseRatingStates[payload.course_id] = STATE_FAIL;
			});
		},
		[types.ADD_COURSE_RATING_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseRatingStates[payload.course_id] = STATE_LOADING;
			});
		},
		[types.ADD_COURSE_RATING_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseRatingStates[payload.course_id] = STATE_SUCCESS;
			});
		},
		[types.ADD_COURSE_RATING_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseRatingStates[payload.course_id] = STATE_FAIL;
			});
		},
		[types.EDIT_COURSE_RATING_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.editCourseRatingStates[payload.course_id] = STATE_LOADING;
			});
		},
		[types.EDIT_COURSE_RATING_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.editCourseRatingStates[payload.course_id] = STATE_SUCCESS;
			});
		},
		[types.EDIT_COURSE_RATING_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.editCourseRatingStates[payload.course_id] = STATE_FAIL;
			});
		},
		[types.GET_PROPAGANDA_REVIEW_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.propagandaReviewsState[payload.courseId] = STATE_LOADING;
			});
		},
		[types.GET_PROPAGANDA_REVIEW_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.propagandaReviews[payload.courseId] = payload.response;
				draft.propagandaReviewsState[payload.courseId] = STATE_SUCCESS;
			});
		},
		[types.GET_PROPAGANDA_REVIEW_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.propagandaReviewsState[payload.courseId] = STATE_FAIL;
			});
		},
		// get course certificate call
		[types.GET_COURSE_CERTIFICATE_CALL](state, { payload }) {
			return update(state, {
				certificate: {
					[payload.courseId]: { $set: null },
				},
				getCertificateState: {
					[payload.courseId]: { $set: STATE_LOADING },
				},
			});
		},
		[types.GET_COURSE_CERTIFICATE_DONE](state, { payload }) {
			return update(state, {
				certificate: {
					[payload.courseId]: { $set: payload.response },
				},
				getCertificateState: {
					[payload.courseId]: { $set: STATE_SUCCESS },
				},
			});
		},
		[types.GET_COURSE_CERTIFICATE_FAIL](state, { payload }) {
			const isCertificateNotFound = payload.error.response?.status === 404;
			return update(state, {
				getCertificateState: {
					[payload.courseId]: { $set: isCertificateNotFound ? STATE_NOT_FOUND : STATE_SUCCESS },
				},
			});
		},
		// create course certificate call
		[types.CREATE_COURSE_CERTIFICATE_CALL](state, { payload }) {
			return update(state, {
				createCertificateState: {
					[payload.courseId]: { $set: STATE_LOADING },
				},
			});
		},
		[types.CREATE_COURSE_CERTIFICATE_DONE](state, { payload }) {
			return update(state, {
				certificate: {
					[payload.courseId]: { $set: payload.response },
				},
				createCertificateState: {
					[payload.courseId]: { $set: STATE_SUCCESS },
				},
				getCertificateState: {
					[payload.courseId]: { $set: STATE_SUCCESS },
				},
			});
		},
		[types.CREATE_COURSE_CERTIFICATE_FAIL](state, { payload }) {
			return update(state, {
				createCertificateState: {
					[payload.courseId]: { $set: STATE_FAIL },
				},
			});
		},
	},
	initialState,
);
