import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_OVERTIME_APPLICATIONS_CALL,
	GET_OVERTIME_APPLICATIONS_DONE,
	GET_OVERTIME_APPLICATIONS_FAIL,
	POST_OVERTIME_APPLICATION_CALL,
	POST_OVERTIME_APPLICATION_DONE,
	POST_OVERTIME_APPLICATION_FAIL,
	GET_OVERTIME_APPLICATION_INFO_CALL,
	GET_OVERTIME_APPLICATION_INFO_DONE,
	GET_OVERTIME_APPLICATION_INFO_FAIL,
	GET_OVERTIME_APPLICATION_DETAIL_CALL,
	GET_OVERTIME_APPLICATION_DETAIL_DONE,
	GET_OVERTIME_APPLICATION_DETAIL_FAIL,
	REST_OVERTIME_APPLICATION_DETAIL_CALL,
	POST_OVERTIME_APPLICATION_APPROVE_CALL,
	POST_OVERTIME_APPLICATION_APPROVE_DONE,
	POST_OVERTIME_APPLICATION_APPROVE_FAIL,
	GET_OVERTIME_APPLICATION_CONFIRM_CALL,
	GET_OVERTIME_APPLICATION_CONFIRM_DONE,
	GET_OVERTIME_APPLICATION_CONFIRM_FAIL,
	POST_OVERTIME_APPLICATION_CONFIRM_CALL,
	POST_OVERTIME_APPLICATION_CONFIRM_DONE,
	POST_OVERTIME_APPLICATION_CONFIRM_FAIL,
	POST_OVERTIME_APPLICATION_WITHDRAW_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_DONE,
	POST_OVERTIME_APPLICATION_WITHDRAW_FAIL,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	overtimeApplications: [],
	getOvertimeApplicationsSideEffect: defaultSideEffectState,
	postOvertimeApplication: {},
	postOvertimeApplicationSideEffect: defaultSideEffectState,
	overtimeApplicationInfo: {},
	getOvertimeApplicationInfoSideEffect: defaultSideEffectState,
	overtimeApplicationDetail: {},
	getOvertimeApplicationDetailSideEffect: defaultSideEffectState,
	postOvertimeApplicationApprove: {},
	postOvertimeApplicationApproveSideEffect: defaultSideEffectState,
	overtimeApplicationConfirm: {},
	getOvertimeApplicationConfirmSideEffect: defaultSideEffectState,
	postOvertimeApplicationConfirm: {},
	postOvertimeApplicationConfirmSideEffect: defaultSideEffectState,
	postOvertimeApplicationWithdraw: {},
	postOvertimeApplicationWithdrawSideEffect: defaultSideEffectState,
	getOvertimeApplicationsApproverView: [],
	getOvertimeApplicationsApproverViewSideEffect: defaultSideEffectState,
	postOvertimeApplicationWithdrawApproval: {},
	postOvertimeApplicationWithdrawApprovalSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_OVERTIME_APPLICATIONS_CALL](state) {
		return update(state, {
			getOvertimeApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			overtimeApplications: { $set: res },
			getOvertimeApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			getOvertimeApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_OVERTIME_APPLICATION_CALL](state) {
		return update(state, {
			postOvertimeApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_DONE](state, { payload: { res } }) {
		return update(state, {
			postOvertimeApplication: { $set: res },
			postOvertimeApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_FAIL](state, { payload }) {
		return update(state, {
			postOvertimeApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_OVERTIME_APPLICATION_INFO_CALL](state) {
		return update(state, {
			getOvertimeApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_INFO_DONE](state, { payload: { res } }) {
		return update(state, {
			overtimeApplicationInfo: { $set: res },
			getOvertimeApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_INFO_FAIL](state, { payload }) {
		return update(state, {
			getOvertimeApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_OVERTIME_APPLICATION_DETAIL_CALL](state) {
		return update(state, {
			getOvertimeApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			overtimeApplicationDetail: { $set: res },
			getOvertimeApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getOvertimeApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[REST_OVERTIME_APPLICATION_DETAIL_CALL](state) {
		return update(state, {
			overtimeApplicationDetail: { $set: {} },
		});
	},

	[POST_OVERTIME_APPLICATION_APPROVE_CALL](state) {
		return update(state, {
			postOvertimeApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_APPROVE_DONE](state, { payload: { res } }) {
		return update(state, {
			postOvertimeApplicationApprove: { $set: res },
			postOvertimeApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_APPROVE_FAIL](state, { payload }) {
		return update(state, {
			postOvertimeApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_OVERTIME_APPLICATION_CONFIRM_CALL](state) {
		return update(state, {
			getOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_CONFIRM_DONE](state, { payload: { res } }) {
		return update(state, {
			overtimeApplicationConfirm: { $set: res },
			getOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATION_CONFIRM_FAIL](state, { payload }) {
		return update(state, {
			getOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_OVERTIME_APPLICATION_CONFIRM_CALL](state) {
		return update(state, {
			postOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_CONFIRM_DONE](state, { payload: { res } }) {
		return update(state, {
			postOvertimeApplicationConfirm: { $set: res },
			postOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_CONFIRM_FAIL](state, { payload }) {
		return update(state, {
			postOvertimeApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_OVERTIME_APPLICATION_WITHDRAW_CALL](state) {
		return update(state, {
			postOvertimeApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_WITHDRAW_DONE](state, { payload: { res } }) {
		return update(state, {
			postOvertimeApplicationWithdraw: { $set: res },
			postOvertimeApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_WITHDRAW_FAIL](state, { payload }) {
		return update(state, {
			postOvertimeApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL](state) {
		return update(state, {
			getOvertimeApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE](state, { payload: { res } }) {
		return update(state, {
			getOvertimeApplicationsApproverView: { $set: res },
			getOvertimeApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL](state, { payload }) {
		return update(state, {
			getOvertimeApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL](state) {
		return update(state, {
			postOvertimeApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE](state, { payload: { res } }) {
		return update(state, {
			postOvertimeApplicationWithdrawApproval: { $set: res },
			postOvertimeApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL](state, { payload }) {
		return update(state, {
			postOvertimeApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
