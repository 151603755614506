import { combineActions, handleActions } from 'redux-actions';
import produce, { produceWithPatches } from 'immer';
import update from 'immutability-helper';
import * as types from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	profile: {},
	putUserProfileState: 'IDLE',
	notifications: [],
	notificationsSeening: {},
	addNotificationsData: [],
	bindCreditCard: [],
	rebindCreditCardStatus: false,
	lockActionStatus: false,
	deleteTrialPlanStatus: false,
	changePlanStatus: false,
	clancelPlanStatus: false,
	opinionPlanStatus: false,
	changeFormalState: false,
	invoicesList: [],
	currentPlan: {},
	unbindOtherUsernameStates: {},
	refetchUserProfileState: 'IDLE',
	putProfilePasswordState: 'IDLE',
	putUserProfilePictureState: 'IDLE',
	bindUserEmailState: 'IDLE',
	learningCenter: {},
	learningCenterSideEffect: defaultSideEffectState,
	learningCenterAssignCourse: {
		complete: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		incomplete: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
	},
	learningCenterAssignCourseSideEffect: defaultSideEffectState,
	mineLearningPath: {
		complete: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		incomplete: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
	},
	mineLearningPathSideEffect: defaultSideEffectState,
	joinedCoursesStatistics: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	joinedCoursesStatisticsSideEffect: defaultSideEffectState,
	joinedLearningPathStatistics: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	joinedLearningPathStatisticsSideEffect: defaultSideEffectState,
	userLearningStatistics: {},
	userLearningStatisticsSideEffect: defaultSideEffectState,
	getLearningRecordsMine: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getLearningRecordsMineSideEffect: defaultSideEffectState,
	learningLevel: 1,
	getLearningLevelSideEffect: defaultSideEffectState,
};

export default handleActions(
	{
		[types.SET_USER](state, { payload: { profile } }) {
			return produce(state, (draft) => {
				const { first_name, last_name } = profile;

				draft.profile = profile;
				draft.profile.name = `${last_name} ${first_name}`;
			});
		},
		[types.PUT_USER_PROFILE_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfileState = 'LOADING';
			});
		},
		[types.PUT_USER_PROFILE_DONE](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfileState = 'SUCCESSS';
			});
		},
		[types.PUT_USER_PROFILE_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfileState = 'FAIL';
			});
		},
		[types.GET_NOTIFICATIONS_DONE](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.notifications = payload;
			});
		},
		[types.GET_NOTIFICATIONS_SEENING](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.notificationsSeening = payload;
			});
		},
		[types.GET_NOTIFICATIONS_ALL_HAVEREAD](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.notificationsSeening = payload;
			});
		},
		[types.GET_NOTIFICATIONS_IO_DONE](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.addNotificationsData = payload;
			});
		},
		[types.GET_BIND_CREDITCARD_INFO_DONE](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.bindCreditCard = payload;
			});
		},
		[types.REBIND_CREDITCARD_INFO_DONE](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.rebindCreditCardStatus = payload;
			});
		},
		[types.GET_INVOICE_LIST_DOEN](state, { payload = [] }) {
			return produce(state, (draft) => {
				draft.invoicesList = payload;
			});
		},
		[types.LOCK_ACTION_CREDITCARD_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.lockActionStatus = payload;
			});
		},
		[types.GET_USER_CURRENT_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.currentPlan = payload;
			});
		},
		[types.DELETE_USER_TRIAL_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.deleteTrialPlanStatus = payload;
			});
		},
		[types.PUT_USER_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.changePlanStatus = payload;
			});
		},
		[types.CANCEL_USER_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.clancelPlanStatus = payload;
			});
		},
		[types.OPINION_USER_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.opinionPlanStatus = payload;
			});
		},
		[types.CHANGE_USER_FORMAL_PLAN_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.changeFormalState = payload;
			});
		},
		[types.UNBIND_OTHER_USERNAME_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.unbindOtherUsernameStates = {
					...draft.unbindOtherUsernameStates,
					[payload.type]: 'LOADING',
				};
			});
		},
		[types.UNBIND_OTHER_USERNAME_DONE](state, { payload }) {
			return produce(state, (draft) => {
				draft.unbindOtherUsernameStates = {
					...draft.unbindOtherUsernameStates,
					[payload.type]: 'SUCCESS',
				};
			});
		},
		[types.UNBIND_OTHER_USERNAME_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.unbindOtherUsernameStates = {
					...draft.unbindOtherUsernameStates,
					[payload.type]: 'ERROR',
				};
			});
		},
		[types.GET_USER_PROFILE_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.refetchUserProfileState = 'LOADING';
			});
		},
		[types.GET_USER_PROFILE_DONE](state, { payload }) {
			return produce(state, (draft) => {
				draft.refetchUserProfileState = 'SUCCESS';
			});
		},
		[types.GET_USER_PROFILE_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.refetchUserProfileState = 'ERROR';
			});
		},
		[types.PUT_PROFILE_PASSWORD_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putProfilePasswordState = 'LOADING';
			});
		},
		[types.PUT_PROFILE_PASSWORD_DOEN](state, { payload }) {
			return produce(state, (draft) => {
				draft.putProfilePasswordState = 'SUCCESS';
			});
		},
		[types.PUT_PROFILE_PASSWORD_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putProfilePasswordState = 'FAIL';
			});
		},
		[types.PUT_USER_PROFILE_PICTURE_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfilePictureState = 'LOADING';
			});
		},
		[types.PUT_USER_PROFILE_PICTURE_DONE](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfilePictureState = 'SUCCESS';
			});
		},
		[types.PUT_USER_PROFILE_PICTURE_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfilePictureState = 'FAIL';
			});
		},
		[types.PUT_USER_PROFILE_PICTURE_RESET](state, { payload }) {
			return produce(state, (draft) => {
				draft.putUserProfilePictureState = 'IDLE';
			});
		},
		[types.BIND_USER_EMAIL_CALL](state, { payload }) {
			return produce(state, (draft) => {
				draft.bindUserEmailState = 'LOADING';
			});
		},
		[types.BIND_USER_EMAIL_DONE](state, { payload }) {
			return produce(state, (draft) => {
				draft.bindUserEmailState = 'SUCCESS';
			});
		},
		[types.BIND_USER_EMAIL_FAIL](state, { payload }) {
			return produce(state, (draft) => {
				draft.bindUserEmailState = 'FAIL';
			});
		},
		[types.GET_LEARNING_CENTER_CALL](state) {
			return update(state, {
				learningCenterSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_DONE](state, { payload }) {
			return update(state, {
				learningCenter: { $set: payload },
				learningCenterSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_FAIL](state, { payload }) {
			return update(state, {
				learningCenterSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_ASSIGN_COURSE_CALL](state) {
			return update(state, {
				learningCenterAssignCourseSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_ASSIGN_COURSE_DONE](state, { payload }) {
			const { res, completed } = payload;
			return update(state, {
				...completed ? {
					learningCenterAssignCourse: {
						complete: { $set: res },
					},
				} : {
					learningCenterAssignCourse: {
						incomplete: { $set: res },
					},
				},
				learningCenterAssignCourseSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_ASSIGN_COURSE_FAIL](state, { payload }) {
			return update(state, {
				learningCenterAssignCourseSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_LEARNING_PATH_CALL](state) {
			return update(state, {
				mineLearningPathSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_LEARNING_PATH_DONE](state, { payload }) {
			const { res, status } = payload;
			return update(state, {
				...status === 'completed' ? {
					mineLearningPath: {
						complete: { $set: res },
					},
				} : {
					mineLearningPath: {
						incomplete: { $set: res },
					},
				},
				mineLearningPathSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_LEARNING_CENTER_LEARNING_PATH_FAIL](state, { payload }) {
			return update(state, {
				mineLearningPathSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_USER_JOINED_COURSES_STATISTICS_CALL](state) {
			return update(state, {
				joinedCoursesStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_USER_JOINED_COURSES_STATISTICS_DONE](state, { payload }) {
			return update(state, {
				joinedCoursesStatistics: { $set: payload },
				joinedCoursesStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_USER_JOINED_COURSES_STATISTICS_FAIL](state, { payload }) {
			return update(state, {
				joinedCoursesStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_CALL](state) {
			return update(state, {
				joinedLearningPathStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_DONE](state, { payload }) {
			return update(state, {
				joinedLearningPathStatistics: { $set: payload },
				joinedLearningPathStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_FAIL](state, { payload }) {
			return update(state, {
				joinedLearningPathStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.RESET_USER_LEARNING_PATH_STATISTICS_CALL](state) {
			return update(state, {
				userLearningStatisticsSideEffect: { $set: defaultSideEffectState },
			});
		},
		[types.GET_USER_LEARNING_PATH_STATISTICS_CALL](state) {
			return update(state, {
				userLearningStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_USER_LEARNING_PATH_STATISTICS_DONE](state, { payload }) {
			return update(state, {
				userLearningStatistics: { $set: payload },
				userLearningStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_USER_LEARNING_PATH_STATISTICS_FAIL](state, { payload }) {
			return update(state, {
				userLearningStatisticsSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_LEARNING_RECORDS_MINE_CALL](state) {
			return update(state, {
				getLearningRecordsMineSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_LEARNING_RECORDS_MINE_DONE](state, { payload: { res } }) {
			return update(state, {
				getLearningRecordsMine: { $set: res },
				getLearningRecordsMineSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_LEARNING_RECORDS_MINE_FAIL](state, { payload }) {
			return update(state, {
				getLearningRecordsMineSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
		[types.GET_LEARNING_LEVEL_CALL](state) {
			return update(state, {
				getLearningLevelSideEffect: { $set: {
					...defaultSideEffectState,
					isLoading: true,
				} },
			});
		},
		[types.GET_LEARNING_LEVEL_DONE](state, { payload }) {
			return update(state, {
				learningLevel: { $set: payload },
				getLearningLevelSideEffect: { $set: {
					...defaultSideEffectState,
					isLoaded: true,
				} },
			});
		},
		[types.GET_LEARNING_LEVEL_FAIL](state, { payload }) {
			return update(state, {
				getLearningLevelSideEffect: { $set: {
					...defaultSideEffectState,
					error: payload.error,
				} },
			});
		},
	},
	initialState,
);
