// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

interface IPostKnowledgeBasePage {
	id?: string;
	content?: string;
	cover_link_id?: string;
	space_id?: string;
	title?: string;
}

export const getKnowledgeBasePages = (payload: {
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('knowledge-base/pages/', payload, { hasPagination: true })
);

export const postKnowledgeBasePage = (payload: IPostKnowledgeBasePage): Observable<Action> => (
	api.post('knowledge-base/pages/', payload)
);

export const postKnowledgeBasePagesOrder = (payload: string[]): Observable<Action> => (
	api.post('knowledge-base/pages/order', payload)
);

export const deleteKnowledgeBasePage = (payload: { id: string }): Observable<Action> => (
	api['delete'](`knowledge-base/pages/${payload.id}`)
);

export const getKnowledgeBasePageDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`knowledge-base/pages/${payload.id}`)
);

export const getKnowledgeBasePageSEO = (): Observable<Action> => (
	api.get('knowledge-base-seo/')
);

export const patchKnowledgeBasePage = (payload: IPostKnowledgeBasePage): Observable<Action> => (
	api.patch(`knowledge-base/pages/${payload.id}`, payload)
);

export const postKnowledgeBasePageRead = (payload: { id: string }): Observable<Action> => (
	api.post(`knowledge-base/pages/${payload.id}/read`)
);
