import { handleActions } from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';

import { STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE } from '@src/const/common';
import {
	GET_EVENT_CALL,
	GET_EVENT_DONE,
	GET_EVENT_FAIL,
	CLEAN_EVENT_CALL,
	GET_EVENTS_CALL,
	GET_EVENTS_DONE,
	GET_EVENTS_FAIL,
	SET_CURRENT_PRICE,
	SET_EVENT_COUNTDOWN_FOR_SALE,
	GET_TAGS_CALL,
	GET_TAGS_DONE,
	GET_TAGS_FAIL,
	EVENT_INSPECTOR_VERIFY_CALL,
	EVENT_INSPECTOR_VERIFY_DONE,
	EVENT_INSPECTOR_VERIFY_FAIL,
	EVENT_REGISTER_FORM_CHECK_IN_CALL,
	EVENT_REGISTER_FORM_CHECK_IN_DONE,
	EVENT_REGISTER_FORM_CHECK_IN_FAIL,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL,
	GET_EVENT_REGISTER_FORM_CALL,
	GET_EVENT_REGISTER_FORM_DONE,
	GET_EVENT_REGISTER_FORM_FAIL,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL,
	GET_REGISTER_FORM_STATISTICS_CALL,
	GET_REGISTER_FORM_STATISTICS_DONE,
	GET_REGISTER_FORM_STATISTICS_FAIL,
	GET_REGISTER_FORM_CHECK_IN_LIST_CALL,
	GET_REGISTER_FORM_CHECK_IN_LIST_DONE,
	GET_REGISTER_FORM_CHECK_IN_LIST_FAIL,
} from '@src/store/types/event';
import { formatTimeFloor } from '@src/utils/time';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	events: [],
	getEventsState: STATE_IDLE,
	event: {},
	getEventState: STATE_IDLE,
	currentPrice: 0,
	eventsMap: {}, // by short_link
	eventsMapState: {}, // by short_link
	countDownForSale: formatTimeFloor(0),
	tags: [],
	getTagsState: STATE_IDLE,
	eventRegisterForm: {},
	eventRegisterFormSideEffect: defaultSideEffectState,
	eventInspectorVerifySideEffect: defaultSideEffectState,
	eventRegisterFormCheckInSideEffect: defaultSideEffectState,
	deleteEventRegisterFormCheckInSideEffect: defaultSideEffectState,
	postEventRegisterFormNotifySettingSideEffect: defaultSideEffectState,
	registerFormStatistics: {},
	getRegisterFormStatisticsSideEffect: defaultSideEffectState,
	checkIns: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getRegisterFormCheckInListSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_EVENT_CALL](state, { payload }) {
		const { eventShortLink } = payload;
		return update(state, {
			getEventState: { $set: STATE_LOADING },
			eventsMapState: { $merge: {
				[eventShortLink]: STATE_LOADING,
			} },
		});
	},
	[GET_EVENT_DONE](state, { payload }) {
		const { eventShortLink, response } = payload;
		return update(state, {
			getEventState: { $set: STATE_SUCCESS },
			eventsMapState: { $merge: {
				[eventShortLink]: STATE_SUCCESS,
			} },
			eventsMap: { $merge: {
				[eventShortLink]: response,
			} },
			event: { $set: response },
		});
	},
	[GET_EVENT_FAIL](state, { payload }) {
		const { eventShortLink } = payload;
		return update(state, {
			getEventState: { $set: STATE_FAIL },
			eventsMapState: { $merge: {
				[eventShortLink]: STATE_FAIL,
			} },
		});
	},
	[CLEAN_EVENT_CALL](state) {
		return produce(state, draft => {
			draft.getEventState = STATE_IDLE;
			draft.event = {};
		});
	},
	[GET_EVENTS_CALL](state) {
		return produce(state, draft => {
			draft.getEventsState = STATE_LOADING;
		});
	},
	[GET_EVENTS_DONE](state, { payload }) {
		return produce(state, draft => {
			draft.getEventsState = STATE_SUCCESS;
			draft.events = payload.response;
		});
	},
	[GET_EVENTS_FAIL](state) {
		return produce(state, draft => {
			draft.getEventsState = STATE_FAIL;
		});
	},
	[SET_CURRENT_PRICE](state, { payload }) {
		return produce(state, draft => {
			draft.currentPrice = payload.currentPrice;
		});
	},
	[SET_EVENT_COUNTDOWN_FOR_SALE](state, { payload }) {
		return update(state, {
			countDownForSale: { $set: payload.countdown },
		});
	},
	[GET_TAGS_CALL](state) {
		return update(state, {
			getTagsState: { $set: STATE_LOADING },
		});
	},
	[GET_TAGS_DONE](state, { payload: { response } }) {
		return update(state, {
			tags: { $set: response },
			getTagsState: { $set: STATE_SUCCESS },
		});
	},
	[GET_TAGS_FAIL](state) {
		return update(state, {
			getTagsState: { $set: STATE_FAIL },
		});
	},
	[GET_EVENT_REGISTER_FORM_CALL](state) {
		return update(state, {
			eventRegisterFormSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_EVENT_REGISTER_FORM_DONE](state, { payload: { response } }) {
		return update(state, {
			eventRegisterForm: { $set: response },
			eventRegisterFormSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_EVENT_REGISTER_FORM_FAIL](state, { payload }) {
		return update(state, {
			eventRegisterFormSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[EVENT_INSPECTOR_VERIFY_CALL](state) {
		return update(state, {
			eventInspectorVerifySideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[EVENT_INSPECTOR_VERIFY_DONE](state) {
		return update(state, {
			eventInspectorVerifySideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[EVENT_INSPECTOR_VERIFY_FAIL](state, { payload }) {
		return update(state, {
			eventInspectorVerifySideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[EVENT_REGISTER_FORM_CHECK_IN_CALL](state) {
		return update(state, {
			eventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[EVENT_REGISTER_FORM_CHECK_IN_DONE](state) {
		return update(state, {
			eventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[EVENT_REGISTER_FORM_CHECK_IN_FAIL](state, { payload }) {
		return update(state, {
			eventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL](state) {
		return update(state, {
			deleteEventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE](state) {
		return update(state, {
			deleteEventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL](state, { payload }) {
		return update(state, {
			deleteEventRegisterFormCheckInSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL](state) {
		return update(state, {
			postEventRegisterFormNotifySettingSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE](state) {
		return update(state, {
			postEventRegisterFormNotifySettingSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL](state, { payload }) {
		return update(state, {
			postEventRegisterFormNotifySettingSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[GET_REGISTER_FORM_STATISTICS_CALL](state) {
		return update(state, {
			getRegisterFormStatisticsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_REGISTER_FORM_STATISTICS_DONE](state, { payload: { response } }) {
		return update(state, {
			registerFormStatistics: { $set: response },
			getRegisterFormStatisticsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_REGISTER_FORM_STATISTICS_FAIL](state, { payload }) {
		return update(state, {
			getRegisterFormStatisticsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[GET_REGISTER_FORM_CHECK_IN_LIST_CALL](state) {
		return update(state, {
			getRegisterFormCheckInListSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_REGISTER_FORM_CHECK_IN_LIST_DONE](state, { payload: { response } }) {
		return update(state, {
			checkIns: { $set: response },
			getRegisterFormCheckInListSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_REGISTER_FORM_CHECK_IN_LIST_FAIL](state, { payload }) {
		return update(state, {
			getRegisterFormCheckInListSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
