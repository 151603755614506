import { handleActions } from 'redux-actions';
import produce from 'immer';

import * as types from '@src/store/types';

const initialState = {
  getAnnouncementsStates: {},
  announcements: [],
};

export default handleActions(
  {
    [types.GET_COURSE_ANNOUNCEMENTS_CALL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getAnnouncementsStates[payload.course_id] = 'LOADING';
      });
    },
    [types.GET_COURSE_ANNOUNCEMENTS_DONE](state, { payload }) {
      return produce(state, (draft) => {
        draft.getAnnouncementsStates[payload.course_id] = 'SUCCESS';
        draft.announcements = payload.response;
      });
    },
    [types.GET_COURSE_ANNOUNCEMENTS_FAIL](state, { payload }) {
      return produce(state, (draft) => {
        draft.getAnnouncementsStates[payload.course_id] = 'FAIL';
      });
    },
  },
  initialState
);
