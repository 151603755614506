import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, concatMap, catchError } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';

import { GET_WEBINAR_CALL, GET_WEBINARS_CALL } from '@src/store/types/webinar';
import { getWebinarDone, getWebinarFail, getWebinarsDone, getWebinarsFail } from '@src/store/actions/webinar';
import { getWebinar, getWebinars } from '@src/services/webinar';

export const getWebinarEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_WEBINAR_CALL),
		concatMap(({ payload }) => (
			fromPromise(getWebinar(payload)).pipe(
				switchMap(response => of(getWebinarDone({ webinarShortLink: payload.webinarShortLink, response }))),
				catchError(() => of(getWebinarFail({ webinarShortLink: payload.webinarShortLink }))),
			)
		)),
	)
);

export const getWebinarsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_WEBINARS_CALL),
		switchMap(({ payload }) => (
			fromPromise(getWebinars(payload)).pipe(
				switchMap(response => of(getWebinarsDone({ response }))),
				catchError(() => of(getWebinarsFail())),
			)
		)),
	)
);
