import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, concatMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_LEAVE_APPLICATIONS_CALL,
	POST_LEAVE_APPLICATION_CALL,
	GET_LEAVE_APPLICATION_INFO_CALL,
	GET_LEAVE_APPLICATION_DETAIL_CALL,
	POST_LEAVE_APPLICATION_APPROVE_CALL,
	GET_LEAVE_APPLICATION_CONFIRM_CALL,
	POST_LEAVE_APPLICATION_CONFIRM_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_CALL,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL,
	DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL,
	GET_AVAILABLE_LEAVE_TYPES_CALL,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL,
	GET_LEAVE_ENTITLEMENT_CALL,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL,
	GET_CUSTOM_LEAVE_TYPE_RULES_CALL,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL,
} from '@src/store/types/leaveApplication';

import {
	getLeaveApplicationsDone,
	getLeaveApplicationsFail,
	postLeaveApplicationDone,
	postLeaveApplicationFail,
	getLeaveApplicationInfoDone,
	getLeaveApplicationInfoFail,
	getLeaveApplicationDetailCall,
	getLeaveApplicationDetailDone,
	getLeaveApplicationDetailFail,
	postLeaveApplicationApproveDone,
	postLeaveApplicationApproveFail,
	getLeaveApplicationConfirmDone,
	getLeaveApplicationConfirmFail,
	postLeaveApplicationConfirmDone,
	postLeaveApplicationConfirmFail,
	postLeaveApplicationWithdrawDone,
	postLeaveApplicationWithdrawFail,
	getLeaveApplicationsApproverViewDone,
	getLeaveApplicationsApproverViewFail,
	postLeaveApplicationWithdrawApprovalDone,
	postLeaveApplicationWithdrawApprovalFail,
	deleteLeaveApplicationAttachmentDone,
	deleteLeaveApplicationAttachmentFail,
	getAvailableLeaveTypesDone,
	getAvailableLeaveTypesFail,
	getLeaveApplicationsTotalMinutesDone,
	getLeaveApplicationsTotalMinutesFail,
	getLeaveEntitlementDone,
	getLeaveEntitlementFail,
	getLeaveEntitlementByLeaveTypeDone,
	getLeaveEntitlementByLeaveTypeFail,
	getLeaveApplicationAttachmentDownloadLinkDone,
	getLeaveApplicationAttachmentDownloadLinkFail,
	getLeaveApplicationsInfoByLeaveTypeDone,
	getLeaveApplicationsInfoByLeaveTypeFail,
	getCustomLeaveTypeRulesDone,
	getCustomLeaveTypeRulesFail,
	postLeaveApplicationGrantNewEntitlementDone,
	postLeaveApplicationGrantNewEntitlementFail,
} from '@src/store/actions/leaveApplication';
import {
	getLeaveApplications,
	postLeaveApplication,
	getLeaveApplicationInfo,
	getLeaveApplicationDetail,
	postLeaveApplicationApprove,
	getLeaveApplicationConfirm,
	postLeaveApplicationConfirm,
	postLeaveApplicationWithdraw,
	getLeaveApplicationsApproverView,
	postLeaveApplicationWithdrawApproval,
	deleteLeaveApplicationAttachment,
	getAvailableLeaveTypes,
	getLeaveApplicationsTotalMinutes,
	getLeaveEntitlement,
	getLeaveEntitlementByLeaveType,
	getLeaveApplicationAttachmentDownloadLink,
	getLeaveApplicationsInfoByLeaveType,
	getCustomLeaveTypeRules,
	postLeaveApplicationsGrantNewEntitlement,
} from '@src/services/leaveApplication';

export const getLeaveApplicationsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATIONS_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplications(payload)).pipe(
			switchMap(res => of(getLeaveApplicationsDone({ res }))),
			catchError(error => of(getLeaveApplicationsFail({ error }))),
		),
	),
);

export const postLeaveApplicationEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplication(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postLeaveApplicationDone({ res }),
				);
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(
					postLeaveApplicationFail({ error }),
				);
			}),
		),
	),
);

export const getLeaveApplicationInfoEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATION_INFO_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationInfo(payload)).pipe(
			switchMap(res => of(getLeaveApplicationInfoDone({ res }))),
			catchError(error => of(getLeaveApplicationInfoFail({ error }))),
		),
	),
);

export const getLeaveApplicationDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATION_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationDetail(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getLeaveApplicationDetailDone({ res }));
			}),
			catchError(error => of(getLeaveApplicationDetailFail({ error }))),
		),
	),
);

export const postLeaveApplicationApproveEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_APPROVE_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplicationApprove(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postLeaveApplicationApproveDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postLeaveApplicationApproveFail({ error }));
			}),
		),
	),
);

export const getLeaveApplicationConfirmEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATION_CONFIRM_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationConfirm(payload)).pipe(
			switchMap(res => of(getLeaveApplicationConfirmDone({ res }))),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(
					getLeaveApplicationConfirmFail({ error }),
				);
			}),
		),
	),
);

export const postLeaveApplicationConfirmEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_CONFIRM_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplicationConfirm(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postLeaveApplicationConfirmDone({ res }));
			}),
			catchError(error => of(postLeaveApplicationConfirmFail({ error }))),
		),
	),
);

export const postLeaveApplicationWithdrawEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_WITHDRAW_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplicationWithdraw(payload)).pipe(
			switchMap(res => [
				getLeaveApplicationDetailCall({ id: payload.id }),
				postLeaveApplicationWithdrawDone({ res }),
			]),
			catchError(error => of(postLeaveApplicationWithdrawFail({ error }))),
		),
	),
);

export const getLeaveApplicationsApproverViewEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationsApproverView(payload)).pipe(
			switchMap(res => of(getLeaveApplicationsApproverViewDone({ res }))),
			catchError(error => of(getLeaveApplicationsApproverViewFail({ error }))),
		),
	),
);

export const postLeaveApplicationWithdrawApprovalEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplicationWithdrawApproval(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postLeaveApplicationWithdrawApprovalDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postLeaveApplicationWithdrawApprovalFail({ error }));
			}),
		),
	),
);

export const deleteLeaveApplicationAttachmentEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL),
	switchMap(
		({ payload }) => fromPromise(deleteLeaveApplicationAttachment(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(deleteLeaveApplicationAttachmentDone({ res }));
			}),
			catchError(error => of(deleteLeaveApplicationAttachmentFail({ error }))),
		),
	),
);

export const getAvailableLeaveTypesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_AVAILABLE_LEAVE_TYPES_CALL),
	switchMap(
		({ payload }) => fromPromise(getAvailableLeaveTypes(payload)).pipe(
			switchMap(res => of(getAvailableLeaveTypesDone({ res }))),
			catchError(error => of(getAvailableLeaveTypesFail({ error }))),
		),
	),
);

export const getLeaveApplicationsTotalMinutesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationsTotalMinutes(payload)).pipe(
			switchMap(res => of(getLeaveApplicationsTotalMinutesDone({ res }))),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(getLeaveApplicationsTotalMinutesFail({ error }));
			}),
		),
	),
);

export const getLeaveEntitlementEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_ENTITLEMENT_CALL),
	switchMap(
		() => fromPromise(getLeaveEntitlement()).pipe(
			switchMap(res => of(getLeaveEntitlementDone({ res }))),
			catchError(error => of(getLeaveEntitlementFail({ error }))),
		),
	),
);

export const getLeaveEntitlementByLeaveTypeEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveEntitlementByLeaveType(payload)).pipe(
			switchMap(res => of(getLeaveEntitlementByLeaveTypeDone({ res }))),
			catchError(error => of(getLeaveEntitlementByLeaveTypeFail({ error }))),
		),
	),
);

export const getLeaveApplicationAttachmentDownloadLinkEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL),
	concatMap(
		({ payload }) => fromPromise(getLeaveApplicationAttachmentDownloadLink(payload)).pipe(
			switchMap(res => of(getLeaveApplicationAttachmentDownloadLinkDone({ res, id: payload.id }))),
			catchError(error => of(getLeaveApplicationAttachmentDownloadLinkFail({ error }))),
		),
	),
);

export const getLeaveApplicationsInfoByLeaveTypeEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL),
	switchMap(
		({ payload }) => fromPromise(getLeaveApplicationsInfoByLeaveType(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(getLeaveApplicationsInfoByLeaveTypeDone({ res, leaveType: payload.leaveType }));
			}),
			catchError(error => of(getLeaveApplicationsInfoByLeaveTypeFail({ error }))),
		),
	),
);

export const getCustomLeaveTypeRulesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_CUSTOM_LEAVE_TYPE_RULES_CALL),
	switchMap(
		() => fromPromise(getCustomLeaveTypeRules()).pipe(
			switchMap(res => of(getCustomLeaveTypeRulesDone({ res }))),
			catchError(error => of(getCustomLeaveTypeRulesFail({ error }))),
		),
	),
);

export const postLeaveApplicationGrantNewEntitlementEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL),
	switchMap(
		({ payload }) => fromPromise(postLeaveApplicationsGrantNewEntitlement(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(postLeaveApplicationGrantNewEntitlementDone({ res }));
			}),
			catchError(error => {
				if (payload.onError) payload.onError(error.response);
				return of(postLeaveApplicationGrantNewEntitlementFail({ error }));
			}),
		),
	),
);
