import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_JOBS_CALL,
	GET_JOBS_DONE,
	GET_JOBS_FAIL,
	POST_JOB_APPLY_CALL,
	POST_JOB_APPLY_DONE,
	POST_JOB_APPLY_FAIL,
	GET_JOB_DETAIL_CALL,
	GET_JOB_DETAIL_DONE,
	GET_JOB_DETAIL_FAIL,
	POST_JOB_BINDING_CALL,
	POST_JOB_BINDING_DONE,
	POST_JOB_BINDING_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	jobs: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getJobsSideEffect: defaultSideEffectState,
	postJob: {},
	postJobSideEffect: defaultSideEffectState,
	deleteJob: {},
	deleteJobSideEffect: defaultSideEffectState,
	jobDetail: {},
	getJobDetailSideEffect: defaultSideEffectState,
	postJobBinding: {},
	postJobBindingSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_JOBS_CALL](state) {
		return update(state, {
			getJobsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_JOBS_DONE](state, { payload: { res } }) {
		return update(state, {
			jobs: { $set: res },
			getJobsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_JOBS_FAIL](state, { payload }) {
		return update(state, {
			getJobsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_JOB_APPLY_CALL](state) {
		return update(state, {
			postJobSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_JOB_APPLY_DONE](state, { payload: { res } }) {
		return update(state, {
			postJob: { $set: res },
			postJobSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_JOB_APPLY_FAIL](state, { payload }) {
		return update(state, {
			postJobSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_JOB_DETAIL_CALL](state) {
		return update(state, {
			getJobDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_JOB_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			jobDetail: { $set: res },
			getJobDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_JOB_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getJobDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_JOB_BINDING_CALL](state) {
		return update(state, {
			postJobBindingSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_JOB_BINDING_DONE](state, { payload: { res } }) {
		return update(state, {
			postJobBinding: { $set: res },
			postJobBindingSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_JOB_BINDING_FAIL](state, { payload }) {
		return update(state, {
			postJobBindingSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
