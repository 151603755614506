import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { session, user, ui } from '@src/services';
import * as actions from '@src/store/actions';
import { updateCartProductsCall } from '@src/store/actions/cart';
import { learningProgressDone } from '@src/store/actions/learningPath';
import { reactLocalStorage } from '@src/utils/localStorage';
import * as types from '@src/store/types';
import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const checkLoginCall = action$ => (
	action$.pipe(
		ofType(types.CHECK_LOGIN_CALL),
		switchMap(({ payload: { ssrHeaders } }) => (
			fromPromise(user.getProfile({ ssrHeaders })).pipe(
				switchMap(res => [
					updateCartProductsCall(),
					actions.checkLoginDone(res),
					actions.setUser({ profile: res }),
					actions.setModalType({ type: null }),
				]),
				catchError(err => of(actions.checkLoginFail(err))),
			)
		)),
	)
);

export const checkLoginSessionCall = action$ => (
	action$.pipe(
		ofType(types.CHECK_LOGIN_SESSION_CALL),
		switchMap(({ payload }) => (
			fromPromise(user.getProfile({})).pipe(
				switchMap(res => {
					const stashActions = localStorage.getItem('STASH_KEY');
					const nextActions = stashActions ? [
						actions.checkLoginSessionDone(res),
						actions.setUser({ profile: res }),
					] : [
						actions.checkLoginSessionDone(res),
						actions.setUser({ profile: res }),
						actions.historyPushCall(payload?.cb || '/'),
					];
					return nextActions;
				}),
				catchError(err => of(actions.checkLoginSessionFail(err))),
			)
		)),
	)
);

export const logoutCall = (action$, store) => (
	action$.pipe(
		ofType(types.LOGOUT_CALL),
		switchMap(() => {
			const closedWeb = store.value?.theme?.themes?.closed_web || false;
			const isFromCompanyVat = window.location.search.includes('fromCompanyVat=');
			const cb = window.location.href;
			const doneActions = [
				updateCartProductsCall(),
				actions.logoutDone(),
				actions.toastShowCall({ type: TOAST_TYPE_SUCCESS, message: 'all:logout_success' }),
				learningProgressDone({ res: { joined: false, progress: [], } }),
			];
			if (closedWeb) doneActions.push(actions.historyPushCall(`/business-login?cb=${cb}`));
			else if (isFromCompanyVat) doneActions.push(actions.setModalType({ type: 'login' }));
			else doneActions.push(actions.historyPushCall('/'));
			return doneActions;
		}),
		catchError(err => of(actions.logoutFail(err))),
	)
);

export const emailLoginCall = action$ => (
	action$.pipe(
		ofType(types.EMAIL_LOGIN_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.emailLogin(payload)).pipe(
				switchMap(res => {
					if (payload.onSuccess) payload.onSuccess();
					reactLocalStorage.set('isSignedIn', true);
					reactLocalStorage.set('token', res.session);
					return [
						actions.setToken({ token: res.session }),
						actions.checkLoginCall({ fromLogin: true }),
						actions.emailLoginDone(res),
						actions.toastShowCall({ type: TOAST_TYPE_SUCCESS, message: 'all:login_success' }),
					];
				}),
				catchError(err => of(actions.emailLoginFail(err))),
			)
		)),
	)
);

export const emailRegisterCall = action$ => (
	action$.pipe(
		ofType(types.EMAIL_REGISTER_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.emailRegister(payload)).pipe(
				switchMap(res => [
					actions.setModal({ modalData: payload }),
					actions.setModalType({ type: 'resendCertificationLetter' }),
					actions.emailRegisterDone(res),
				]),
				catchError(err => of(actions.emailRegisterFail(err))),
			)
		)),
	)
);

export const emailConfirmCall = action$ => (
	action$.pipe(
		ofType(types.EMAIL_CONFIRM_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.emailConfirm(payload)).pipe(
				switchMap(res => [
					actions.setToken({ token: res.session }),
					actions.emailConfirmDone(res),
					actions.checkLoginCall({ fromLogin: false }),
					actions.toastShowCall({ type: TOAST_TYPE_SUCCESS, message: 'all:login_success' }),
					actions.historyPushCall('/'),
				]),
				catchError(err => merge(
					of(actions.emailConfirmFail(err)),
					of(actions.historyPushCall('/')),
					of(actions.toastShowCall({ message: 'checkEmail:warn', type: TOAST_TYPE_ERROR })),
				)),
			)
		)),
	)
);

export const emailResendCall = (action$, store) => (
	action$.pipe(
		ofType(types.EMAIL_RE_SEND_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.emailReSend(payload)).pipe(
				switchMap(res => [
					actions.emailReSendDone(res),
					actions.toastShowCall({ message: 'checkEmail:success', type: TOAST_TYPE_SUCCESS }),
					actions.setModal({
						modalData: {
							...store.value.ui.modalData,
							message: {
								type: 'success',
								text: 'checkEmail:success',
							},
						},
					}),
				]),
				catchError(err => of(actions.emailReSendFail(err))),
			)
		)),
	)
);

export const updateMobileCall = action$ => (
	action$.pipe(
		ofType(types.UPDATE_MOBILE_CALL),
		switchMap(({ payload, payload: { showToast } }) => (
			fromPromise(session.updateMobile(payload)).pipe(
				switchMap(res => {
					const newActions = [actions.updateMobileDone(res)];

					if (showToast) {
						newActions.push(
							actions.toastShowCall({ message: 'confirmMobile:success', type: TOAST_TYPE_SUCCESS }),
						);
					}

					return newActions;
				}),
				catchError(err => of(actions.updateMobileFail(err))),
			)
		)),
	)
);

export const confirmMobileCall = action$ => (
	action$.pipe(
		ofType(types.CONFIRM_MOBILE_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.confirmMobile(payload)).pipe(
				switchMap(res => [
					actions.checkLoginCall({ fromLogin: false }),
					actions.confirmMobileDone(res),
				]),
				catchError(err => {
					ui.toastShow({ message: '驗證碼錯誤', type: TOAST_TYPE_ERROR });

					return of(actions.confirmMobileFail(err));
				}),
			)
		)),
	)
);

export const forgetPasswordCall = (action$, store) => (
	action$.pipe(
		ofType(types.FORGET_PASSWORD_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.forgetPassword(payload)).pipe(
				switchMap(res => {
					const { onSuccess } = payload || {};
					if (onSuccess) {
						onSuccess();
					}
					return [
						actions.forgetPasswordDone(res),
						actions.setModal({
							modalData: {
								...store.value.ui.modalData,
								message: {
									type: 'success',
									text: 'forgetPassword:success',
								},
							},
						}),
					];
				}),
				catchError(err => {
					const { onError } = payload || {};
					if (onError) {
						onError(err?.response?.data);
					}
					return of(actions.forgetPasswordFail(err));
				}),
			)
		)),
	)
);

export const getForgetPasswordEmailCall = action$ => (
	action$.pipe(
		ofType(types.GET_FORGET_PASSWORD_EMAIL_CALL),
		switchMap(({ payload, payload: { verification_code } }) => (
			fromPromise(session.getForgetPasswordEmail(payload)).pipe(
				switchMap(res => [
					actions.getForgetPasswordEmailDone(res),
					actions.historyPushCall({ pathname: '/' }),
					actions.setModal({
						modalData: {
							isSuccess: true,
							email: res.email,
							verificationCode: verification_code,
						},
					}),
					actions.setModalType({ type: 'resetPassword' }),
				]),
				catchError(err => merge(
					of(actions.getForgetPasswordEmailFail(err)),
					of(actions.historyPushCall({ pathname: '/' })),
					of(actions.toastShowCall({ message: 'resetPassword:linkError', type: TOAST_TYPE_ERROR })),
				)),
			)
		)),
	)
);

export const getBusinessForgetPasswordEmailCall = action$ => (
	action$.pipe(
		ofType(types.GET_BUSINESS_FORGET_PASSWORD_EMAIL_CALL),
		switchMap(({ payload, payload: { verification_code } }) => (
			fromPromise(session.getForgetPasswordEmail(payload)).pipe(
				switchMap(res => [
					actions.getForgetPasswordEmailDone(res),
					actions.historyPushCall({
						pathname: '/business-reset-password',
						search: `?email=${res.email}&verificationCode=${verification_code}`,
					}),
				]),
				catchError(err => merge(
					of(actions.getForgetPasswordEmailFail(err)),
					of(actions.historyPushCall({ pathname: '/business-login' })),
					of(actions.toastShowCall({ message: 'resetPassword:linkError', type: TOAST_TYPE_ERROR })),
				)),
			)
		)),
	)
);

export const googleDomainNotAllow = action$ => (
	action$.pipe(
		ofType(types.GOOGLE_DOMAIN_NOT_ALLOW),
		mergeMap(() => merge(
			of(actions.historyPushCall({ pathname: '/business-login' })),
			of(actions.toastShowCall({ message: 'business:login:google_domain_not_allow', type: TOAST_TYPE_ERROR })),
		)),
	)
);

export const resetPasswordCall = action$ => (
	action$.pipe(
		ofType(types.RESET_PASSWORD_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.resetPassword(payload)).pipe(
				switchMap(res => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return [
						actions.setToken({ token: res.session }),
						actions.checkLoginCall({ fromLogin: true }),
						actions.resetPasswordDone(res),
						actions.toastShowCall({ message: 'resetPassword:success', type: TOAST_TYPE_SUCCESS }),
						actions.setModalType({ type: null }),
					];
				}),
				catchError(err => merge(
					of(
						actions.resetPasswordFail(err),
						actions.toastShowCall({ message: 'resetPassword:linkError', type: TOAST_TYPE_ERROR }),
					),
				)),
			)
		)),
	)
);

export const socialLoginPrepareCall = action$ => (
	action$.pipe(
		ofType(types.SOCIAL_LOGIN_PREPARE_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.socialLoginPrepare(payload)).pipe(
				switchMap(res => {
					let { url } = res;
					if (payload.name === 'google') {
						const urlData = url.split('?');
						url = `${urlData[0]}?disallow_webview=true&${urlData[1]}`;
					}
					window.location.href = url;
					return [actions.socialLoginPrepareDone(res)];
				}),
				catchError(err => of(actions.socialLoginPrepareFail(err))),
			)
		)),
	)
);

export const socialLoginCall = action$ => (
	action$.pipe(
		ofType(types.SOCIAL_LOGIN_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.socialLogin(payload)).pipe(
				switchMap(res => [
					actions.setToken({ token: res.session }),
					actions.checkLoginCall({ fromLogin: true }),
					actions.socialLoginDone(res),
					actions.toastShowCall({ type: TOAST_TYPE_SUCCESS, message: 'all:login_success' }),
				]),
				catchError(err => [
					actions.socialLoginFail(err),
					actions.historyPushCall('/login-page'),
					actions.toastShowCall({ message: 'errors:loginError', type: TOAST_TYPE_ERROR }),
				]),
			)
		)),
	)
);

export const sendBugMsgCall = action$ => (
	action$.pipe(
		ofType(types.SEND_BUG_MSG_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.sendBugMsg(payload)).pipe(
				switchMap(res => [actions.sendBugMsgDone(res)]),
				catchError(err => of(actions.sendBugMsgFail(err))),
			)
		)),
	)
);

export const setBindResumeCall = action$ => (
	action$.pipe(
		ofType(types.SET_TOKEN, types.CHECK_LOGIN_DONE, types.POST_JOB_APPLY_DONE),
		switchMap(() => {
			const isSignedIn = reactLocalStorage.get('isSignedIn');
			if (!isSignedIn || isSignedIn === 'false') {
				return [];
			}
			const afterActions = [];
			const resumeExpiredAt = reactLocalStorage.get('resume_id_expiredAt');
			if (resumeExpiredAt && (new Date()).getTime() < parseInt(resumeExpiredAt)) {
				const resumeIds = JSON.parse(reactLocalStorage.get('resume_id', JSON.stringify([])));
				// eslint-disable-next-line no-restricted-syntax
				for (const resumeId of resumeIds) {
					afterActions.push(actions.postJobBingingCall({ id: resumeId }));
				}
				reactLocalStorage.remove('resume_id');
				reactLocalStorage.remove('resume_id_expiredAt');
			}
			return afterActions;
		}),
	)
);

export const rfaLoginCall = action$ => (
	action$.pipe(
		ofType(types.RFA_LOGIN_CALL),
		switchMap(({ payload }) => (
			fromPromise(session.rfaLogin(payload)).pipe(
				switchMap(res => {
					if (payload.onSuccess) payload.onSuccess();
					return [
						actions.setToken({ token: res.session }),
						actions.checkLoginCall({ fromLogin: true }),
						actions.rfaLoginDone(res),
						actions.toastShowCall({ type: TOAST_TYPE_SUCCESS, message: 'all:login_success' }),
					];
				}),
				catchError(err => of(actions.rfaLoginFail(err))),
			)
		)),
	)
);
