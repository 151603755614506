/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import api from '@src/api';

export const getSurveysQuestionSheet = payload => api.get(`surveys/${payload.surveyId}/sheet`);

export const postSurveysAnswerSheet = payload => api.post(`surveys/${payload.survey_id}/answer_sheet`, payload);

export const getSurveysAnswerSheet = payload => api.get(`surveys/${payload.surveyId}/answer_sheet/${payload.sheetId}`);
