import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, concatMap, catchError } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';

import * as actions from '@src/store/actions';
import {
	GET_EVENTS_CALL, GET_EVENT_CALL, GET_TAGS_CALL,
	EVENT_INSPECTOR_VERIFY_CALL,
	EVENT_REGISTER_FORM_CHECK_IN_CALL,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL,
	REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL,
	GET_EVENT_REGISTER_FORM_CALL,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL,
	GET_REGISTER_FORM_STATISTICS_CALL,
	GET_REGISTER_FORM_CHECK_IN_LIST_CALL,
} from '@src/store/types/event';
import {
	getEventDone,
	getEventFail,
	getEventsDone,
	getEventsFail,
	getTagsDone,
	getTagsFail,
	eventInspectorVerifyDone,
	eventInspectorVerifyFail,
	eventRegisterFormCheckInDone,
	eventRegisterFormCheckInFail,
	deleteEventRegisterFormCheckInDone,
	deleteEventRegisterFormCheckInFail,
	revertDeleteEventRegisterFormCheckInDone,
	revertDeleteEventRegisterFormCheckInFail,
	getEventRegisterFormDone,
	getEventRegisterFormFail,
	postEventRegisterFormNotifySettingDone,
	postEventRegisterFormNotifySettingFail,
	getRegisterFormStatisticsDone,
	getRegisterFormStatisticsFail,
	getRegisterFormCheckInListDone,
	getRegisterFormCheckInListFail,
} from '@src/store/actions/event';
import {
	getEvents, getEvent, getTags,
	eventInspectorVerify,
	eventRegisterFormCheckIn,
	deleteEventRegisterFormCheckIn,
	revertDeleteEventRegisterFormCheckIn,
	getEventRegisterForm,
	postEventRegisterFormNotifySetting,
	getRegisterFormStatistics,
	getRegisterFormCheckInList,
} from '@src/services/event';
import { ui } from '@src/services';
import { TOAST_TYPE_ERROR } from '@src/const/common';

export const getEventsCallEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_EVENTS_CALL),
		switchMap(({ payload }) => (
			fromPromise(getEvents(payload)).pipe(
				switchMap(response => of(getEventsDone({ response }))),
				catchError(error => of(getEventsFail({ error }))),
			)
		)),
	)
);

export const getEventCall = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_EVENT_CALL),
		concatMap(({ payload }) => (
			fromPromise(getEvent(payload)).pipe(
				switchMap(response => of(getEventDone({ response, eventShortLink: payload.eventShortLink }))),
				catchError(error => of(getEventFail({ error, eventShortLink: payload.eventShortLink }))),
			)
		)),
	)
);

export const getTagsCallEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_TAGS_CALL),
		switchMap(({ payload }) => (
			fromPromise(getTags(payload)).pipe(
				switchMap(response => of(getTagsDone({ response }))),
				catchError(error => of(getTagsFail({ error }))),
			)
		)),
	)
);

export const eventInspectorVerifyEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(EVENT_INSPECTOR_VERIFY_CALL),
		concatMap(({ payload }) => (
			fromPromise(eventInspectorVerify(payload)).pipe(
				switchMap(response => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return of(eventInspectorVerifyDone({ response }));
				}),
				catchError(error => of(eventInspectorVerifyFail({ error }))),
			)
		)),
	)
);

export const eventRegisterFormCheckInEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(EVENT_REGISTER_FORM_CHECK_IN_CALL),
		concatMap(({ payload }) => (
			fromPromise(eventRegisterFormCheckIn(payload)).pipe(
				switchMap(response => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return of(eventRegisterFormCheckInDone({ response }));
				}),
				catchError(error => of(eventRegisterFormCheckInFail({ error }))),
			)
		)),
	)
);

export const deleteEventRegisterFormCheckInEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL),
		concatMap(({ payload }) => (
			fromPromise(deleteEventRegisterFormCheckIn(payload)).pipe(
				switchMap(response => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return of(deleteEventRegisterFormCheckInDone({ response }));
				}),
				catchError(error => of(deleteEventRegisterFormCheckInFail({ error }))),
			)
		)),
	)
);

export const revertDeleteEventRegisterFormCheckInEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL),
		concatMap(({ payload }) => (
			fromPromise(revertDeleteEventRegisterFormCheckIn(payload)).pipe(
				switchMap(response => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return of(revertDeleteEventRegisterFormCheckInDone({ response }));
				}),
				catchError(error => of(revertDeleteEventRegisterFormCheckInFail({ error }))),
			)
		)),
	)
);

export const getEventRegisterFormEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_EVENT_REGISTER_FORM_CALL),
		concatMap(({ payload }) => (
			fromPromise(getEventRegisterForm(payload)).pipe(
				switchMap(response => of(getEventRegisterFormDone({ response }))),
				catchError(error => of(getEventRegisterFormFail({ error }))),
			)
		)),
	)
);

export const postEventRegisterFormNotifySettingEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL),
		concatMap(({ payload }) => (
			fromPromise(postEventRegisterFormNotifySetting(payload)).pipe(
				switchMap(response => {
					const { onSuccess } = payload;
					if (onSuccess) {
						onSuccess();
					}
					return of(postEventRegisterFormNotifySettingDone({ response }));
				}),
				catchError(error => of(postEventRegisterFormNotifySettingFail({ error }))),
			)
		)),
	)
);

export const getRegisterFormStatisticsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_REGISTER_FORM_STATISTICS_CALL),
		concatMap(({ payload }) => (
			fromPromise(getRegisterFormStatistics(payload)).pipe(
				switchMap(response => of(getRegisterFormStatisticsDone({ response }))),
				catchError(error => {
					const failActions = [
						getRegisterFormStatisticsFail({ error }),
					];
					const { status } = error.response;
					if (status === 401 || status === 403) {
						ui.toastShow({
							message: 'errors:no_permission',
							type: TOAST_TYPE_ERROR,
						});
						failActions.push(
							actions.historyPushCall('/'),
						);
					}
					return failActions;
				}),
			)
		)),
	)
);

export const getRegisterFormCheckInListEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_REGISTER_FORM_CHECK_IN_LIST_CALL),
		concatMap(({ payload }) => (
			fromPromise(getRegisterFormCheckInList(payload)).pipe(
				switchMap(response => of(getRegisterFormCheckInListDone({ response }))),
				catchError(error => of(getRegisterFormCheckInListFail({ error }))),
			)
		)),
	)
);
