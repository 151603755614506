import React from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as actionCreators from '@src/store/actions';
import OauthButton from '@src/components/oauthButton';

import { Title } from './Styled';
import classes from './styles.module.less';

const StyledTitle = styled(Title)`
	margin: 15px auto 24px;
`;

const LoginWithOther = ({ actions, isBorderTop = true, isBorderBottom, t }) => {
	function handleLoginByType(type) {
		actions.addActionsToStash([
			{
				actionMethod: 'historyReplaceCall',
				actionPayload: location.pathname,
			},
		]);
		actions.socialLoginPrepareCall({ name: type });
	}
	return (
		<>
			{isBorderTop && <Title>{t('login:or')}</Title>}
			<div>
				<OauthButton
					className={classes.oauthBtn}
					name={t('auth_modal:sign_in_with_Google')}
					type="google"
					onClick={() => handleLoginByType('google')}
				/>
				<OauthButton
					className={classes.oauthBtn}
					name={t('auth_modal:sign_in_with_Facebook')}
					type="facebook"
					onClick={() => handleLoginByType('facebook')}
				/>
				<OauthButton
					className={classes.oauthBtn}
					name={t('auth_modal:sign_in_with_Line')}
					type="line"
					onClick={() => handleLoginByType('line')}
				/>
			</div>
			{isBorderBottom && <StyledTitle>{t('login:or')}</StyledTitle>}
		</>
	);
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(null, mapDispatchToProps)(LoginWithOther),
);
