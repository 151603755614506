import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/zh-tw';

dayjs.extend(utc);

export const formatDeadline = (value) => {
  if (!value) {
    return null;
  }

  return dayjs(value).locale('zh-tw').format('YYYY/MM/DD (dd) HH:mm');
};

export const formatStartCourse = (value) => {
  if (!value) {
    return null;
  }

  const data = dayjs(value);
  return data.locale('zh-tw').format(`YYYY/MM/DD 中午 HH:mm`);
};

export const formatDate = (value) => {
  if (!value) {
    return null;
  }

  return dayjs(value).format('YYYY-MM-DD');
};

export const formatDateType2 = (value) => {
  if (!value) {
    return null;
  }

  return dayjs(value).format('YYYY / MM / DD');
};

export const formatDateWithTime = (value) => {
  if (!value) {
    return null;
  }

  return dayjs(value).format('YYYY-MM-DD HH:mm');
};

export const isExpiryDate = (value) => {
  return dayjs().isAfter(dayjs(value));
};

export const formatRemainingTimeCeil = (value) => {
  if (!value) {
    return null;
  }

  const remaining = value - dayjs.utc().valueOf();

  const seconds = Math.ceil((remaining / 1000) % 60);
  const minutes = Math.ceil((remaining / 1000 / 60) % 60);
  const hours = Math.ceil((remaining / (1000 * 60 * 60)) % 24);
  const days = Math.ceil(remaining / (1000 * 60 * 60 * 24));

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

export const formatRemainingTimeFloor = (value) => {
  if (!value) {
    return null;
  }

  const remaining = value - dayjs.utc().valueOf();

  const seconds = Math.floor((remaining / 1000) % 60);
  const minutes = Math.floor((remaining / 1000 / 60) % 60);
  const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
  const days = Math.floor(remaining / (1000 * 60 * 60 * 24));

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

export const formatRemainingTotalTimeCeil = (value) => {
  if (!value) {
    return null;
  }

  const remaining = value - dayjs.utc().valueOf();

  const seconds = Math.ceil(remaining / 1000);
  const minutes = Math.ceil(remaining / 1000 / 60);
  const hours = Math.ceil(remaining / (1000 * 60 * 60));
  const days = Math.ceil(remaining / (1000 * 60 * 60 * 24));

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

export const getDaysInMonth = (month, year) => {
  // Here January is 1 based
  // Day 0 is the last day in the previous month
  return new Date(year, month, 0).getDate();
  // Here January is 0 based
  // return new Date(year, month+1, 0).getDate();
};

export const getCurrentYear = () => dayjs().year();

export const parseBirthday = (value) => {
  if (!value) {
    return null;
  }

  const birthday = dayjs(value);

  return {
    year: birthday.year(),
    month: birthday.month() + 1,
    date: birthday.date(),
  };
};

export const formatBirthday = (year, month, date) => {
  return dayjs().year(year).month(month).date(date).format('YYYY-MM-DD');
};

export const calcDuractionTimePercentage = (start, end) => {
  if (!start || !end) {
    return 0;
  }

  const currentValue = dayjs.utc().valueOf();

  if (currentValue < start) {
    return 0;
  }

  return Math.ceil(((currentValue - start) / (end - start)) * 100);
};

export const getCurrentTimestamp = () => {
  return dayjs.utc().valueOf();
};
