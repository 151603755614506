import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './locales';

const myDetector = {
  name: 'myDetectorsName',
  async: true,
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'zh-TW',
    ns: ['common'],
    defaultNS: ['common'],
    debug: false && process.env.NODE_ENV === 'development',
  });

export default i18n;
