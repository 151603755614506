import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_KNOWLEDGE_BASE_SPACES_CALL,
	GET_KNOWLEDGE_BASE_SPACES_DONE,
	GET_KNOWLEDGE_BASE_SPACES_FAIL,
	POST_KNOWLEDGE_BASE_SPACE_CALL,
	POST_KNOWLEDGE_BASE_SPACE_DONE,
	POST_KNOWLEDGE_BASE_SPACE_FAIL,
	DELETE_KNOWLEDGE_BASE_SPACE_CALL,
	DELETE_KNOWLEDGE_BASE_SPACE_DONE,
	DELETE_KNOWLEDGE_BASE_SPACE_FAIL,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL,
	PATCH_KNOWLEDGE_BASE_SPACE_CALL,
	PATCH_KNOWLEDGE_BASE_SPACE_DONE,
	PATCH_KNOWLEDGE_BASE_SPACE_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	knowledgeBaseSpaces: {
		data: [],
		pagination: {
			totalCount: 0,
		},
	},
	getKnowledgeBaseSpacesSideEffect: defaultSideEffectState,
	postKnowledgeBaseSpace: {},
	postKnowledgeBaseSpaceSideEffect: defaultSideEffectState,
	deleteKnowledgeBaseSpace: {},
	deleteKnowledgeBaseSpaceSideEffect: defaultSideEffectState,
	knowledgeBaseSpaceDetail: {},
	getKnowledgeBaseSpaceDetailSideEffect: defaultSideEffectState,
	patchKnowledgeBaseSpace: {},
	patchKnowledgeBaseSpaceSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_KNOWLEDGE_BASE_SPACES_CALL](state) {
		return update(state, {
			getKnowledgeBaseSpacesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_SPACES_DONE](state, { payload: { res } }) {
		return update(state, {
			knowledgeBaseSpaces: { $set: res },
			getKnowledgeBaseSpacesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_SPACES_FAIL](state, { payload }) {
		return update(state, {
			getKnowledgeBaseSpacesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_KNOWLEDGE_BASE_SPACE_CALL](state) {
		return update(state, {
			postKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_SPACE_DONE](state, { payload: { res } }) {
		return update(state, {
			postKnowledgeBaseSpace: { $set: res },
			postKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_SPACE_FAIL](state, { payload }) {
		return update(state, {
			postKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[DELETE_KNOWLEDGE_BASE_SPACE_CALL](state) {
		return update(state, {
			deleteKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[DELETE_KNOWLEDGE_BASE_SPACE_DONE](state, { payload: { res } }) {
		return update(state, {
			deleteKnowledgeBaseSpace: { $set: res },
			deleteKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[DELETE_KNOWLEDGE_BASE_SPACE_FAIL](state, { payload }) {
		return update(state, {
			deleteKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL](state) {
		return update(state, {
			getKnowledgeBaseSpaceDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			knowledgeBaseSpaceDetail: { $set: res },
			getKnowledgeBaseSpaceDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getKnowledgeBaseSpaceDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[PATCH_KNOWLEDGE_BASE_SPACE_CALL](state) {
		return update(state, {
			patchKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[PATCH_KNOWLEDGE_BASE_SPACE_DONE](state, { payload: { res } }) {
		return update(state, {
			patchKnowledgeBaseSpace: { $set: res },
			patchKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[PATCH_KNOWLEDGE_BASE_SPACE_FAIL](state, { payload }) {
		return update(state, {
			patchKnowledgeBaseSpaceSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
