import router from 'next/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concatMap, switchMap, catchError } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';
import { isEmpty } from 'lodash';
import { sentGTMAddCartItem } from '@src/utils/gtag';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';
import {
	addEventToCart,
	addWebinarToCart,
	getCartProducts,
	removeCourseFromCart,
	removeEventFromCart,
	removeWebinarFromCart,
	updateEventQuantity,
	reserveCartProducts,
	getPublicCourseCoupons,
	applyCourseCoupons,
	deleteCourseCoupons,
	getPublicEventCoupons,
	applyEventSlotCoupons,
	deleteEventSlotCoupons,
	getPublicWebinarCoupons,
	applyWebinarSlotCoupons,
	deleteWebinarSlotCoupons,
} from '@src/services/cart';
import { toastShowCall } from '@src/store/actions/ui';
import {
	addEventToCartDone,
	addEventToCartFail,
	addWebinarToCartDone,
	addWebinarToCartFail,
	getCartProductsDone,
	getCartProductsFail,
	updateCartProductsCall,
	removeCourseFromCartDone,
	removeCourseFromCartFail,
	removeEventFromCartDone,
	removeEventFromCartFail,
	removeWebinarFromCartDone,
	removeWebinarFromCartFail,
	updateEventQuantityDone,
	updateEventQuantityFail,
	reserveCartProductsDone,
	reserveCartProductsFail,
	getPublicCourseCouponsCall,
	getPublicCourseCouponsDone,
	getPublicCourseCouponsFail,
	applyCourseCouponsDone,
	applyCourseCouponsFail,
	deleteCourseCouponsDone,
	deleteCourseCouponsFail,
	getPublicEventCouponsCall,
	getPublicEventCouponsDone,
	getPublicEventCouponsFail,
	applyEventSlotCouponsDone,
	applyEventSlotCouponsFail,
	deleteEventSlotCouponsDone,
	deleteEventSlotCouponsFail,
	getPublicWebinarCouponsCall,
	getPublicWebinarCouponsDone,
	getPublicWebinarCouponsFail,
	applyWebinarSlotCouponsDone,
	applyWebinarSlotCouponsFail,
	deleteWebinarSlotCouponsDone,
	deleteWebinarSlotCouponsFail,
} from '@src/store/actions/cart';
import {
	ADD_EVENT_TO_CART_CALL,
	ADD_WEBINAR_TO_CART_CALL,
	GET_CART_PRODUCTS_CALL,
	UPDATE_CART_PRODUCTS_CALL,
	REMOVE_COURSE_FROM_CART_CALL,
	REMOVE_EVENT_FROM_CART_CALL,
	REMOVE_WEBINAR_FROM_CART_CALL,
	UPDATE_EVENT_QUANTITY_CALL,
	RESERVE_CART_PRODUCTS_CALL,
	GET_PUBLIC_COURSE_COUPONS_CALL,
	APPLY_COURSE_COUPONS_CALL,
	DELETE_COURSE_COUPONS_CALL,
	GET_PUBLIC_EVENT_COUPONS_CALL,
	APPLY_EVENT_SLOT_COUPONS_CALL,
	DELETE_EVENT_SLOT_COUPONS_CALL,
	GET_PUBLIC_WEBINAR_COUPONS_CALL,
	APPLY_WEBINAR_SLOT_COUPONS_CALL,
	DELETE_WEBINAR_SLOT_COUPONS_CALL,
} from '@src/store/types/cart';

export const addEventToCartEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(ADD_EVENT_TO_CART_CALL),
		switchMap(({ payload }) => (
			fromPromise(addEventToCart(payload)).pipe(
				switchMap(() => {
					sentGTMAddCartItem({
						id: payload?.event?.short_link,
						name: payload?.event?.name,
						raw: payload?.event,
					});
					return of(
						toastShowCall({ message: 'checkout:add_to_cart_successfully', type: TOAST_TYPE_SUCCESS }),
						addEventToCartDone(),
						updateCartProductsCall(),
					);
				}),
				catchError(() => of(
					toastShowCall({ message: 'checkout:add_to_cart_fail', type: TOAST_TYPE_ERROR }),
					addEventToCartFail(),
				)),
			)
		)),
	)
);

export const addWebinarToCartEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(ADD_WEBINAR_TO_CART_CALL),
		switchMap(({ payload }) => (
			fromPromise(addWebinarToCart(payload)).pipe(
				switchMap(() => {
					sentGTMAddCartItem({
						id: payload?.webinar?.short_link,
						name: payload?.webinar?.name,
						raw: payload?.webinar,
					});
					return of(
						toastShowCall({ message: 'checkout:add_to_cart_successfully', type: TOAST_TYPE_SUCCESS }),
						addWebinarToCartDone(),
						updateCartProductsCall(),
					);
				}),
				catchError(() => of(
					toastShowCall({ message: 'checkout:add_to_cart_fail', type: TOAST_TYPE_ERROR }),
					addWebinarToCartFail(),
				)),
			)
		)),
	)
);

export const getCartProductsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_CART_PRODUCTS_CALL, UPDATE_CART_PRODUCTS_CALL),
		switchMap(() => (
			fromPromise(getCartProducts()).pipe(
				switchMap(response => {
					const actions = [
						getCartProductsDone({ response }),
						getPublicCourseCouponsCall(),
						getPublicEventCouponsCall(),
						getPublicWebinarCouponsCall(),
					];
					return actions;
				}),
				catchError(error => of(getCartProductsFail({ error }))),
			)
		)),
	)
);

export const removeCourseFromCartEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(REMOVE_COURSE_FROM_CART_CALL),
		switchMap(({ payload }) => (
			fromPromise(removeCourseFromCart(payload)).pipe(
				switchMap(() => of(
					toastShowCall({ message: 'checkout:remove_successfully', type: TOAST_TYPE_SUCCESS }),
					removeCourseFromCartDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(
					toastShowCall({ message: 'checkout:add_to_cart_fail', type: TOAST_TYPE_ERROR }),
					removeCourseFromCartFail(),
				)),
			)
		)),
	)
);

export const removeEventFromCartEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(REMOVE_EVENT_FROM_CART_CALL),
		switchMap(({ payload }) => (
			fromPromise(removeEventFromCart(payload)).pipe(
				switchMap(() => of(
					toastShowCall({ message: 'checkout:remove_successfully', type: TOAST_TYPE_SUCCESS }),
					removeEventFromCartDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(
					toastShowCall({ message: 'checkout:add_to_cart_fail', type: TOAST_TYPE_ERROR }),
					removeEventFromCartFail(),
				)),
			)
		)),
	)
);

export const removeWebinarFromCartEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(REMOVE_WEBINAR_FROM_CART_CALL),
		switchMap(({ payload }) => (
			fromPromise(removeWebinarFromCart(payload)).pipe(
				switchMap(() => of(
					toastShowCall({ message: 'checkout:remove_successfully', type: TOAST_TYPE_SUCCESS }),
					removeWebinarFromCartDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(
					toastShowCall({ message: 'checkout:add_to_cart_fail', type: TOAST_TYPE_ERROR }),
					removeWebinarFromCartFail(),
				)),
			)
		)),
	)
);

export const updateEventQuantityEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(UPDATE_EVENT_QUANTITY_CALL),
		switchMap(({ payload }) => (
			fromPromise(updateEventQuantity(payload)).pipe(
				switchMap(() => of(
					updateEventQuantityDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(
					updateEventQuantityFail(),
					updateCartProductsCall(),
				)),
			)
		)),
	)
);

export const reserveCartProductsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(RESERVE_CART_PRODUCTS_CALL),
		switchMap(() => (
			fromPromise(reserveCartProducts()).pipe(
				switchMap(({ is_reserve_success, failed_products, failed_event_slots, failed_webinar_slots }) => {
					if (is_reserve_success) {
						router.push('/checkout');

						return of(reserveCartProductsDone());
					}

					const toastAction = (
						isEmpty(failed_products) && isEmpty(failed_event_slots) && isEmpty(failed_webinar_slots)
					) ? [] : [toastShowCall({ message: 'checkout:shopping_cart_update', type: TOAST_TYPE_ERROR })];

					return of(
						...toastAction,
						reserveCartProductsFail(),
						updateCartProductsCall(),
					);
				}),
				catchError(() => of(
					reserveCartProductsFail(),
					updateCartProductsCall(),
				)),
			)
		)),
	)
);

export const getPublicCourseCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_PUBLIC_COURSE_COUPONS_CALL),
		switchMap(() => (
			fromPromise(getPublicCourseCoupons()).pipe(
				switchMap(response => of(getPublicCourseCouponsDone({ response }))),
				catchError(() => of(getPublicCourseCouponsFail())),
			)
		)),
	)
);

export const applyCourseCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(APPLY_COURSE_COUPONS_CALL),
		switchMap(({ payload }) => (
			fromPromise(applyCourseCoupons(payload)).pipe(
				switchMap(() => of(
					applyCourseCouponsDone(),
					updateCartProductsCall(),
				)),
				catchError(error => of(applyCourseCouponsFail({ error }))),
			)
		)),
	)
);

export const deleteCourseCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(DELETE_COURSE_COUPONS_CALL),
		switchMap(() => (
			fromPromise(deleteCourseCoupons()).pipe(
				switchMap(() => of(
					deleteCourseCouponsDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(deleteCourseCouponsFail())),
			)
		)),
	)
);

export const getPublicEventCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_PUBLIC_EVENT_COUPONS_CALL),
		concatMap(() => (
			fromPromise(getPublicEventCoupons()).pipe(
				switchMap(response => of(getPublicEventCouponsDone({ response }))),
				catchError(() => of(getPublicEventCouponsFail())),
			)
		)),
	)
);

export const applyEventSlotCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(APPLY_EVENT_SLOT_COUPONS_CALL),
		concatMap(({ payload }) => (
			fromPromise(applyEventSlotCoupons(payload)).pipe(
				switchMap(response => of(
					applyEventSlotCouponsDone({ response }),
					updateCartProductsCall(),
				)),
				catchError(error => of(applyEventSlotCouponsFail({ error }))),
			)
		)),
	)
);

export const deleteEventSlotCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(DELETE_EVENT_SLOT_COUPONS_CALL),
		concatMap(() => (
			fromPromise(deleteEventSlotCoupons()).pipe(
				switchMap(() => of(
					deleteEventSlotCouponsDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(deleteEventSlotCouponsFail())),
			)
		)),
	)
);

export const getPublicWebinarCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(GET_PUBLIC_WEBINAR_COUPONS_CALL),
		concatMap(() => (
			fromPromise(getPublicWebinarCoupons()).pipe(
				switchMap(response => of(getPublicWebinarCouponsDone({ response }))),
				catchError(() => of(getPublicWebinarCouponsFail())),
			)
		)),
	)
);

export const applyWebinarSlotCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(APPLY_WEBINAR_SLOT_COUPONS_CALL),
		concatMap(({ payload }) => (
			fromPromise(applyWebinarSlotCoupons(payload)).pipe(
				switchMap(response => of(
					applyWebinarSlotCouponsDone({ response }),
					updateCartProductsCall(),
				)),
				catchError(error => of(applyWebinarSlotCouponsFail({ error }))),
			)
		)),
	)
);

export const deleteWebinarSlotCouponsEpic = (action$: Observable<Action>): Observable<Action> => (
	action$.pipe(
		ofType(DELETE_WEBINAR_SLOT_COUPONS_CALL),
		concatMap(() => (
			fromPromise(deleteWebinarSlotCoupons()).pipe(
				switchMap(() => of(
					deleteWebinarSlotCouponsDone(),
					updateCartProductsCall(),
				)),
				catchError(() => of(deleteWebinarSlotCouponsFail())),
			)
		)),
	)
);
