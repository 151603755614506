import { STRENGTHS } from '@src/const/password';

const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

const mediumRegex = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
);

export const isStrongPassword = password => strongRegex.test(password);

export const isMediumPassword = password => mediumRegex.test(password);

export const getPasswordStrength = (password = '') => {
  if (isStrongPassword(password)) {
    return STRENGTHS[0];
  } else if (isMediumPassword(password)) {
    return STRENGTHS[1];
  }

  return STRENGTHS[2];
}
