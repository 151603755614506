import React from 'react';
import useTranslation from '@src/hooks/useTranslation';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 48px;
  }
`;
const CoverWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99999;
`;

const PreviewMessageBar = styled.div`
	padding: 12px 20px;
	display: flex;;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #676767;
`;

const Text = styled.div`
	font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
`;

const Button = styled(Text)`
	cursor: pointer;
`;

interface IPreviewCover {
	className?: string;
	callbackUrl?: string;
}

const PreviewCover = ({ className, callbackUrl }: IPreviewCover): JSX.Element => {
	const { t } = useTranslation();

	const handleClick = () => {
		window.location.replace(callbackUrl);
	};

	return (
		<CoverWrapper className={className}>
			<GlobalStyle />
			<PreviewMessageBar>
				<Text>{t('common:preview_mode')}</Text>
				{
					handleClick && <Button onClick={handleClick}>{t('common:preview_mode_back')}</Button>
				}
			</PreviewMessageBar>
		</CoverWrapper>
	);
};

export default PreviewCover;
