import Cookies from 'universal-cookie';
import { reactLocalStorage } from '@src/utils/localStorage';
import request from './request';
const cookies = new Cookies();

const getHeader = () => {
	const cookieToken = cookies.get('token') || '';
	const token = reactLocalStorage.get('token') || '';

	return {
		Authorization: `${token || cookieToken}`,
	};
};

const delay = (interval, data) => new Promise(resolve => {
	setTimeout(() => {
		resolve(data);
	}, interval);
});

export default {
	get(url, data, otherConfigs) {
		return request('GET', url, data, getHeader(), otherConfigs);
	},
	post(url, data, otherConfigs) {
		return request('POST', url, data, getHeader(), otherConfigs);
	},
	delete(url, data, otherConfigs) {
		return request('DELETE', url, data, getHeader(), otherConfigs);
	},
	put(url, data, otherConfigs) {
		return request('PUT', url, data, getHeader(), otherConfigs);
	},
	upload(url, data, otherConfigs) {
		return request('POST', url, data, getHeader(), otherConfigs);
	},
	patch(url, data, otherConfigs) {
		return request('PATCH', url, data, getHeader(), otherConfigs);
	},
	mock(interval, data) {
		return delay(interval, data);
	},
};
