/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line import/no-cycle
import api from '@src/api';

export const getQuizzesAnswerSheet = payload => api.get(`quizzes/${payload.quizId}/answer_sheet/${payload.sheetId}`);

export const getQuizzesAnswerSheetViewAnswer = payload => api.get(`quizzes/${payload.quizId}/answer_sheet/${payload.sheetId}/view_answer`);

export const setQuizzesAnswerSheet = payload => api.post(`quizzes/${payload.quiz_id}/answer_sheet`, payload);

export const getQuizzesQuestionSheet = payload => api.get(`quizzes/${payload.quizId}/question_sheet`);
