import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';
import * as types from '@src/store/types';
import _ from 'lodash';
import update from 'immutability-helper';

const RESPONSE_PROTECT_MESSAGE = '信用卡授權失敗_卡號保護中';

const initialState = {
	groupErrorMessage: '',
	inputErrorMessages: {},
};

export default handleActions(
	{
		[types.EMAIL_LOGIN_FAIL](state, { payload: { response = {} } }) {
			const { status } = response;

			return produce(state, draft => {
				if (status === 400 || status === 403) {
					draft.groupErrorMessage = 'login:passwordError';
				}
			});
		},
		[types.EMAIL_REGISTER_FAIL](state, { payload: { response = {} } }) {
			const { status, data } = response;

			return produce(state, draft => {
				if (status === 409 || data?.message === 'Conflict Email') {
					draft.inputErrorMessages = {
						...draft.inputErrorMessages,
						email: 'login:accountExisted',
					};
				}
			});
		},

		[types.FORGET_PASSWORD_FAIL](state, { payload: { response = {} } }) {
			const { status } = response;

			return produce(state, draft => {
				if (status === 404) {
					draft.inputErrorMessages = {
						...draft.inputErrorMessages,
						email: 'forgetPassword:emailError',
					};
				}
			});
		},

		[types.CHECK_HOST_NAME_EXIST_DONE](state, { payload = [] }) {
			return produce(state, draft => {
				draft.inputErrorMessages.hostName = 'addSchool:nameError';
			});
		},
		[types.ADD_SCHOOL_FAIL](state, { payload: { response = {} } }) {
			const { status, data } = response;

			return produce(state, draft => {
				if (status === 400) {
					const { fields } = data;

					_.forEach(fields, item => {
						switch (item) {
							case 'name':
								draft.inputErrorMessages.name = 'addSchool:nameError';
								break;
							case 'hostname':
								draft.inputErrorMessages.hostName = 'addSchool:nameError';
								break;
							case 'company_no':
								draft.inputErrorMessages.companyNo =
									'addSchool:companyNoError';
								break;
							default:
								break;
						}
					});
				}
			});
		},
		[types.ADD_CREDIT_CARD_FAIL](state, { payload }) {
			const isCreditCardProtected = decodeURI(payload.error.response.data.i18n_msg)
				.indexOf(RESPONSE_PROTECT_MESSAGE) > -1;

			return update(state, {
				groupErrorMessage: {
					$set: isCreditCardProtected ?
						'checkout:retry_after_seconds' :
						'pay:creditCardError',
				},
			});
		},
		[types.CONFIRM_MOBILE_FAIL](state, { payload: { response } }) {
			return produce(state, draft => {
				draft.groupErrorMessage = 'confirmMobile:verificationError';
			});
		},
		[combineActions(
			types.EMAIL_LOGIN_CALL,
			types.ADD_CREDIT_CARD_CALL,
			types.EMAIL_RE_SEND_CALL,
			types.CONFIRM_MOBILE_CALL,
		)](state, { payload }) {
			return produce(state, draft => {
				draft.groupErrorMessage = '';
			});
		},
		[types.SET_ERROR_MESSAGE](
			state,
			{ payload: { groupErrorMessage, inputErrorMessages } },
		) {
			return produce(state, draft => {
				if (groupErrorMessage) {
					draft.groupErrorMessage = groupErrorMessage;
				}

				if (inputErrorMessages) {
					draft.inputErrorMessages = inputErrorMessages;
				}
			});
		},
		[types.CLEAR_ERROR_MESSAGE](state, { payload }) {
			return initialState;
		},
		[types.CLEAR_INPUT_ERROR_MESSAGE](state, { payload: { name } }) {
			return produce(state, draft => {
				if (draft.inputErrorMessages[name]) {
					draft.inputErrorMessages[name] = '';
				}
			});
		},
	},
	initialState,
);
