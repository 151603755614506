import * as actions from '@src/store/actions';
import * as selectors from '@src/store/selectors';
import { getApiData } from '@src/utils/deprecatedCommon';

export const defaultActionCalls = {
	actionCall: ({ req, store, query }) => {
		const { preview } = query;
		const apiData = getApiData(req);
		const { ssrHeaders, domain } = apiData;

		store.dispatch(actions.getThemesCall({ temporary: preview, ssrHeaders, store }));
		store.dispatch(actions.setDomain({ domain }));
	},
	actionDone: ({ store, res }) => {
		const state = store.getState();
		const { themeState } = selectors.getThemeSelector(state);

		return themeState !== 'IDLE' && themeState !== 'LOADING';
	},
	updateHydrateState: (draft, nextState) => {
		draft.theme = nextState.theme;
		draft.ssr = nextState.ssr;
		draft.category = nextState.category;
	},
};

export async function execDefaultServerSideProps(ctx) {
	const { store } = ctx;

	const success = await new Promise(resolve => {
		const unsubscribe = store.subscribe(() => {
			const defaultActionDone = defaultActionCalls.actionDone(ctx);

			if (defaultActionDone) {
				resolve(unsubscribe);
			}
		});

		defaultActionCalls.actionCall(ctx);
	});

	success();
}
