import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_LEAVE_APPLICATIONS_CALL,
	GET_LEAVE_APPLICATIONS_DONE,
	GET_LEAVE_APPLICATIONS_FAIL,
	POST_LEAVE_APPLICATION_CALL,
	POST_LEAVE_APPLICATION_DONE,
	POST_LEAVE_APPLICATION_FAIL,
	GET_LEAVE_APPLICATION_INFO_CALL,
	GET_LEAVE_APPLICATION_INFO_DONE,
	GET_LEAVE_APPLICATION_INFO_FAIL,
	GET_LEAVE_APPLICATION_DETAIL_CALL,
	GET_LEAVE_APPLICATION_DETAIL_DONE,
	GET_LEAVE_APPLICATION_DETAIL_FAIL,
	REST_LEAVE_APPLICATION_DETAIL_CALL,
	POST_LEAVE_APPLICATION_APPROVE_CALL,
	POST_LEAVE_APPLICATION_APPROVE_DONE,
	POST_LEAVE_APPLICATION_APPROVE_FAIL,
	GET_LEAVE_APPLICATION_CONFIRM_CALL,
	GET_LEAVE_APPLICATION_CONFIRM_DONE,
	GET_LEAVE_APPLICATION_CONFIRM_FAIL,
	POST_LEAVE_APPLICATION_CONFIRM_CALL,
	POST_LEAVE_APPLICATION_CONFIRM_DONE,
	POST_LEAVE_APPLICATION_CONFIRM_FAIL,
	POST_LEAVE_APPLICATION_WITHDRAW_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_DONE,
	POST_LEAVE_APPLICATION_WITHDRAW_FAIL,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL,
	GET_AVAILABLE_LEAVE_TYPES_CALL,
	GET_AVAILABLE_LEAVE_TYPES_DONE,
	GET_AVAILABLE_LEAVE_TYPES_FAIL,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL,
	GET_LEAVE_ENTITLEMENT_CALL,
	GET_LEAVE_ENTITLEMENT_DONE,
	GET_LEAVE_ENTITLEMENT_FAIL,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL,
	GET_CUSTOM_LEAVE_TYPE_RULES_CALL,
	GET_CUSTOM_LEAVE_TYPE_RULES_DONE,
	GET_CUSTOM_LEAVE_TYPE_RULES_FAIL,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const defaultLeaveApplicationsInfoByLeaveType = {};

const initialState = {
	leaveApplications: [],
	getLeaveApplicationsSideEffect: defaultSideEffectState,
	postLeaveApplication: {},
	postLeaveApplicationSideEffect: defaultSideEffectState,
	leaveApplicationInfo: {},
	getLeaveApplicationInfoSideEffect: defaultSideEffectState,
	leaveApplicationDetail: {},
	getLeaveApplicationDetailSideEffect: defaultSideEffectState,
	postLeaveApplicationApprove: {},
	postLeaveApplicationApproveSideEffect: defaultSideEffectState,
	leaveApplicationConfirm: {},
	getLeaveApplicationConfirmSideEffect: defaultSideEffectState,
	postLeaveApplicationConfirm: {},
	postLeaveApplicationConfirmSideEffect: defaultSideEffectState,
	postLeaveApplicationWithdraw: {},
	postLeaveApplicationWithdrawSideEffect: defaultSideEffectState,
	getLeaveApplicationsApproverView: [],
	getLeaveApplicationsApproverViewSideEffect: defaultSideEffectState,
	postLeaveApplicationWithdrawApproval: {},
	postLeaveApplicationWithdrawApprovalSideEffect: defaultSideEffectState,
	getAvailableLeaveTypes: [],
	getAvailableLeaveTypesSideEffect: defaultSideEffectState,
	getLeaveApplicationsTotalMinutes: 0,
	getLeaveApplicationsTotalMinutesSideEffect: defaultSideEffectState,
	getLeaveEntitlement: [],
	getLeaveEntitlementSideEffect: defaultSideEffectState,
	getLeaveEntitlementByLeaveType: [],
	getLeaveEntitlementByLeaveTypeSideEffect: defaultSideEffectState,
	getLeaveApplicationAttachmentDownloadLink: {},
	getLeaveApplicationAttachmentDownloadLinkSideEffect: defaultSideEffectState,
	leaveApplicationsInfoByLeaveType: defaultLeaveApplicationsInfoByLeaveType,
	leaveApplicationsInfoByLeaveTypeSideEffect: defaultSideEffectState,
	customLeaveTypeRules: [],
	getCustomLeaveTypeRulesSideEffect: defaultSideEffectState,
	postLeaveApplicationGrantNewEntitlement: {},
	postLeaveApplicationGrantNewEntitlementSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_LEAVE_APPLICATIONS_CALL](state) {
		return update(state, {
			getLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_DONE](state, { payload: { res } }) {
		return update(state, {
			leaveApplications: { $set: res },
			getLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEAVE_APPLICATION_CALL](state) {
		return update(state, {
			postLeaveApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplication: { $set: res },
			postLeaveApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATION_INFO_CALL](state) {
		return update(state, {
			getLeaveApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_INFO_DONE](state, { payload: { res } }) {
		return update(state, {
			leaveApplicationInfo: { $set: res },
			getLeaveApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_INFO_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationInfoSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATION_DETAIL_CALL](state) {
		return update(state, {
			getLeaveApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			leaveApplicationDetail: { $set: res },
			getLeaveApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[REST_LEAVE_APPLICATION_DETAIL_CALL](state) {
		return update(state, {
			leaveApplicationDetail: { $set: {} },
			leaveApplicationsInfoByLeaveType: { $set: defaultLeaveApplicationsInfoByLeaveType },
			getLeaveApplicationsTotalMinutes: { $set: 0 },
		});
	},

	[POST_LEAVE_APPLICATION_APPROVE_CALL](state) {
		return update(state, {
			postLeaveApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_APPROVE_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplicationApprove: { $set: res },
			postLeaveApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_APPROVE_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationApproveSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATION_CONFIRM_CALL](state) {
		return update(state, {
			getLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_CONFIRM_DONE](state, { payload: { res } }) {
		return update(state, {
			leaveApplicationConfirm: { $set: res },
			getLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_CONFIRM_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEAVE_APPLICATION_CONFIRM_CALL](state) {
		return update(state, {
			postLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_CONFIRM_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplicationConfirm: { $set: res },
			postLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_CONFIRM_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationConfirmSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEAVE_APPLICATION_WITHDRAW_CALL](state) {
		return update(state, {
			postLeaveApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_WITHDRAW_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplicationWithdraw: { $set: res },
			postLeaveApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_WITHDRAW_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationWithdrawSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL](state) {
		return update(state, {
			getLeaveApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE](state, { payload: { res } }) {
		return update(state, {
			getLeaveApplicationsApproverView: { $set: res },
			getLeaveApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationsApproverViewSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL](state) {
		return update(state, {
			postLeaveApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplicationWithdrawApproval: { $set: res },
			postLeaveApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationWithdrawApprovalSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_AVAILABLE_LEAVE_TYPES_CALL](state) {
		return update(state, {
			getAvailableLeaveTypesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_AVAILABLE_LEAVE_TYPES_DONE](state, { payload: { res } }) {
		return update(state, {
			getAvailableLeaveTypes: { $set: res },
			getAvailableLeaveTypesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_AVAILABLE_LEAVE_TYPES_FAIL](state, { payload }) {
		return update(state, {
			getAvailableLeaveTypesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL](state) {
		return update(state, {
			getLeaveApplicationsTotalMinutesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE](state, { payload: { res } }) {
		return update(state, {
			getLeaveApplicationsTotalMinutes: { $set: res },
			getLeaveApplicationsTotalMinutesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationsTotalMinutes: { $set: 0 },
			getLeaveApplicationsTotalMinutesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_ENTITLEMENT_CALL](state) {
		return update(state, {
			getLeaveEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_ENTITLEMENT_DONE](state, { payload: { res } }) {
		return update(state, {
			getLeaveEntitlement: { $set: res },
			getLeaveEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_ENTITLEMENT_FAIL](state, { payload }) {
		return update(state, {
			getLeaveEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL](state) {
		return update(state, {
			getLeaveEntitlementByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE](state, { payload: { res } }) {
		return update(state, {
			getLeaveEntitlementByLeaveType: { $set: res },
			getLeaveEntitlementByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL](state, { payload }) {
		return update(state, {
			getLeaveEntitlementByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL](state) {
		return update(state, {
			getLeaveApplicationAttachmentDownloadLinkSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE](state, { payload: { res, id } }) {
		return update(state, {
			getLeaveApplicationAttachmentDownloadLink: {
				[id]: { $set: res },
			},
			getLeaveApplicationAttachmentDownloadLinkSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL](state, { payload }) {
		return update(state, {
			getLeaveApplicationAttachmentDownloadLinkSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL](state) {
		return update(state, {
			leaveApplicationsInfoByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE](state, { payload: { res, leaveType } }) {
		return update(state, {
			leaveApplicationsInfoByLeaveType: {
				[leaveType]: { $set: res },
			},
			leaveApplicationsInfoByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL](state, { payload }) {
		return update(state, {
			leaveApplicationsInfoByLeaveTypeSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_CUSTOM_LEAVE_TYPE_RULES_CALL](state) {
		return update(state, {
			getCustomLeaveTypeRulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_CUSTOM_LEAVE_TYPE_RULES_DONE](state, { payload: { res } }) {
		return update(state, {
			customLeaveTypeRules: { $set: res },
			getCustomLeaveTypeRulesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_CUSTOM_LEAVE_TYPE_RULES_FAIL](state, { payload }) {
		return update(state, {
			getCustomLeaveTypeRulesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL](state) {
		return update(state, {
			postLeaveApplicationGrantNewEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE](state, { payload: { res } }) {
		return update(state, {
			postLeaveApplicationGrantNewEntitlement: { $set: res },
			postLeaveApplicationGrantNewEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL](state, { payload }) {
		return update(state, {
			postLeaveApplicationGrantNewEntitlementSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
