import { combineActions, handleActions } from 'redux-actions';
import produce from 'immer';
import update from 'immutability-helper';
import * as types from '@src/store/types';

const initialState = {
	videoObject: {},
};

export default handleActions({
	[types.GET_FILE_VIDEO_LINK_CALL](state) {
		return update(state, {
			videoObject: { $set: {} },
		});
	},
	[types.GET_FILE_VIDEO_LINK_DONE](state, { payload }) {
		return update(state, {
			videoObject: { $set: payload },
		});
	},
}, initialState);
