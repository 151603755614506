import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as actions from '@src/store/actions';
import * as types from '@src/store/types';
import { ui, comment } from '@src/services';
import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const addCourseDiscussionCall = (action$) => action$.pipe(
	ofType(types.ADD_COURSE_DISCUSSION_CALL),
	switchMap(({ payload }) => fromPromise(comment.addCourseDiscussion(payload)).pipe(
		switchMap(() => {
			ui.toastShow({ message: 'course:discussions:add_discussion_success', type: TOAST_TYPE_SUCCESS });
			if (payload.onSuccess) payload.onSuccess();
			return [
				actions.getCourseDiscussionsBeforeCall({
					course_id: payload.course_id,
					discussionType: payload.type,
				}),
				actions.addCourseDiscussionDone({
					course_id: payload.course_id,
				}),
			];
		}),
		catchError(() => {
			ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });

			return [actions.addCourseDiscussionFail(payload)];
		}),
	)),
);

export const addCourseDiscussionsBeforeCall = (action$) => action$.pipe(
	ofType(types.ADD_COURSE_DISCUSSIONS_BEFORE_CALL),
	switchMap(({ payload }) => fromPromise(comment.addCourseDiscussionBefore(payload)).pipe(
		switchMap(() => {
			ui.toastShow({ message: 'course:discussions:add_discussion_success', type: TOAST_TYPE_SUCCESS });
			if (payload.onSuccess) payload.onSuccess();
			return [
				actions.getCourseDiscussionsBeforeCall({
					course_id: payload.course_id,
					discussionType: payload.type,
				}),
				actions.getCourseDiscussionsBeforeCall({
					course_id: payload.course_id,
				}),
				actions.addCourseDiscussionDone({
					course_id: payload.course_id,
				}),
			];
		}),
		catchError(() => {
			ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });

			return [actions.addCourseDiscussionReplyDone(payload)];
		}),
	)),
);

export const addCourseDiscussionReplyCall = (action$) => action$.pipe(
	ofType(types.ADD_COURSE_DISCUSSION_REPLY_CALL),
	switchMap(({ payload }) => fromPromise(comment.addCourseDiscussionReply(payload)).pipe(
		switchMap(() => {
			ui.toastShow({ message: '你已成功回覆', type: TOAST_TYPE_SUCCESS });
			if (payload.onSuccess) payload.onSuccess();
			return [
				actions.getCourseDiscussionsBeforeCall({
					course_id: payload.course_id,
					discussionType: payload.type,
				}),
				actions.addCourseDiscussionReplyDone({
					comment_id: payload.comment_id,
				}),
			];
		}),
		catchError(() => {
			ui.toastShow({ message: 'errors:serverError', type: TOAST_TYPE_ERROR });
			return [actions.addCourseDiscussionReplyFail(payload)];
		}),
	)),
);

export const getCourseDiscussionsBeforeCall = (action$) => action$.pipe(
	ofType(types.GET_COURSE_DISCUSSIONS_BEFORE_CALL),
	mergeMap(({ payload }) => fromPromise(comment.getCourseDiscussionsBefore(payload)).pipe(
		switchMap(res => [
			actions.getCourseDiscussionsBeforeDone({
				course_id: payload.course_id,
				response: res,
			}),
		]),
		catchError(err => [actions.getCourseDiscussionsBeforeFail(err)]),
	)),
);

export const getCourseDiscussionsCall = (action$) => action$.pipe(
	ofType(types.GET_COURSE_DISCUSSIONS_CALL),
	switchMap(({ payload }) => fromPromise(comment.getCourseDiscussions(payload)).pipe(
		switchMap(res => [
			actions.getCourseDiscussionsDone({
				course_id: payload.course_id,
				response: res,
			}),
		]),
		catchError(err => [actions.getCourseDiscussionsFail(err)]),
	)),
);
