import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_LEARNING_AREAS_CALL,
	GET_LEARNING_AREAS_DONE,
	GET_LEARNING_AREAS_FAIL,
	GET_LEARNING_AREAS_BY_SHORT_LINK_CALL,
	GET_LEARNING_AREAS_BY_SHORT_LINK_DONE,
	GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL,
	GET_LEARNING_AREAS_PATHS_CALL,
	GET_LEARNING_AREAS_PATHS_DONE,
	GET_LEARNING_AREAS_PATHS_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	learningAreas: [],
	learningAreasByKey: {},
	getLearningAreasSideEffect: defaultSideEffectState,
	getLearningAreasDetailSideEffect: defaultSideEffectState,
	learningAreaDetail: {},
	learningAreasPaths: [],
	getLearningAreasPathsSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_LEARNING_AREAS_CALL](state) {
		return update(state, {
			getLearningAreasSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_DONE](state, { payload: { res, key } }) {
		return update(state, {
			...key ? {
				learningAreasByKey: {
					$set: {
						...state.learningAreasByKey,
						[key]: res,
					},
				},
			} : {
				learningAreas: { $set: res },
			},
			getLearningAreasSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_FAIL](state, { payload }) {
		return update(state, {
			getLearningAreasSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_LEARNING_AREAS_BY_SHORT_LINK_CALL](state) {
		return update(state, {
			getLearningAreasDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_BY_SHORT_LINK_DONE](state, { payload: { res } }) {
		return update(state, {
			learningAreaDetail: { $set: res },
			getLearningAreasDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL](state, { payload }) {
		return update(state, {
			getLearningAreasDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
	[GET_LEARNING_AREAS_PATHS_CALL](state) {
		return update(state, {
			getLearningAreasPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_PATHS_DONE](state, { payload: { res } }) {
		return update(state, {
			learningAreasPaths: { $set: res },
			getLearningAreasPathsSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_LEARNING_AREAS_PATHS_FAIL](state, { payload }) {
		return update(state, {
			getLearningAreasPathsSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
