import styled from 'styled-components';
import EmailIcon from '@src/assets/images/gmail.svg';

export const Wrapper = styled.div`
  background: rgba(42, 42, 42, 0.61);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const Box = styled.div`
  width: 460px;
  border-radius: 2px;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 25px;
  position: relative;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 80px 0 0;
    background-color: #f3f3f1;
  }
`;

export const IconList = styled.div`
  display: flex;
  text-align: center;
  margin: 26px;

  .item {
    flex: 1;
    cursor: pointer;
  }

  .icon {
    display: inline-block;
    width: 45px;
    height: 45px;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
  .email {
    background: url(${EmailIcon}) no-repeat center center/contain;
  }
`;

export const UrlList = styled.div`
  display: flex;
  align-items: center;

  .url {
    height: 46px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #a9a9a9;
    padding: 0 20px;
    flex: 1;
    overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
  }

  .copy-btn {
    width: 76px;
    height: 46px;
    line-height: 1.5;
    border-radius: 2px;
    border: 1px solid #3091fd;
    color: #3091fd;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    .url {
      width: 80%;
    }
    .copy-btn {
      width: 80%;
      margin: 24px auto;
    }
  }
`;
