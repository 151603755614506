import { createAction } from 'redux-actions';

import {
	AFFILIATE_MARKETING_EVENT_TRIGGER_CALL,
	AFFILIATE_MARKETING_EVENT_TRIGGER_DONE,
	AFFILIATE_MARKETING_EVENT_TRIGGER_FAIL,
} from '@src/store/types/affiliateMarketing';

export const affiliateMarketingEventTriggerCall = createAction(AFFILIATE_MARKETING_EVENT_TRIGGER_CALL);
export const affiliateMarketingEventTriggerDone = createAction(AFFILIATE_MARKETING_EVENT_TRIGGER_DONE);
export const affiliateMarketingEventTriggerFail = createAction(AFFILIATE_MARKETING_EVENT_TRIGGER_FAIL);
