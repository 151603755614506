import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { homework } from '@src/services';
import * as actions from '@src/store/actions';
import * as selectors from '@src/store/selectors';
import * as types from '@src/store/types';

const PAGE_SIZE = 9;

export const getCourseHomeworksCall = (action$, store) => action$.pipe(
	ofType(types.GET_COURSE_HOMEWORKS_CALL),
	switchMap(({ payload }) => fromPromise(homework.getCourseHomeworks(payload)).pipe(
		switchMap(res => [
			actions.getCourseHomeworksDone({
				course_id: payload.course_id,
				unit_id: payload.data.unit_id,
				response: {
					...res,
					pagination: {
						...res.pagination,
						page: payload.data.page,
						page_size: payload.data.page_size,
					},
				},
			}),
		]),
		catchError(err => [
			actions.getCourseHomeworksFail({
				course_id: payload.course_id,
				unit_id: payload.data.unit_id,
				err,
			}),
		]),
	)),
);

export const addCourseHomeworkCall = (action$, store) => action$.pipe(
	ofType(types.ADD_COURSE_HOMEWORK_CALL),
	switchMap(({ payload }) => fromPromise(homework.addCourseHomework(payload)).pipe(
		switchMap(res => {
			if (payload.callback) {
				payload.callback();
			}

			const homeworks = selectors.getHomeworks(store.value);
			const page = homeworks?.pagination?.page || 0;
			const page_size = homeworks?.pagination?.page_size || PAGE_SIZE;

			return [
				actions.getCourseHomeworksCall({
					course_id: payload.course_id,
					data: {
						unit_id: payload.data.unit_id,
						page,
						page_size,
					},
				}),
				actions.getCurrentCourseCallUpdate({
					courseId: payload.course_id,
				}),
				actions.addCourseHomeworkDone({
					course_id: payload.course_id,
					data: payload.data,
					response: res,
				}),
			];
		}),
		catchError(err => [
			actions.addCourseHomeworkFail({
				course_id: payload.course_id,
				data: payload.data,
				err,
			}),
		]),
	)),
);

export const updateCourseHomeworkCall = (action$, store) => action$.pipe(
	ofType(types.UPDATE_COURSE_HOMEWORK_CALL),
	switchMap(({ payload }) => fromPromise(homework.updateCourseHomework(payload)).pipe(
		switchMap(res => {
			if (payload.callback) {
				payload.callback();
			}

			return [
				actions.getCourseHomeworkDetailCall({
					param: {
						assignment_id: payload.assignment_id,
					},
				}),
				actions.updateCourseHomeworkDone({
					assignment_id: payload.assignment_id,
					data: payload.data,
					response: res,
				}),
			];
		}),
		catchError(err => [
			actions.updateCourseHomeworkFail({
				assignment_id: payload.assignment_id,
				data: payload.data,
				err,
			}),
		]),
	)),
);

export const getCourseHomeworkDetailCall = (action$, store) => action$.pipe(
	ofType(types.GET_COURSE_HOMEWORK_DETAIL_CALL),
	switchMap(({ payload }) => fromPromise(homework.getCourseHomeworkDetail(payload)).pipe(
		switchMap(res => [
			actions.getCourseHomeworkDetailDone({
				assignment_id: payload.param.assignment_id,
				response: res,
			}),
		]),
		catchError(err => [
			actions.getCourseHomeworkDetailFail({
				assignment_id: payload.param.assignment_id,
				err,
			}),
		]),
	)),
);

export const getCourseHomeworkRepliesCall = (action$, store) => action$.pipe(
	ofType(types.GET_COURSE_HOMEWORK_REPLIES_CALL),
	switchMap(({ payload }) => fromPromise(homework.getCourseHomeworkReplies(payload)).pipe(
		switchMap(res => [
			actions.getCourseHomeworkRepliesDone({
				post_id: payload.post_id,
				response: res,
			}),
		]),
		catchError(err => [
			actions.getCourseHomeworkRepliesFail({
				post_id: payload.post_id,
				response: err,
			}),
		]),
	)),
);

export const addCourseHomeworkReplyCall = (action$, store) => action$.pipe(
	ofType(types.ADD_COURSE_HOMEWORK_REPLY_CALL),
	switchMap(({ payload }) => fromPromise(homework.addCourseHomeworkReply(payload)).pipe(
		switchMap(res => {
			if (payload.callback) {
				payload.callback();
			}

			return [
				actions.getCourseHomeworkRepliesCall({
					param: { post_id: payload.post_id },
				}),
				actions.addCourseHomeworkReplyDone({
					post_id: payload.post_id,
					response: res,
				}),
			];
		}),
		catchError(err => [
			actions.addCourseHomeworkReplyFail({
				post_id: payload.post_id,
				response: err,
			}),
		]),
	)),
);
