import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concatMap, mergeMap, map, catchError, switchMap, exhaustMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { upload } from '@src/services';
import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const uploadCall = action$ => action$.pipe(
	ofType(types.UPLOAD_CALL),
	concatMap(({ payload, payload: { fileData } }) => fromPromise(upload.uploadFile(payload)).pipe(
		concatMap(res => {
			const { id } = res;
			const { uiSuccess, uiLinkSuccess, uiError } = fileData;

			uiSuccess(res);

			return [
				actions.uploadDone(res),
				actions.uploadFileLinkCall({
					id,
					uiSuccess: uiLinkSuccess,
					uiError,
				}),
			];
		}),
		catchError(err => {
			const { uiError } = fileData;

			uiError({ err });

			return of(actions.uploadFail(err));
		}),
	)),
);

export const uploadFileLinkCall = action$ => action$.pipe(
	ofType(types.UPLOAD_FILE_LINK_CALL),
	mergeMap(({ payload: { id, uiSuccess, uiError } }) => fromPromise(upload.uploadFileLink({ id })).pipe(
		map(res => {
			uiSuccess({ ...res, id });

			return actions.uploadFileLinkDone(res);
		}),
		catchError(err => {
			uiError({ err, id });

			return of(actions.uploadFileLinkFail(err));
		}),
	)),
);

export const getFileVideoUrlCall = action$ => action$.pipe(
	ofType(types.GET_FILE_VIDEO_LINK_CALL),
	exhaustMap(({ payload: { id } }) => fromPromise(upload.getFileVideoUrl({ id })).pipe(
		map(res => actions.getFileVideoUrlDone({ ...res, id })),
		catchError(err => of(actions.getFileVideoUrlFail(err))),
	)),
);
