/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';

import TextField from '@src/components/textField';
import { IconComponent } from '@src/icons';
import * as actionCreators from '@src/store/actions';
import * as selectors from '@src/store/selectors';
import { I18N_TO_LANG } from '@src/const/common';

import ModalHeader from '../modalHeader';
import {
	Wrapper,
	Content,
	Description,
	EmailInputWrapper,
	BlueBtn,
	ResendInfo,
} from './Styled';

const COUNT_DOWN_TIME = 60;

const ResendCertificationLetterModal = ({
	t,
	actions,
	onClose,
	modalData,
}) => {
	const [targetClass, setTargetClass] = useState();
	const emailResendState = useSelector(selectors.getEmailResendState);
	const [isResendSuccess, setIsResendSuccess] = useState(true);
	const [countDown, setCountDown] = useState(-1);
	const [isDisabled, setIsDisabled] = useState(false);
	const { i18n } = useTranslation();

	useEffect(() => () => {
		actions.emailResendStateReset();
  }, []); // eslint-disable-line

	useEffect(() => {
		if (emailResendState === 'SUCCESS') {
			setIsResendSuccess(true);
			setCountDown(COUNT_DOWN_TIME);
		}
	}, [emailResendState]);

	const handleResend = () => {
		if (!isDisabled) {
			actions.emailResendCall({
				...modalData,
				language: I18N_TO_LANG[i18n.language.toLocaleLowerCase()],
			});
		}
	};

	const handleClickStart = e => {
		setTargetClass(e.target.className);
	};

	const handleClickEnd = e => {
		if (
			e.target.id === 'modal-wrap' &&
      window.innerWidth > 768 &&
      targetClass === e.target.className
		) {
			onClose();
		}
		setTargetClass();
	};

	useEffect(() => {
		if (isResendSuccess) {
			setCountDown(COUNT_DOWN_TIME);
			setIsDisabled(true);
			const countDownId = setInterval(() => {
				setCountDown(prev => {
					if (prev > 0) {
						return prev - 1;
					}
					clearInterval(countDownId);
					setIsDisabled(false);
					setIsResendSuccess(false);
				});
			}, 1000);
		}
	}, [isResendSuccess]);

	return (
		<Wrapper
			onMouseDown={e => handleClickStart(e)}
			onMouseUp={e => handleClickEnd(e)}
			id="modal-wrap"
		>
			<Content onClick={e => e.stopPropagation()}>
				<ModalHeader title={t('checkEmail:title')} onClose={onClose} />
				{emailResendState === 'SUCCESS' && (
					<ResendInfo>
						{t('checkEmail:resend_info', { email: modalData?.email })}
					</ResendInfo>
				)}
				<Description>
					<div>{t('checkEmail:description')}</div>
					<div>{t('checkEmail:hint')}</div>
				</Description>
				<EmailInputWrapper>
					<TextField
						required
						inputType="email"
						// eslint-disable-next-line react/prop-types
						InputValue={modalData?.email}
						isDisabled
						autoComplete="email"
						onInputValueChange={() => null}
						withError={false}
						icon={(
							<IconComponent
								size={22}
								color="#dcdcdc"
								icon="login_form_email"
							/>
						)}
					/>
				</EmailInputWrapper>
				<BlueBtn $isDisabled={isDisabled} onClick={handleResend}>
					{
						countDown >= 0 ? (
							`${t('checkEmail:reSend')} (${countDown})`
						) : (
							t('checkEmail:reSend')
						)
					}
				</BlueBtn>
			</Content>
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	modalData: state.ui.modalData,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actionCreators }, dispatch),
});

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(ResendCertificationLetterModal),
);
