import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '@src/store/actions';
import LoginModal from './components/loginModal';
import SelectAuth from './components/loginModal/SelectAuthModal';
import RegisterModal from './components/registerModal';
import ResendCertificationLetterModal from './components/resendCertificationLetterModal';
import ForgetPasswordModal from './components/forgetPasswordModal';
import ShareModal from './components/shareModal';
import ResetPasswordModal from './components/resetPasswordModal';
import ConflictEmailModal from './components/conflictEmailModal';

const MODAL = {
	selectAuth: SelectAuth,
	login: LoginModal,
	register: RegisterModal,
	resendCertificationLetter: ResendCertificationLetterModal,
	forgetpassword: ForgetPasswordModal,
	shareCourse: ShareModal,
	shareHomework: ShareModal,
	resetPassword: ResetPasswordModal,
	conflictEmail: ConflictEmailModal,
};

export function ModalContainer({ modalType, actions }) {
	if (!modalType) {
		return null;
	}

	function handleCloseModal() {
		actions.setModalType({ type: null });
		actions.setModal({
			modalData: null,
		});
		actions.clearErrorMessage();
		actions.clearStash();
	}

	const Comp = MODAL[modalType];

	// TODO: 把modal的 wrapper跟closeBtn 拉出來
	return <Comp onClose={handleCloseModal} />;
}

function mapStateToProps(state) {
	return {
		modalType: state.ui.modalType,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
