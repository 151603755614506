import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_SCHOOL_ANNOUNCEMENTS_CALL,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL,
} from '@src/store/types/schoolAnnouncement';

import {
	getSchoolAnnouncementsDone,
	getSchoolAnnouncementsFail,
	getSchoolAnnouncementDetailDone,
	getSchoolAnnouncementDetailFail,
	getSchoolAnnouncementByIndexDone,
	getSchoolAnnouncementByIndexFail,
	postSchoolAnnouncementReadDone,
	postSchoolAnnouncementReadFail,
	postSchoolAnnouncementReadAllDone,
	postSchoolAnnouncementReadAllFail,
} from '@src/store/actions/schoolAnnouncement';
import {
	getSchoolAnnouncements,
	getSchoolAnnouncementDetail,
	getSchoolAnnouncementByIndex,
	postSchoolAnnouncementRead,
	postSchoolAnnouncementReadAll,
} from '@src/services/schoolAnnouncement';

export const getSchoolAnnouncementsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SCHOOL_ANNOUNCEMENTS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getSchoolAnnouncements(payload)).pipe(
			switchMap(res => of(getSchoolAnnouncementsDone({
				res, isUnread: payload.unread_only, isModalList: payload.is_modal_list,
			}))),
			catchError(error => of(getSchoolAnnouncementsFail({ error }))),
		),
	),
);

export const getSchoolAnnouncementDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getSchoolAnnouncementDetail(payload)).pipe(
			switchMap(res => of(getSchoolAnnouncementDetailDone({ res }))),
			catchError(error => of(getSchoolAnnouncementDetailFail({ error }))),
		),
	),
);

export const getSchoolAnnouncementByIndexEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL),
	switchMap(
		({ payload }) => fromPromise(getSchoolAnnouncementByIndex(payload)).pipe(
			switchMap(res => of(getSchoolAnnouncementByIndexDone({ res }))),
			catchError(error => of(getSchoolAnnouncementByIndexFail({ error }))),
		),
	),
);

export const postSchoolAnnouncementReadEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SCHOOL_ANNOUNCEMENT_READ_CALL),
	switchMap(
		({ payload }) => fromPromise(postSchoolAnnouncementRead(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postSchoolAnnouncementReadDone({ res }),
				);
			}),
			catchError(error => of(
				postSchoolAnnouncementReadFail({ error }),
			)),
		),
	),
);

export const postSchoolAnnouncementReadAllEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL),
	switchMap(
		({ payload }) => fromPromise(postSchoolAnnouncementReadAll()).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					postSchoolAnnouncementReadAllDone({ res }),
				);
			}),
			catchError(error => of(
				postSchoolAnnouncementReadAllFail({ error }),
			)),
		),
	),
);
