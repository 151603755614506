import styled from 'styled-components';

export const BlueBtn = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 2px;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  cursor: pointer;
  background: #3091fd;
  &:hover {
    background: #005abe;
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  background: rgba(42, 42, 42, 0.61);
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow-y: scroll;
`;

export const Content = styled.div`
  width: 400px;
  border-radius: 2px;
  box-shadow: rgba(69, 65, 78, 0.08) 0px 1px 15px 0px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 24px;
  position: relative;
  margin: 100px;
  display: flex;
  flex-direction: column;

  .des {
    font-size: 14px;
    text-align: center;
  }
  .info {
    font-size: 13px;
    font-weight: 300;
    color: #222;
    text-align: center;
  }
  .label {
    font-size: 16px;
    color: #222222;
    margin: 12px 0;
  }
  .errorMessageBox {
    div {
      color: #d0021b;
      margin: 10px 0;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 80px;
    background-color: #f3f3f1;
  }
`;
