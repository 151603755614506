import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_KNOWLEDGE_BASE_PAGES_CALL,
	POST_KNOWLEDGE_BASE_PAGE_CALL,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL,
	DELETE_KNOWLEDGE_BASE_PAGE_CALL,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL,
	GET_KNOWLEDGE_BASE_PAGE_SEO_CALL,
	PATCH_KNOWLEDGE_BASE_PAGE_CALL,
	POST_KNOWLEDGE_BASE_PAGE_READ_CALL,
} from '@src/store/types/knowledgeBasePage';

import {
	getKnowledgeBasePagesDone,
	getKnowledgeBasePagesFail,
	postKnowledgeBasePageDone,
	postKnowledgeBasePageFail,
	postKnowledgeBasePagesOrderDone,
	postKnowledgeBasePagesOrderFail,
	deleteKnowledgeBasePageDone,
	deleteKnowledgeBasePageFail,
	getKnowledgeBasePageDetailCall,
	getKnowledgeBasePageDetailDone,
	getKnowledgeBasePageDetailFail,
	getKnowledgeBasePageSEODone,
	getKnowledgeBasePageSEOFail,
	patchKnowledgeBasePageDone,
	patchKnowledgeBasePageFail,
	postKnowledgeBasePageReadDone,
	postKnowledgeBasePageReadFail,
} from '@src/store/actions/knowledgeBasePage';
import { toastShowCall } from '@src/store/actions/ui';
import {
	getKnowledgeBasePages,
	postKnowledgeBasePage,
	postKnowledgeBasePagesOrder,
	deleteKnowledgeBasePage,
	getKnowledgeBasePageDetail,
	getKnowledgeBasePageSEO,
	patchKnowledgeBasePage,
	postKnowledgeBasePageRead,
} from '@src/services/knowledgeBasePage';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@src/const/common';

export const getKnowledgeBasePagesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_KNOWLEDGE_BASE_PAGES_CALL),
	mergeMap(
		({ payload }) => fromPromise(getKnowledgeBasePages(payload)).pipe(
			switchMap(res => of(getKnowledgeBasePagesDone({ res, order: payload.order_by }))),
			catchError(error => of(getKnowledgeBasePagesFail({ error }))),
		),
	),
);

export const postKnowledgeBasePageEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_KNOWLEDGE_BASE_PAGE_CALL),
	switchMap(
		({ payload }) => fromPromise(postKnowledgeBasePage(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:created_success', type: TOAST_TYPE_SUCCESS }),
					postKnowledgeBasePageDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:created_fail', type: TOAST_TYPE_ERROR }),
				postKnowledgeBasePageFail({ error }),
			)),
		),
	),
);

export const postKnowledgeBasePagesOrderEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL),
	switchMap(
		({ payload }) => fromPromise(postKnowledgeBasePagesOrder(payload)).pipe(
			switchMap(res => of(
				postKnowledgeBasePagesOrderDone({ res }),
			)),
			catchError(error => of(
				postKnowledgeBasePagesOrderFail({ error }),
			)),
		),
	),
);

export const deleteKnowledgeBasePageEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(DELETE_KNOWLEDGE_BASE_PAGE_CALL),
	switchMap(
		({ payload }) => fromPromise(deleteKnowledgeBasePage(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({ message: 'done:deleted_success', type: TOAST_TYPE_SUCCESS }),
					deleteKnowledgeBasePageDone({ res }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:deleted_fail', type: TOAST_TYPE_ERROR }),
				deleteKnowledgeBasePageFail({ error }),
			)),
		),
	),
);

export const getKnowledgeBasePageDetailEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL),
	switchMap(
		({ payload }) => fromPromise(getKnowledgeBasePageDetail(payload)).pipe(
			switchMap(res => of(getKnowledgeBasePageDetailDone({ res }))),
			catchError(error => of(getKnowledgeBasePageDetailFail({ error }))),
		),
	),
);

export const getKnowledgeBasePageSEOEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_KNOWLEDGE_BASE_PAGE_SEO_CALL),
	switchMap(
		() => fromPromise(getKnowledgeBasePageSEO()).pipe(
			switchMap(res => of(getKnowledgeBasePageSEODone({ res }))),
			catchError(error => of(getKnowledgeBasePageSEOFail({ error }))),
		),
	),
);

export const patchKnowledgeBasePageEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(PATCH_KNOWLEDGE_BASE_PAGE_CALL),
	switchMap(
		({ payload }) => fromPromise(patchKnowledgeBasePage(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess(res);
				return of(
					toastShowCall({
						message: payload.isUpdate ? 'done:saved_success' : 'done:created_success',
						type: TOAST_TYPE_SUCCESS,
					}),
					patchKnowledgeBasePageDone({ res }),
					getKnowledgeBasePageDetailCall({ id: payload.id }),
				);
			}),
			catchError(error => of(
				toastShowCall({ message: 'fail:updated_fail', type: TOAST_TYPE_ERROR }),
				patchKnowledgeBasePageFail({ error }),
			)),
		),
	),
);

export const postKnowledgeBasePageReadEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_KNOWLEDGE_BASE_PAGE_READ_CALL),
	switchMap(
		({ payload }) => fromPromise(postKnowledgeBasePageRead(payload)).pipe(
			switchMap(res => of(
				postKnowledgeBasePageReadDone({ res }),
			)),
			catchError(error => of(
				postKnowledgeBasePageReadFail({ error }),
			)),
		),
	),
);
