import api from '@src/api';

export const getCourseHomeworks = (payload) =>
  api.get(`course/${payload.course_id}/assignments`, payload.data, {
    hasPagination: true,
  });

export const addCourseHomework = (payload) =>
  api.post(`course/${payload.course_id}/assignment`, payload.data);

export const updateCourseHomework = (payload) =>
  api.post(`assignment/${payload.assignment_id}`, payload.data);

export const getCourseHomeworkDetail = ({ param, ssrHeaders }) =>
  api.get(`assignment/${param.assignment_id}`, undefined, { ssrHeaders });

export const getCourseHomeworkReplies = ({ param, ssrHeaders }) =>
  api.get(`assignment/${param.post_id}/replies`, undefined, { ssrHeaders });

export const addCourseHomeworkReply = (payload) =>
  api.post(`assignment/${payload.post_id}/reply`, payload.data);
