/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import queryString from 'query-string';

import api from '@src/api';

export const addProductToCart = (product_id, payload) => (
	api.post(`shopping-carts/products/${product_id}`, payload)
);

export const getPurchase = (purchase_id, payload) => api.get(`purchase/${purchase_id}`, payload);

export const getPurchaseList = payload => api.get('purchases', payload, { hasPagination: true });

export const getPurchaseCourseList = payload => api.get('purchase/courses', payload, { hasPagination: true });

export const getPurchaseCourseTeacherList = payload => api.get('purchase/courses/teachers', payload);

export const cancelPurchaseOrder = (purchase_id, payload) => (
	api['delete'](`purchase/${purchase_id}?${queryString.stringify(payload)}`)
);

export const buyTheFreeCourse = payload => api.post(`shopping-carts/product/${payload.product_id}/free`);

export const getPurchaseEvents = ({ sortType }) => api.get('purchase/tickets', { sort: sortType });

export const getPurchaseWebinars = ({ sortType, ssrHeaders }) => (
	api.get('purchase/webinars', { sort: sortType }, { ssrHeaders })
);
