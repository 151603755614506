import api from '@src/api';

export const getRegisterFormFieldsByEventId = ({ eventId }: {
	eventId: string;
}) => api.get(`offline-events/${eventId}/register-form-fields`);

export const getRegisterFormFieldsByWebinarId = ({ webinarId }: {
	webinarId: string;
}) => api.get(`webinars/${webinarId}/register-form-fields`);

interface IFormData {
	field_id: string;
	value: string;
}

export const saveRegisterFormBySlot = ({ shortLink, slotId, formDataArray }: {
	shortLink: string;
	slotId: string;
	formDataArray: IFormData[];
}) => api.post(`offline-events/${shortLink}/slots/${slotId}/register-form/`, formDataArray); // slash in the end of url is required.

export const saveWebinarRegisterFormBySlot = ({ shortLink, slotId, formDataArray }: {
	shortLink: string;
	slotId: string;
	formDataArray: IFormData[];
}) => api.post(`webinars/${shortLink}/slots/${slotId}/register-form/`, formDataArray); // slash in the end of url is required.

export const getAreaInfo = () => api.get('checkout-form/area');

export const getPurchaseInfoFields = () => api.get('checkout-form/purchase');

export const getCheckoutForm = () => api.get('checkout-form/');

export const saveCheckoutFormPurchase = payload => api.post('checkout-form/purchase', payload);

export const getRecipientInfo = () => api.get('checkout-form/recipient_info');
