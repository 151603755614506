import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { theme } from '@src/services';
import * as actions from '@src/store/actions';
import * as types from '@src/store/types';

export const getThemesCall = (action$, store) => action$.pipe(
	ofType(types.GET_THEMES_CALL),
	switchMap(({ payload }) => fromPromise(theme.getThemes(payload)).pipe(
		switchMap(res => of(
			actions.getThemesDone(res),
		)),
		catchError(err => of(actions.getThemesFail(err))),
	)),
);

export const updateThemesCall = (action$, store) => action$.pipe(
	ofType(types.UPDATE_THEMES_CALL),
	switchMap(({ payload }) => fromPromise(theme.updateThemes(payload)).pipe(
		switchMap(res => [actions.updateThemesDone(res)]),
		catchError(err => of(actions.updateThemesFail(err))),
	)),
);
