import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import * as selectors from '@src/store/selectors';

import classes from './styles.module.less';

function OauthButton(props) {
  const { className, name, type, isBinded = false, onClick } = props;
  const { themes: { closed_web } } = useSelector(selectors.getThemeSelector);
  if ((type === 'facebook' || type === 'google') && closed_web) return null;
  return (
    <button
      className={cx(classes.oauthBtn, className, isBinded && classes.binded)}
      onClick={onClick}
      disabled={isBinded}
    >
      <i className={cx(classes.icon, classes[type])} />
      <span className={classes.oauthBtnText}>{name}</span>
    </button>
  );
}

OauthButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['google', 'facebook', 'line']).isRequired,
  isBinded: PropTypes.bool,
};

export default OauthButton;
