// eslint-disable-next-line import/no-cycle
import api from '@src/api';
import { Observable } from 'rxjs';
import { Action } from 'redux-actions';

interface IPostKnowledgeBaseSpace {
	id?: string;
	description?: string;
	name?: string;
}

export const getKnowledgeBaseSpaces = (payload: {
	page: number;
	page_size: number;
}): Observable<Action> => (
	api.get('knowledge-base/spaces/', payload, { hasPagination: true })
);

export const postKnowledgeBaseSpace = (payload: IPostKnowledgeBaseSpace): Observable<Action> => (
	api.post('knowledge-base/spaces/', payload)
);

export const deleteKnowledgeBaseSpace = (payload: { id: string }): Observable<Action> => (
	api['delete'](`knowledge-base/spaces/${payload.id}`)
);

export const getKnowledgeBaseSpaceDetail = (payload: { id: string }): Observable<Action> => (
	api.get(`knowledge-base/spaces/${payload.id}`)
);

export const patchKnowledgeBaseSpace = (payload: IPostKnowledgeBaseSpace): Observable<Action> => (
	api.patch(`knowledge-base/spaces/${payload.id}`, payload)
);
