/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useRef } from 'react';
import { Wrapper, Input } from './Styled';

export default function TextField({
	required = false,
	inputType = 'text',
	placeholder,
	icon,
	withError,
	InputValue = '',
	onInputValueChange,
	minLength,
	autoComplete,
	autoFocus = false,
	isDisabled = false,
	...props
}) {
	const inputRef = useRef();

	useEffect(() => {
		if (autoFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [autoFocus]);

	return (
		<Wrapper
			withError={withError}
			$isDisabled={isDisabled}
			{...props}
		>
			<Input
				ref={inputRef}
				required={required}
				type={inputType}
				minLength={minLength}
				value={InputValue}
				placeholder={placeholder}
				autoComplete={autoComplete}
				disabled={isDisabled}
				onChange={e => onInputValueChange(e.target.value)}
			/>
			{icon && icon}
		</Wrapper>
	);
}
