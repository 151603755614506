import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import {
	GET_KNOWLEDGE_BASE_PAGES_CALL,
	GET_KNOWLEDGE_BASE_PAGES_DONE,
	GET_KNOWLEDGE_BASE_PAGES_FAIL,
	POST_KNOWLEDGE_BASE_PAGE_CALL,
	POST_KNOWLEDGE_BASE_PAGE_DONE,
	POST_KNOWLEDGE_BASE_PAGE_FAIL,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL,
	DELETE_KNOWLEDGE_BASE_PAGE_CALL,
	DELETE_KNOWLEDGE_BASE_PAGE_DONE,
	DELETE_KNOWLEDGE_BASE_PAGE_FAIL,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL,
	GET_KNOWLEDGE_BASE_PAGE_SEO_CALL,
	GET_KNOWLEDGE_BASE_PAGE_SEO_DONE,
	GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL,
	PATCH_KNOWLEDGE_BASE_PAGE_CALL,
	PATCH_KNOWLEDGE_BASE_PAGE_DONE,
	PATCH_KNOWLEDGE_BASE_PAGE_FAIL,
	POST_KNOWLEDGE_BASE_PAGE_READ_CALL,
	POST_KNOWLEDGE_BASE_PAGE_READ_DONE,
	POST_KNOWLEDGE_BASE_PAGE_READ_FAIL,
} from '@src/store/types';

const defaultSideEffectState = {
	isLoading: false,
	isLoaded: false,
	error: null,
};

const initialState = {
	knowledgeBasePages: {
		data: [],
		pagination: {
			totalCount: 0,
		},
		last_read: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		last_created: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
		last_updated: {
			data: [],
			pagination: {
				totalCount: 0,
			},
		},
	},
	getKnowledgeBasePagesSideEffect: defaultSideEffectState,
	postKnowledgeBasePage: {},
	postKnowledgeBasePageSideEffect: defaultSideEffectState,
	postKnowledgeBasePagesOrder: [],
	postKnowledgeBasePagesOrderSideEffect: defaultSideEffectState,
	deleteKnowledgeBasePage: {},
	deleteKnowledgeBasePageSideEffect: defaultSideEffectState,
	knowledgeBasePageDetail: {},
	getKnowledgeBasePageDetailSideEffect: defaultSideEffectState,
	knowledgeBasePageSEO: {},
	getKnowledgeBasePageSEOSideEffect: defaultSideEffectState,
	patchKnowledgeBasePage: {},
	patchKnowledgeBasePageSideEffect: defaultSideEffectState,
	postKnowledgeBasePageRead: {},
	postKnowledgeBasePageReadSideEffect: defaultSideEffectState,
};

export default handleActions({
	[GET_KNOWLEDGE_BASE_PAGES_CALL](state) {
		return update(state, {
			getKnowledgeBasePagesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
			getKnowledgeBasePageDetailSideEffect: { $set: defaultSideEffectState },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGES_DONE](state, { payload: { res, order } }) {
		return update(state, {
			...order ? {
				knowledgeBasePages: {
					[order]: { $set: res },
				},
			} : {
				knowledgeBasePages: { $set: res },
			},
			getKnowledgeBasePagesSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGES_FAIL](state, { payload }) {
		return update(state, {
			getKnowledgeBasePagesSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_KNOWLEDGE_BASE_PAGE_CALL](state) {
		return update(state, {
			postKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGE_DONE](state, { payload: { res } }) {
		return update(state, {
			postKnowledgeBasePage: { $set: res },
			postKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGE_FAIL](state, { payload }) {
		return update(state, {
			postKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL](state) {
		return update(state, {
			postKnowledgeBasePagesOrderSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE](state, { payload: { res } }) {
		return update(state, {
			postKnowledgeBasePagesOrder: { $set: res },
			postKnowledgeBasePagesOrderSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL](state, { payload }) {
		return update(state, {
			postKnowledgeBasePagesOrderSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[DELETE_KNOWLEDGE_BASE_PAGE_CALL](state) {
		return update(state, {
			deleteKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[DELETE_KNOWLEDGE_BASE_PAGE_DONE](state, { payload: { res } }) {
		return update(state, {
			deleteKnowledgeBasePage: { $set: res },
			deleteKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[DELETE_KNOWLEDGE_BASE_PAGE_FAIL](state, { payload }) {
		return update(state, {
			deleteKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL](state) {
		return update(state, {
			getKnowledgeBasePageDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
			postKnowledgeBasePageSideEffect: { $set: defaultSideEffectState },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE](state, { payload: { res } }) {
		return update(state, {
			knowledgeBasePageDetail: { $set: res },
			getKnowledgeBasePageDetailSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL](state, { payload }) {
		return update(state, {
			getKnowledgeBasePageDetailSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[GET_KNOWLEDGE_BASE_PAGE_SEO_CALL](state) {
		return update(state, {
			getKnowledgeBasePageSEOSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
			postKnowledgeBasePageSideEffect: { $set: defaultSideEffectState },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGE_SEO_DONE](state, { payload: { res } }) {
		return update(state, {
			knowledgeBasePageSEO: { $set: res },
			getKnowledgeBasePageSEOSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL](state, { payload }) {
		return update(state, {
			getKnowledgeBasePageSEOSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[PATCH_KNOWLEDGE_BASE_PAGE_CALL](state) {
		return update(state, {
			patchKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[PATCH_KNOWLEDGE_BASE_PAGE_DONE](state, { payload: { res } }) {
		return update(state, {
			patchKnowledgeBasePage: { $set: res },
			patchKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[PATCH_KNOWLEDGE_BASE_PAGE_FAIL](state, { payload }) {
		return update(state, {
			patchKnowledgeBasePageSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},

	[POST_KNOWLEDGE_BASE_PAGE_READ_CALL](state) {
		return update(state, {
			postKnowledgeBasePageReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoading: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGE_READ_DONE](state, { payload: { res } }) {
		return update(state, {
			postKnowledgeBasePageRead: { $set: res },
			postKnowledgeBasePageReadSideEffect: { $set: {
				...defaultSideEffectState,
				isLoaded: true,
			} },
		});
	},
	[POST_KNOWLEDGE_BASE_PAGE_READ_FAIL](state, { payload }) {
		return update(state, {
			postKnowledgeBasePageReadSideEffect: { $set: {
				...defaultSideEffectState,
				error: payload.error,
			} },
		});
	},
}, initialState);
