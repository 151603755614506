import React from 'react';

const FBMessenger = ({ id, enable }: { id?: string, enable?: boolean }) => {
	if (!id || !enable) return null;
	return (
		<>
			<div id="fb-root" />
			<div id="fb-customer-chat" className="fb-customerchat" />
			<script
				dangerouslySetInnerHTML={{
					__html: `
					var chatbox = document.getElementById('fb-customer-chat');
					chatbox.setAttribute("page_id", "${id}");
					chatbox.setAttribute("attribution", "biz_inbox");
					`,
				}}
			/>
			<script
				dangerouslySetInnerHTML={{
					__html: `
					window.fbAsyncInit = function() {
						FB.init({
							xfbml            : true,
							version          : 'v17.0'
						});
					};
		
					(function(d, s, id) {
						var js, fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) return;
						js = d.createElement(s); js.id = id;
						js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
						fjs.parentNode.insertBefore(js, fjs);
					}(document, 'script', 'facebook-jssdk'));
					`,
				}}
			/>
		</>
	);
};

export default FBMessenger;
