import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import {
	ADD_EVENT_TO_CART_CALL,
	ADD_EVENT_TO_CART_DONE,
	ADD_EVENT_TO_CART_FAIL,
	ADD_WEBINAR_TO_CART_CALL,
	ADD_WEBINAR_TO_CART_DONE,
	ADD_WEBINAR_TO_CART_FAIL,
	GET_CART_PRODUCTS_CALL,
	GET_CART_PRODUCTS_DONE,
	GET_CART_PRODUCTS_FAIL,
	UPDATE_CART_PRODUCTS_CALL,
	REMOVE_COURSE_FROM_CART_CALL,
	REMOVE_COURSE_FROM_CART_DONE,
	REMOVE_COURSE_FROM_CART_FAIL,
	REMOVE_EVENT_FROM_CART_CALL,
	REMOVE_EVENT_FROM_CART_DONE,
	REMOVE_EVENT_FROM_CART_FAIL,
	REMOVE_WEBINAR_FROM_CART_CALL,
	REMOVE_WEBINAR_FROM_CART_DONE,
	REMOVE_WEBINAR_FROM_CART_FAIL,
	UPDATE_EVENT_QUANTITY_CALL,
	UPDATE_EVENT_QUANTITY_DONE,
	UPDATE_EVENT_QUANTITY_FAIL,
	RESERVE_CART_PRODUCTS_CALL,
	RESERVE_CART_PRODUCTS_DONE,
	RESERVE_CART_PRODUCTS_FAIL,
	GET_PUBLIC_COURSE_COUPONS_CALL,
	GET_PUBLIC_COURSE_COUPONS_DONE,
	GET_PUBLIC_COURSE_COUPONS_FAIL,
	APPLY_COURSE_COUPONS_CALL,
	APPLY_COURSE_COUPONS_DONE,
	APPLY_COURSE_COUPONS_FAIL,
	DELETE_COURSE_COUPONS_CALL,
	DELETE_COURSE_COUPONS_DONE,
	DELETE_COURSE_COUPONS_FAIL,
	GET_PUBLIC_EVENT_COUPONS_CALL,
	GET_PUBLIC_EVENT_COUPONS_DONE,
	GET_PUBLIC_EVENT_COUPONS_FAIL,
	APPLY_EVENT_SLOT_COUPONS_CALL,
	APPLY_EVENT_SLOT_COUPONS_DONE,
	APPLY_EVENT_SLOT_COUPONS_FAIL,
	DELETE_EVENT_SLOT_COUPONS_CALL,
	DELETE_EVENT_SLOT_COUPONS_DONE,
	DELETE_EVENT_SLOT_COUPONS_FAIL,
	GET_PUBLIC_WEBINAR_COUPONS_CALL,
	GET_PUBLIC_WEBINAR_COUPONS_DONE,
	GET_PUBLIC_WEBINAR_COUPONS_FAIL,
	APPLY_WEBINAR_SLOT_COUPONS_CALL,
	APPLY_WEBINAR_SLOT_COUPONS_DONE,
	APPLY_WEBINAR_SLOT_COUPONS_FAIL,
	DELETE_WEBINAR_SLOT_COUPONS_CALL,
	DELETE_WEBINAR_SLOT_COUPONS_DONE,
	DELETE_WEBINAR_SLOT_COUPONS_FAIL,
} from '@src/store/types/cart';
import {
	STATE_SUCCESS,
	STATE_LOADING,
	STATE_FAIL,
	STATE_IDLE,
	STATE_INVALID,
	STATE_INSUFFICIENT_CONDITION,
	STATE_UNAUTHORIZED,
} from '@src/const/common';

const initialState = {
	addEventToCartState: STATE_IDLE,
	addWebinarToCartState: STATE_IDLE,
	shoppingCartProducts: {},
	getShoppingCartProductsState: STATE_IDLE,
	updateShoppingCartProductsState: STATE_IDLE,
	removeCourseFromCartState: STATE_IDLE,
	removeEventFromCartState: STATE_IDLE,
	removeWebinarFromCartState: STATE_IDLE,
	updateEventQuantity: STATE_IDLE,
	reserveCartProductsState: STATE_IDLE,
	getPublicCourseCouponsState: STATE_IDLE,
	publicCourseCoupons: [],
	applyCourseCouponsState: STATE_IDLE,
	deleteCourseCouponsState: STATE_IDLE,
	getPublicEventCouponsState: STATE_IDLE,
	publicEventCoupons: [],
	applyEventSlotCouponsState: STATE_IDLE,
	deleteEventSlotCouponsState: STATE_IDLE,
	getPublicWebinarCouponsState: STATE_IDLE,
	publicWebinarCoupons: [],
	applyWebinarSlotCouponsState: STATE_IDLE,
	deleteWebinarSlotCouponsState: STATE_IDLE,
};

export default handleActions(
	{
		[ADD_EVENT_TO_CART_CALL](state) {
			return update(state, {
				addEventToCartState: { $set: STATE_LOADING },
			});
		},
		[ADD_EVENT_TO_CART_DONE](state) {
			return update(state, {
				addEventToCartState: { $set: STATE_SUCCESS },
			});
		},
		[ADD_EVENT_TO_CART_FAIL](state) {
			return update(state, {
				addEventToCartState: { $set: STATE_FAIL },
			});
		},
		[ADD_WEBINAR_TO_CART_CALL](state) {
			return update(state, {
				addWebinarToCartState: { $set: STATE_LOADING },
			});
		},
		[ADD_WEBINAR_TO_CART_DONE](state) {
			return update(state, {
				addWebinarToCartState: { $set: STATE_SUCCESS },
			});
		},
		[ADD_WEBINAR_TO_CART_FAIL](state) {
			return update(state, {
				addWebinarToCartState: { $set: STATE_FAIL },
			});
		},
		[GET_CART_PRODUCTS_CALL](state) {
			return update(state, {
				getShoppingCartProductsState: { $set: STATE_LOADING },
			});
		},
		[GET_CART_PRODUCTS_DONE](state, { payload }) {
			return update(state, {
				shoppingCartProducts: { $set: payload.response },
				getShoppingCartProductsState: { $set: STATE_SUCCESS },
				updateShoppingCartProductsState: { $set: STATE_IDLE },
			});
		},
		[GET_CART_PRODUCTS_FAIL](state, { payload }) {
			if (payload?.error?.response?.status === 401) {
				return update(state, {
					shoppingCartProducts: { $set: {} },
					getShoppingCartProductsState: { $set: STATE_UNAUTHORIZED },
					updateShoppingCartProductsState: { $set: STATE_UNAUTHORIZED },
				});
			}

			return update(state, {
				shoppingCartProducts: { $set: {} },
				getShoppingCartProductsState: { $set: STATE_FAIL },
				updateShoppingCartProductsState: { $set: STATE_FAIL },
			});
		},
		[UPDATE_CART_PRODUCTS_CALL](state) {
			return update(state, {
				updateShoppingCartProductsState: { $set: STATE_LOADING },
			});
		},
		[REMOVE_COURSE_FROM_CART_CALL](state) {
			return update(state, {
				removeCourseFromCartState: { $set: STATE_LOADING },
			});
		},
		[REMOVE_COURSE_FROM_CART_DONE](state) {
			return update(state, {
				removeCourseFromCartState: { $set: STATE_SUCCESS },
			});
		},
		[REMOVE_COURSE_FROM_CART_FAIL](state) {
			return update(state, {
				removeCourseFromCartState: { $set: STATE_FAIL },
			});
		},
		[REMOVE_EVENT_FROM_CART_CALL](state) {
			return update(state, {
				removeEventFromCartState: { $set: STATE_LOADING },
			});
		},
		[REMOVE_EVENT_FROM_CART_DONE](state) {
			return update(state, {
				removeEventFromCartState: { $set: STATE_SUCCESS },
			});
		},
		[REMOVE_EVENT_FROM_CART_FAIL](state) {
			return update(state, {
				removeEventFromCartState: { $set: STATE_FAIL },
			});
		},
		[REMOVE_WEBINAR_FROM_CART_CALL](state) {
			return update(state, {
				removeWebinarFromCartState: { $set: STATE_LOADING },
			});
		},
		[REMOVE_WEBINAR_FROM_CART_DONE](state) {
			return update(state, {
				removeWebinarFromCartState: { $set: STATE_SUCCESS },
			});
		},
		[REMOVE_WEBINAR_FROM_CART_FAIL](state) {
			return update(state, {
				removeWebinarFromCartState: { $set: STATE_FAIL },
			});
		},
		[UPDATE_EVENT_QUANTITY_CALL](state) {
			return update(state, {
				updateEventQuantity: { $set: STATE_LOADING },
			});
		},
		[UPDATE_EVENT_QUANTITY_DONE](state) {
			return update(state, {
				updateEventQuantity: { $set: STATE_SUCCESS },
			});
		},
		[UPDATE_EVENT_QUANTITY_FAIL](state) {
			return update(state, {
				updateEventQuantity: { $set: STATE_FAIL },
			});
		},
		[RESERVE_CART_PRODUCTS_CALL](state) {
			return update(state, {
				getShoppingCartProductsState: { $set: STATE_IDLE },
				reserveCartProductsState: { $set: STATE_LOADING },
			});
		},
		[RESERVE_CART_PRODUCTS_DONE](state) {
			return update(state, {
				reserveCartProductsState: { $set: STATE_SUCCESS },
			});
		},
		[RESERVE_CART_PRODUCTS_FAIL](state) {
			return update(state, {
				reserveCartProductsState: { $set: STATE_FAIL },
			});
		},
		[GET_PUBLIC_COURSE_COUPONS_CALL](state) {
			return update(state, {
				getPublicCourseCouponsState: { $set: STATE_LOADING },
			});
		},
		[GET_PUBLIC_COURSE_COUPONS_DONE](state, { payload }) {
			return update(state, {
				getPublicCourseCouponsState: { $set: STATE_SUCCESS },
				publicCourseCoupons: { $set: payload.response },
			});
		},
		[GET_PUBLIC_COURSE_COUPONS_FAIL](state) {
			return update(state, {
				getPublicCourseCouponsState: { $set: STATE_FAIL },
			});
		},
		[APPLY_COURSE_COUPONS_CALL](state) {
			return update(state, {
				applyCourseCouponsState: { $set: STATE_LOADING },
			});
		},
		[APPLY_COURSE_COUPONS_DONE](state) {
			return update(state, {
				applyCourseCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[APPLY_COURSE_COUPONS_FAIL](state, { payload }) {
			if (payload.error.response.status === 400) {
				if (payload.error.response.data.message === 'Insufficient Condition') {
					return update(state, {
						applyCourseCouponsState: { $set: STATE_INSUFFICIENT_CONDITION },
					});
				} else {
					return update(state, {
						applyCourseCouponsState: { $set: STATE_INVALID },
					});
				}
			}

			return update(state, {
				applyCourseCouponsState: { $set: STATE_FAIL },
			});
		},
		[DELETE_COURSE_COUPONS_CALL](state) {
			return update(state, {
				deleteCourseCouponsState: { $set: STATE_LOADING },
			});
		},
		[DELETE_COURSE_COUPONS_DONE](state) {
			return update(state, {
				deleteCourseCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[DELETE_COURSE_COUPONS_FAIL](state) {
			return update(state, {
				deleteCourseCouponsState: { $set: STATE_FAIL },
			});
		},
		[GET_PUBLIC_EVENT_COUPONS_CALL](state) {
			return update(state, {
				getPublicEventCouponsState: { $set: STATE_LOADING },
			});
		},
		[GET_PUBLIC_EVENT_COUPONS_DONE](state, { payload }) {
			return update(state, {
				getPublicEventCouponsState: { $set: STATE_SUCCESS },
				publicEventCoupons: { $set: payload.response },
			});
		},
		[GET_PUBLIC_EVENT_COUPONS_FAIL](state) {
			return update(state, {
				getPublicEventCouponsState: { $set: STATE_FAIL },
			});
		},
		[APPLY_EVENT_SLOT_COUPONS_CALL](state) {
			return update(state, {
				applyEventSlotCouponsState: { $set: STATE_LOADING },
			});
		},
		[APPLY_EVENT_SLOT_COUPONS_DONE](state) {
			return update(state, {
				applyEventSlotCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[APPLY_EVENT_SLOT_COUPONS_FAIL](state, { payload }) {
			if (payload.error.response.status === 400) {
				if (payload.error.response.data.message === 'Insufficient Condition') {
					return update(state, {
						applyEventSlotCouponsState: { $set: STATE_INSUFFICIENT_CONDITION },
					});
				} else {
					return update(state, {
						applyEventSlotCouponsState: { $set: STATE_INVALID },
					});
				}
			}

			return update(state, {
				applyEventSlotCouponsState: { $set: STATE_FAIL },
			});
		},
		[DELETE_EVENT_SLOT_COUPONS_CALL](state) {
			return update(state, {
				deleteEventSlotCouponsState: { $set: STATE_LOADING },
			});
		},
		[DELETE_EVENT_SLOT_COUPONS_DONE](state) {
			return update(state, {
				deleteEventSlotCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[DELETE_EVENT_SLOT_COUPONS_FAIL](state) {
			return update(state, {
				deleteEventSlotCouponsState: { $set: STATE_FAIL },
			});
		},
		[GET_PUBLIC_WEBINAR_COUPONS_CALL](state) {
			return update(state, {
				getPublicWebinarCouponsState: { $set: STATE_LOADING },
			});
		},
		[GET_PUBLIC_WEBINAR_COUPONS_DONE](state, { payload }) {
			return update(state, {
				getPublicWebinarCouponsState: { $set: STATE_SUCCESS },
				publicWebinarCoupons: { $set: payload.response },
			});
		},
		[GET_PUBLIC_WEBINAR_COUPONS_FAIL](state) {
			return update(state, {
				getPublicWebinarCouponsState: { $set: STATE_FAIL },
			});
		},
		[APPLY_WEBINAR_SLOT_COUPONS_CALL](state) {
			return update(state, {
				applyWebinarSlotCouponsState: { $set: STATE_LOADING },
			});
		},
		[APPLY_WEBINAR_SLOT_COUPONS_DONE](state) {
			return update(state, {
				applyWebinarSlotCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[APPLY_WEBINAR_SLOT_COUPONS_FAIL](state, { payload }) {
			if (payload.error.response.status === 400) {
				if (payload.error.response.data.message === 'Insufficient Condition') {
					return update(state, {
						applyWebinarSlotCouponsState: { $set: STATE_INSUFFICIENT_CONDITION },
					});
				} else {
					return update(state, {
						applyWebinarSlotCouponsState: { $set: STATE_INVALID },
					});
				}
			}

			return update(state, {
				applyWebinarSlotCouponsState: { $set: STATE_FAIL },
			});
		},
		[DELETE_WEBINAR_SLOT_COUPONS_CALL](state) {
			return update(state, {
				deleteWebinarSlotCouponsState: { $set: STATE_LOADING },
			});
		},
		[DELETE_WEBINAR_SLOT_COUPONS_DONE](state) {
			return update(state, {
				deleteWebinarSlotCouponsState: { $set: STATE_SUCCESS },
			});
		},
		[DELETE_WEBINAR_SLOT_COUPONS_FAIL](state) {
			return update(state, {
				deleteWebinarSlotCouponsState: { $set: STATE_FAIL },
			});
		},
	},
	initialState,
);
