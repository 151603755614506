/* eslint-disable no-param-reassign */
import { handleActions } from 'redux-actions';
import produce from 'immer';

import * as types from '@src/store/types';
import { getCurrentTimestamp } from '@src/utils/day';
import {
	STATE_SUCCESS, STATE_LOADING, STATE_FAIL, STATE_IDLE, STATE_PRIVATE, STATE_NOT_FOUND,
} from '@src/const/common';

const initialState = {
	getHomeworksStates: {},
	homework: {},
	addHomeworkStates: {},
	updateHomeworkStates: {},
	getCourseHomeworkDetailStates: {},
	homeworkDetail: {},
	getCourseHomeworkRepliesState: STATE_IDLE,
	courseHomeworkReplies: [],
	addCourseHomeworkReplyState: STATE_IDLE,
};

export default handleActions(
	{
		[types.GET_COURSE_HOMEWORKS_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.getHomeworksStates[payload.unit_id] = STATE_LOADING;
			});
		},
		[types.GET_COURSE_HOMEWORKS_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.getHomeworksStates[payload.unit_id] = STATE_SUCCESS;
				draft.homework = payload.response;
			});
		},
		[types.GET_COURSE_HOMEWORKS_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.getHomeworksStates[payload.unit_id] = STATE_FAIL;
			});
		},
		[types.ADD_COURSE_HOMEWORK_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.addHomeworkStates[payload.data.unit_id] = STATE_LOADING;
			});
		},
		[types.ADD_COURSE_HOMEWORK_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.addHomeworkStates[payload.data.unit_id] = STATE_SUCCESS;
			});
		},
		[types.ADD_COURSE_HOMEWORK_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.addHomeworkStates[payload.data.unit_id] = STATE_FAIL;
			});
		},
		[types.UPDATE_COURSE_HOMEWORK_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.updateHomeworkStates[payload.assignment_id] = STATE_LOADING;
			});
		},
		[types.UPDATE_COURSE_HOMEWORK_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.updateHomeworkStates[payload.assignment_id] = STATE_SUCCESS;
			});
		},
		[types.UPDATE_COURSE_HOMEWORK_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.updateHomeworkStates[payload.assignment_id] = STATE_FAIL;
			});
		},
		[types.GET_COURSE_HOMEWORK_DETAIL_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseHomeworkDetailStates[payload.assignment_id] = STATE_LOADING;
			});
		},
		[types.GET_COURSE_HOMEWORK_DETAIL_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseHomeworkDetailStates[payload.assignment_id] = STATE_SUCCESS;
				draft.homeworkDetail = payload.response;
			});
		},
		[types.GET_COURSE_HOMEWORK_DETAIL_FAIL](state, { payload }) {
			return produce(state, draft => {
				if (payload.err.response.status === 403) {
					draft.getCourseHomeworkDetailStates[payload.assignment_id] = STATE_PRIVATE;
				} else if (payload.err.response.status === 404) {
					draft.getCourseHomeworkDetailStates[payload.assignment_id] = STATE_NOT_FOUND;
				} else {
					draft.getCourseHomeworkDetailStates[payload.assignment_id] = STATE_FAIL;
				}
			});
		},

		[types.GET_COURSE_HOMEWORK_REPLIES_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseHomeworkRepliesState = STATE_LOADING;
			});
		},
		[types.GET_COURSE_HOMEWORK_REPLIES_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseHomeworkRepliesState = STATE_SUCCESS;
				draft.courseHomeworkReplies = payload.response;
			});
		},
		[types.GET_COURSE_HOMEWORK_REPLIES_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.getCourseHomeworkRepliesState = STATE_FAIL;
			});
		},

		[types.ADD_COURSE_HOMEWORK_REPLY_CALL](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseHomeworkReplyState = STATE_LOADING;
			});
		},
		[types.ADD_COURSE_HOMEWORK_REPLY_DONE](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseHomeworkReplyState = STATE_SUCCESS;
			});
		},
		[types.ADD_COURSE_HOMEWORK_REPLY_FAIL](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseHomeworkReplyState = STATE_FAIL;
			});
		},
		[types.ADD_COURSE_HOMEWORK_REPLY_RESET](state, { payload }) {
			return produce(state, draft => {
				draft.addCourseHomeworkReplyState = STATE_IDLE;
			});
		},
		[types.ADD_COURSE_DISCUSSION_REPLY_DONE](state, { payload }) {
			const targetDiscussionIndex = state.courseHomeworkReplies.findIndex(
				discussion => discussion.id === payload.comment_id,
			);

			if (targetDiscussionIndex === -1) {
				return state;
			}

			return produce(state, draft => {
				if (targetDiscussionIndex !== -1) {
					draft.courseHomeworkReplies[targetDiscussionIndex].replies.push({
						id: payload.reply_id,
						message: payload.message,
						created_at: getCurrentTimestamp(),
						user: {
							name: payload.username,
							profile_picture_link: payload.profile_picture_link,
							role: 'student',
						},
					});
				}
			});
		},
	},
	initialState,
);
