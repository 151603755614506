import React from 'react';
import Head from 'next/head';

const NoIndex = ({ enable }: { enable?:boolean }): JSX.Element => {
	if (!enable) return <></>;
	return (
		<Head>
			<meta name="robots" content="noindex" />
			<meta name="googlebot" content="noindex" />
		</Head>
	);
};

export default NoIndex;
